/** @format */

@import "../../../../styling/variables.scss";
.hero {
  display: flex;
  background: #fff;
  color: $blackGreen;
  justify-content: space-between;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url("../../../../img/hero.webp");

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 678px;
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 678px;
    width: 55%;
  }
  &__line {
    background: #6ba32b;
    width: 380px;
    height: 14px;
    margin-bottom: 20px;
    margin-left: 20px;
  }
  &__title {
    font-size: 52px;
    margin-bottom: 50px;
    color: #fff;
    margin-left: 20px;
  }
  &__desc {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 50px;
    font-family: "Montserrat";
    color: #fff;
    margin-left: 20px;
  }
  &__links {
    display: flex;
  }
  a {
    margin-left: 20px;
  }
  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 193px;
    height: 56px;
    border-radius: 44px;
    background: #509c5a;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #509c5a;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    div {
      margin-left: 12px;
      color: #fff;
      font-size: 20px;
      font-weight: 500;
      line-height: 110%;
      transition: 0.2s ease-in-out;
    }
    > svg {
      width: 20px;
      path {
        fill: transparent;
        transition: 0.2s ease-in-out;
      }
    }
    &_cursor {
      position: absolute;
      bottom: -12px;
      left: 50%;
      transform: translateX(-50%);
    }
    // &:hover {
    //   background: transparent;
    //   div {
    //     color: #509c5a;
    //   }
    //   > svg {
    //     path {
    //       stroke: #509c5a;
    //       fill: #509c5a;
    //       transition: 0.2s ease-in-out;
    //     }
    //   }
    // }
  }
  &__modal {
    max-width: 1004px;
    width: 100%;
    position: absolute;
    top: 130px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: flex-start;
    video {
      width: calc(100% - 49px);
      border-radius: 6px;
      box-shadow: 0px 4px 20px -4px rgba(0, 0, 0, 0.5);
    }
    button {
      width: 34px;
      min-width: 34px;
      margin-left: 16px;
      height: 34px;
      background: transparent;
      border: none;
      cursor: pointer;
      color: #fff;
      img {
        width: 100%;
      }
    }
  }
  &__preview {
    cursor: pointer;
    width: 500px;
    position: relative;
    > picture:first-child {
      display: block;
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
}
.button {
  padding: 0 32px;
  display: inline-flex;
  align-items: center;
  height: 39px;
  min-height: 39px;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 110%;
  transition: 0.2s ease-in-out;
  background: $green;
  border: 1px solid $green;
  color: #ffffff;
  &:hover {
    background: #ffffff;
    color: $green;
    transition: 0.2s ease-in-out;
  }
}
.mobile {
  display: none;
}
@media screen and (max-width: 1300px) {
  .hero {
    &__modal {
      top: 160px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .hero {
    &__preview {
      width: 400px;
    }
  }
}

@media screen and (max-width: 1070px) {
  .hero {
    &__modal {
      max-width: 904px;
    }
  }
}

@media (max-width: 991px) {
  .hero {
    height: auto;
    position: relative;
    padding-bottom: 40px;
    &__modal {
      max-width: 804px;
    }
    &__wrapper {
      width: 100%;
      height: 596px;
    }
    &__container {
      flex-direction: column;
    }
    // &__video {
    //   &::after {
    //     display: none;
    //   }
    // }
  }
}

@media screen and (max-width: 830px) {
  .hero {
    &__modal {
      top: 180px;
      max-width: 704px;
    }
  }
}

@media screen and (max-width: 768px) {
  .hero {
    padding-top: 40px;
    &__title {
      font-size: 32px;
    }
    &__desc {
      font-size: 18px;
    }
    &__line {
      display: none;
    }
    &__links {
      a {
        white-space: nowrap;
      }
    }
    &__wrapper {
      height: auto;
    }
    &__container {
      align-items: flex-start;
    }
    &__modal {
      top: 20px;
      max-width: 90%;
    }
    &__play {
      height: 39px;
      width: 170px;
      div {
        font-size: 14px;
        margin-left: 6px;
      }
    }
    &__preview {
      margin: 20px 0 0 20px;
    }
    // &__video {
    //   margin: 20px 0 0 20px;
    // }
  }
}

@media screen and (max-width: 650px) {
  .hero {
    &__modal {
      top: 120px;
    }
  }
}

@media screen and (max-width: 576px) {
  .hero {
    &__container {
      flex-wrap: wrap;
      flex-direction: row;
    }
    &__wrapper {
      flex: 100%;
    }
    &__modal {
      top: 200px;
      button {
        svg {
          min-width: 20px;
        }
      }
    }
    &__desc {
      margin-bottom: 20px;
    }
    &__preview {
      margin-block: 0;
      width: auto;
      flex: 20%;
      margin-right: 10px;
      img {
        display: none;
      }
      div {
        position: static;
        transform: translate(0, 0);
      }
    }
    &__links {
      flex: 45%;
      a {
        height: 39px;
        min-height: auto;
        &:first-child {
          display: none;
        }
      }
    }
  }
  .desktop {
    display: none;
  }
  .mobile {
    display: flex;
  }
}

@media screen and (max-width: 475px) {
  .hero {
    &__wrapper {
      justify-content: flex-end;
    }
    &__title {
      margin-left: 0;
      font-size: 32px;
    }
    &__desc {
      margin-left: 0;
      font-size: 18px;
    }
    a {
      margin-left: 0;
    }
    &__links {
      gap: 20px;
      a {
        padding: 0 16px;
        height: 39px;
        white-space: nowrap;
      }
    }
    &__line {
      width: 50%;
      margin-left: 0;
    }
    &__play {
      margin: 0;
    }
    &__modal {
      video {
        width: calc(100% - 31px);
      }
      button {
        margin-left: 6px;
        width: 24px;
        min-width: 24px;
        height: 24px;
        svg {
          width: 100%;
          min-width: 20px;
        }
      }
    }
    &__preview {
      width: 100%;
      margin-left: 0;
    }
  }
}

@media screen and (max-width: 425px) {
  .hero {
    a {
      justify-content: center;
    }
  }
}
@media screen and (max-width: 350px){
  .hero{
    &__container{
      flex-direction: column;
    }
    &__preview{
      margin-bottom: 8px;
      flex: 1;
      width: 50%;
    }
    &__play{
      width: 100%;
    }
    &__links{
      flex: 1;
      width: 50%;
      a{
        width: 100%;
      }
    }
  }
}

.tile {
  border-left: 4px solid #c5d483;
  padding-inline: 10px;
  h4 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #0f131e;
  }
  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
    color: #509c5b;
  }
}
@media screen and (max-width: 576px) {
  .tile {
    h4 {
      font-size: 30px;
    }
    p {
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 425px) {
  .tile{
    h4 {
      font-size: 24px;
    }
    p{
      font-size: 16px;
    }
  }
}

.modal{
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(60, 60, 60, 0.05);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  &__inner{
    max-width: 451px;
    width: 100%;
    background: #fff;
    padding: 40px 31px;
    position: relative;
  }
  &__close{
    cursor: pointer;
    background: none;
    border: none;
    position: absolute;
    top: 15px;
    right: 15px;
  }
  &__title{
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    text-align: center;
    color: #0F131E;
  }
  &__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 24px 0;
    &_label{
      margin-right: 10px;
      font-size: 16px;
      line-height: 140%;
      color: #000000;
    }
    &_semi{
      display: flex;
      align-items: center;
    }
  }
  &__date{
    &_block{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 151px;
      height: 38px;
      padding: 0 16px;
      border: 1px solid #D7D3C1;
      border-radius: 2px;
      position: relative;
      input{
        visibility: hidden;
        z-index: -1;
        position: absolute;
        bottom: 0px;
      }
    }
  }
  &__status{
    margin: 24px 0 8px 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: rgba(60, 60, 60, 0.8);
  }
  &__btn{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 24px auto 0 auto;
    border: none;
    width: 199px;
    height: 39px;
    cursor: pointer;
    outline: none;
    background: #509C5B;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 110%;
    color: #FFFFFF;
    transition: .2s ease-in-out;
    &_disabled{
      opacity: 0.3;
      pointer-events: none;
      transition: .2s ease-in-out;
    }
  }
}

/** @format */
.timeline {
  padding-block: 16px;
  background: #d7d3c1;
  h2 {
    text-align: center;
  }
  &__header_mobile {
    display: none;
  }
  // &__inner {
  //   display: grid;
  //   grid-template-columns: repeat(10,1fr);
  //   grid-template-rows: 1fr 1fr;
  //   padding-inline-start: 8.5%;

  //   margin-top: 40px;
  // }
  &__mobile {
    display: none;
  }
  &__wrapper {
    overflow-x: scroll;
    &::-webkit-scrollbar {
      // width: 5px;
      display: none;
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: red;
      // border-radius: 10px;
    }
    
    &::-webkit-scrollbar-thumb:hover {
      background-color: red;
    }
  }
  &__inner {
    width: max-content;
    position: relative;
    display: flex;
    justify-content: center;
    padding-inline: 16px;
    margin-inline: auto;
    li {
      display: grid;
      // flex-shrink: 0;
      align-items: center;
      grid-template-rows: var(--timeline-row-start, minmax(0, 1fr)) auto var(
          --timeline-row-end,
          minmax(0, 1fr)
        );
      grid-template-columns: var(--timeline-col-start, minmax(0, 1fr)) auto var(
          --timeline-col-end,
          minmax(0, 1fr)
        );
      max-width: 140px;
      hr {
        height: 2px;
        width: 100%;
        border-width: 0;
        background: #509c5a;
      }
      hr:first-child {
        grid-column-start: 1;
        grid-row-start: 2;
        grid-column-end: 4;
      }
      hr:last-child {
        grid-column-start: 3;
        grid-column-end: none;
        grid-row-start: 2;
        grid-row-end: auto;
      }
    }
  }
  .timelineBlock {
    display: flex;
    flex-direction: column;
    gap: 5px;
    p {
      font-family: "Roboto";
      font-size: 16px;
      font-weight: 400;
      line-height: 15.6px;
      color: #3c3c3ccc;
    }
    h5 {
      font-family: "Montserrat";
      font-size: 18px;
      font-weight: 700;
      line-height: 23.4px;
      color: #509c5a;
    }
  }
  .timelineStart {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
    margin: 0.25rem;
    align-self: flex-end;
    justify-self: center;
  }
  .timelineMiddle {
    grid-column-start: 1;
    grid-row-start: 2;
    height: 8px;
    width: 8px;
    border-radius: 1px;
    background: #509c5a;
  }
  .timelineEnd {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 3;
    grid-row-end: 4;
    margin: 0.25rem;
    align-self: flex-start;
    justify-self: center;
  }
}

@media (max-width: 991px) {
  .timeline {
    padding-inline-start: 4%;
  }
}

@media screen and (max-width: 768px) {
  .timeline {
    padding-bottom: 0;
    padding-inline: 8%;
    &__mobile {
      display: flex;
      flex-direction: column;
    }
    &__wrapper {
      overflow: visible;
      display: none;
    }
    &__inner {
      flex-direction: column;
    }
    &__header {
      margin-bottom: 22px;
    }
    &__header_mobile {
      display: block;
    }
  }
}
@media screen and (max-width: 576px) {
  .timeline {
    margin-top: 40px;
    padding-inline: 10px;
    padding-top: 32px;
    h2 {
      font-size: 24px;
      font-weight: 700;
      line-height: 31.2px;
      text-align: left;
    }
  }
}

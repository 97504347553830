/** @format */

@import '../../styling/variables.scss';

.button {
  border-radius: 4px;
  border: 1px solid var(--Main-Green, #509c5a);
  display: flex;
  width: 252px;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: none;
  cursor: pointer;
  color: var(--Main-Green, #509c5a);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%; /* 15.4px */
  margin-top: 24px;
}
.button_success {
  display: flex;
  width: 252px;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid var(--Main-Green, #509c5a);
  background: var(--Main-Green, #509c5a);
  color: var(--Main-White, #fff);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%; /* 15.4px */
}

.modal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 24px;

  &_close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  p {
    color: var(--Main-Black-green, #0f131e);
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
    letter-spacing: -0.32px;
  }

  h3 {
    color: var(--Main-Black-green, #0f131e);
    text-align: center;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 31.2px */
  }
}

.calculate {
  margin-top: 67px;
  padding-block: 2rem 4rem;
  overflow: hidden;

  .calculator {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &__yield {
      padding-left: 8px;
      white-space: nowrap;
      font-size: 14px;
      display: block;
    }
    &__harvest {
      color: var(--Main-White, #fff);
      text-align: center;

      /* text */
      font-family: Roboto;
      font-size: 15px;
      font-style: normal;
      margin-top: 5px;
      font-weight: 400;
      line-height: 140%; /* 21px */
    }
    &__inner {
      display: flex;
      padding-top: 76px;
      // overflow: hidden;
    }
    &__left {
      flex: 2;
    }

    &__law {
      background-color: #fff;
      margin-right: 66px;
      margin-top: 190px;
      border-left: 4px solid #c5d483;
      padding: 10px 10px 10px 44px;
      position: relative;
      img {
        position: absolute;
        left: 10px;
        top: 10px;
      }
      h5 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: -0.03em;
        color: #000000;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        width: 95%;
      }
    }
    &__body {
      flex: 1;
      height: 406px;
      position: relative;
      transform: translateY(-18%);
      max-width: 402px;
      padding: 10px 20px;
      color: #fff;
      position: relative;
      &_total {
        color: var(
          --Grey-black-80,
          rgba(60, 60, 60, 0.8)
        );
        text-align: right;
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 33.6px */
        letter-spacing: -0.48px;
       
        margin-right: 20%;


        & > input {
          color: var(
            --Grey-black-80,
            rgba(60, 60, 60, 0.8)
          );
          text-align: right;
          font-family: Montserrat;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%; /* 33.6px */
          letter-spacing: -0.48px;
          max-width: 100px;
          border: none;
          text-align: right;
          background: none;
        }
      }
      .blur {
        border-radius: 14px;
        position: absolute;
        width: 100%;
        height: 446px;
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;
        border-radius: 4px;
        background: rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(7.5px);
      }
      h3 {
        font-size: 24px;
        text-align: left;
        font-family: 'Montserrat';
      }
      &_amount {
        margin-top: 10px;
        display: flex;
        align-items: center;
        &__leaf {
          background-color: transparent;
          min-width: 90px;
          height: 90px;
          display: flex;
          border-radius: 50%;
          z-index: 1;
          img {
            width: 100%;
            height: 100%;
          }
        }

        h3 {
          padding-left: 16px;
        }
      }
      &_slider {
        margin-top: 16px;


        &__value {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 24px;
          border-radius: 4px;
          box-sizing: border-box;

          background: #fff;
          height: 50px;
          display: flex;
          padding: 8px 16px;
          margin-bottom: 12px;

          gap: 10px;
          div {
            font-family: 'Montserrat';
            display: flex;
            align-items: center;
            justify-content: center;
            &:first-child,
            &:last-child {
              cursor: pointer;
            }
          }
        }
        &__container {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;

          .slider {
            -webkit-appearance: none;
            width: 100%;
            height: 2px;
            background: #fff;
            outline: none;
            height: 7px;
            border-radius: 30px;
          }
          .max {
            border-radius: 20px;
            border: 1px solid #fff;
            background: rgba(255, 255, 255, 0.25);

            color: #fff;
            cursor: pointer;
            width: 43px;
            height: 23px;
            display: flex;
            align-items: center;
            justify-content: center;

            text-align: center;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%; /* 16.8px */
            cursor: pointer;
          }

          .slider::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            border: 2px solid #fff;
            background: #6ba32b;
            cursor: pointer;
          }

          .slider::-moz-range-thumb {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            border: 2px solid #fff;
            background: #6ba32b;
            cursor: pointer;
          }
        }
      }
      &_scenario {
        margin-top: 16px;
        h3 {
          text-transform: uppercase;
        }
        &_amount {
          text-align: center;
          margin-top: 5px;
          font-family: Montserrat;
          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 38.4px */
        }
        &_price {
          color: var(--Main-Yellow, #d7d3c1);

          /* Text 3 */
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 16.8px */
          text-align: center;
        }
        &__tabs {
          padding-block: 12px;
          display: flex;
          border-bottom: 3px solid #fff;
          justify-content: center;
        }
        &__info {
          margin-top: 24px;
          display: flex;
          justify-content: center;
          gap: 1rem;

          &_header {
            color: var(--Main-White, #fff);
            font-family: Montserrat;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%; /* 33.6px */
          }

          &_line {
            width: 140px;
            p {
              text-align: center;
            }
            hr {
              background-color: #509c5b;
              height: 3px;
              border: none;
              position: relative;
              overflow: visible;
              &::after {
                content: 'V';
                position: absolute;
                color: #509c5b;
                right: -2px;
                transform: rotate(-90deg);
                top: -8px;
              }
            }
          }
        }
      }
      &_power__active {
        position: absolute;

        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        width: 170px;
        height: 42px;

        cursor: pointer;
        border-radius: 4px;
        background: var(--Main-Green, #509c5a);
        color: var(--Main-White, #fff);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%; /* 15.4px */
      }

      &_power {
        position: absolute;

        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 4px;
        border: 2px solid #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        width: 170px;
        height: 42px;
        text-align: center;
        color: var(--Main-White, #fff);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%; /* 15.4px */
        h3 {
          font-size: 16px;
          font-weight: 600;
          color: $green;
        }
        p {
          font-size: 32px;
          font-weight: 600;
          font-family: 'Montserrat';
          span {
            font-size: 24px;
            font-family: 'Montserrat';
          }
        }
      }
      &_note {
        text-align: center;
        margin-top: 45px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .calculate {
    .calculator {
      &__law {
        margin-top: 140px;
        margin-right: 40px;
      }
    }
  }
}

@media (max-width: 991px) {
  .calculate {
    &__head {
      p {
        width: 100%;
        margin-bottom: 24px;
      }
    }
    .calculator {
      &__left {
        display: none;
      }
      &__inner {
        justify-content: center;
      }
    }
  }
}
@media screen and (max-width: 700px) {
  .calculate {
    &__head {
      height: 40px;
      opacity: 0;
    }
  }
}

@media screen and (max-width: 576px) {
  .calculate {
    margin-top: 40px;
    padding-top: 0;
    &__title {
      text-align: center;
      font-size: 24px;
    }
    &__head {
      margin-bottom: 10px;
      p {
        text-align: center;
        margin-bottom: 0;
      }
    }
    .calculator {
      height: auto;
      padding: 0;
      margin-top: 10px;
      &__inner {
        padding-top: 0;
        height: 585px;
        position: relative;
      }
      &__body {
        width: 100%;
        transform: translateY(0);
        border-radius: 0;
        padding-inline: 10px;
        position: absolute;
        bottom: 0;
        height: 535px;
        h3 {
          font-size: 18px;
        }
        .blur {
          border: none;
          border-radius: 0;
          height: 535px;
        }
        &_amount {
          &__trees {
            font-size: 16px;
            width: 100%;
            padding-right: 40px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .calculate {
    .calculator {
      &__inner {
        position: static;
      }
      &__body {
        max-width: none;
        position: static;
        &_power,
        &_power__active {
          margin-top: 10px;
          padding-inline: 16px;
          bottom: 10px;
          a {
            width: 100%;
            justify-content: center;
          }
        }
        &_amount {
          &__leaf {
            img {
              width: 90px;
              height: 90px;
            }
          }
        }
        &_scenario {
          &__info {
            gap: 20px;
            div {
              text-align: center;
              &:last-child {
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .calculate {
    padding-block: 0;
  }
}

@media screen and (max-width: 410px) {
  .calculate {
    .calculator {
      height: auto;
      padding: 0;
      margin-top: 10px;
      &__inner {
        padding-top: 0;
      }
      &__body {
        width: 100%;
        transform: translateY(0);
        border-radius: 0;
        padding-inline: 10px;
        h3 {
          font-size: 18px;
        }
        .blur {
          border: none;
          border-radius: 0;
        }
        &_amount {
          &__trees {
            font-size: 16px;
            width: 100%;
            padding-right: 10px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 410px) {
  .calculate {
    .calculator {
      height: auto;
      padding: 0;
      margin-top: 10px;
      &__inner {
        padding-top: 0;
      }
      &__body {
        width: 100%;
        transform: translateY(0);
        border-radius: 0;
        padding-inline: 10px;
        h3 {
          font-size: 18px;
        }
        .blur {
          border: none;
          border-radius: 0;
        }
        &_amount {
          &__trees {
            font-size: 12px;
            width: 100%;
            padding-right: 10px;
            span {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 365px) {
  .calculator {
    &__body {
      &_total {
        font-size: 21px;
      }
    }
  }
}

.location {
  padding: 0 23px 84px 20px;
  height: calc(100vh - 200px);
  color: #0f131e;
  overflow-y: scroll;

  &__buildings {
    &_title {
      margin-left: 20px;
      display: flex;
      gap: 24px;
      margin-bottom: 16px;
      h2 {
        cursor: pointer;
      }
    }
    &_carousel {
      background-color: #f8f8f8;
      padding: 16px 0 12px 16px;
      > div:nth-child(1) > div:nth-child(1) > div {
        width: auto;
      }
    }
  }
  &__webcams {
    margin-top: 36px;
    padding-right: 22px;
    h2 {
      margin-left: 20px;
      margin-bottom: 10px;
    }
    &_inner {
      display: flex;
      gap: 20px;
      // flex-wrap: wrap;
    }
    &_one {
      flex: 1;
      padding-block: 15px;
      display: flex;
      flex-direction: column;
      gap: 6px;
      position: relative;
      &__image {
        display: flex;
        flex-direction: column;
        gap: 10px;
        > img {
          width: 450px;
          height: 260px;
          object-fit: cover;
        }
      }
      &__offline {
        position: absolute;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        color: #fff;
        font-size: 12px;
        & + img {
          filter: blur(2px);
        }
      }
      &__info {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
      }
      &__desc {
        display: flex;
        align-items: flex-start;
        gap: 10px;
        h4 {
          font-size: 14px;
          font-weight: 500;
          padding-block: 4px 10px;
        }
        p {
          font-size: 14px;
        }
      }
    }
    &_other {
      flex: 1.5;
      background-color: #f8f8f8;
      padding: 16px;
      display: grid;
      column-gap: 18px;
      row-gap: 6px;
      grid-template-columns: repeat(auto-fit, 205px);
      grid-template-rows: 150px;
      max-height: 332px;
      overflow: auto;
      .cam {
        p {
          font-size: 12px;
        }
        &__image {
          position: relative;
          & > div {
            position: absolute;
            color: #fff;
            font-size: 12px;
          }
          img {
            border-radius: 2px;
            width: 205px;
            height: 116px;
            object-fit: cover;
          }
        }
        &__online {
          bottom: 15px;
          left: 12px;
          display: flex;
          align-items: center;
          gap: 2px;
          & > div {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #6ba32b;
          }
        }
        &__offline {
          text-align: center;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 1;
          & + img {
            filter: blur(2px);
          }
        }
      }
    }
  }
  &__standort {
    margin-top: 33px;
    max-width: 1057px;
    &_title {
      font-family: "Montserrat", sans-serif;
      color: #0f131e;
      font-size: 42px;
      font-style: normal;
      font-weight: 600;
      line-height: 110%;
      letter-spacing: -1.26px;
      padding-left: 10px;
      border-left: 4px solid #509c5a;
      margin-bottom: 24px;
    }
    &_btext {
      color: #0f131e;
      font-size: 16px;
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      line-height: 120%;
      letter-spacing: -0.48px;
      width: calc(50% - 10px);
    }
    &_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;
    }
    &_block {
      padding-left: 10px;
      border-left: 4px solid #c5d483;
      width: calc(50% - 10px);
      &_main {
        color: #0f131e;
        font-size: 32px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.96px;
      }
      &_sub {
        color: #509c5a;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
      }
    }
    &_collect {
      width: calc(50% - 10px);
      display: flex;
      flex-wrap: wrap;
      row-gap: 14px;
    }
    &_subtitle {
      font-family: "Montserrat", sans-serif;
      color: #0f131e;
      font-size: 32px;
      font-weight: 600;
      line-height: 120%;
      padding-left: 10px;
      border-left: 4px solid #d7d3c1;
      margin-bottom: 24px;
      margin-top: 40px;
    }
    &_text {
      color: rgba(60, 60, 60, 0.8);
      font-size: 15px;
      font-weight: 400;
      line-height: 130%;
      margin-bottom: 24px;
      &_wrapper {
        display: flex;
        justify-content: space-between;
        div {
          width: calc(50% - 12px);
          margin-bottom: 0;
          img {
            width: 100%;
          }
        }
      }
    }
    &_label {
      color: #0f131e;
      font-size: 18px;
      font-weight: 700;
      line-height: 130%;
      font-family: "Montserrat", sans-serif;
      margin-bottom: 16px;
    }
    &_wrap {
      display: flex;
      justify-content: space-between;
      margin-bottom: 31px;
      &_block {
        width: calc(50% - 21px);
        &_title {
          font-family: "Montserrat";
          color: #509c5a;
          font-size: 18px;
          font-weight: 700;
          line-height: 130%;
          margin-bottom: 18px;
        }
        &_text {
          color: rgba(60, 60, 60, 0.8);
          font-size: 15px;
          font-weight: 400;
          line-height: 130%;
        }
      }
    }
  }
}

.img_swiper {
  width: 359px;
  height: 243px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 991px) {
  .location {
    height: auto;
    &__webcams {
      &_inner {
        flex-direction: column;
      }
    }
    &__mainCam {
      width: 100% !important;
    }
    &__standort {
      &_wrapper {
        flex-direction: column;
        align-items: flex-start;
      }
      &_collect {
        width: 100%;
      }
      &_btext {
        width: 100%;
        margin-bottom: 14px;
      }
      &_block {
        width: calc(50% - 10px);
      }
      &_text {
        &_wrapper {
          flex-direction: column;
          div {
            width: 100%;
            &:first-child {
              margin-bottom: 14px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 840px) {
  .location {
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  .location {
    &__standort {
      &_title {
        font-size: 32px;
      }
      &_btext {
        font-size: 16px;
      }
      &_block {
        &_main {
          font-size: 32px;
        }
      }
      &_subtitle {
        font-size: 24px;
      }
      &_text {
        font-size: 15px;
      }
      &_wrap {
        flex-direction: column;
        margin-bottom: 0;
        &_block {
          width: 100%;
          margin-bottom: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .location {
    &__webcams {
      padding-right: 0;
    }
    &__standort {
      &_collect {
        flex-direction: column;
      }
      &_block {
        width: 100%;
      }
    }
  }
}

@import "../../styling/variables.scss";

.title {
  padding-right: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: rgba(60, 60, 60, 0.8);
  display: flex;
  align-items: center;
  gap: 10px;
  span {
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    color: $green;
    font-family: "Montserrat", sans-serif;
  }
}
.tooltip {
  display: flex;
  position: relative;
  img {
    cursor: pointer;
  }
  &__inner {
    visibility: hidden;
    width: 432px;
    background-color: #f8f8f8;
    color: #0f131e;
    text-align: center;
    padding: 5px 0;
    position: absolute;
    z-index: 10;
    right: -20px;
    top: 30px;
    filter: drop-shadow(0px 21px 26px rgba(0, 0, 0, 0.1));
    border: 1px solid #d7d3c1;
    border-radius: 2px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 393px;
      bottom: 100%;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-bottom-color: #d7d3c1;
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 394px;
      bottom: 100%;
      width: 0;
      height: 0;
      border: 9px solid transparent;
      border-bottom-color: #f8f8f8;
    }
    h4 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 120%;
      text-align: left;
      color: #000000;
    }
    &_list {
      display: flex;
      flex-direction: column;
      gap: 8px;
      & > div {
        display: flex;
        text-align: left;
        gap: 4px;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        color: #0f131e;
        img {
          width: 14px;
          height: 14px;
        }
      }
    }
    &_amount {
      &__line {
        background: #d7d3c1;
        border-radius: 15px;
        height: 2px;
        width: 100%;
        position: relative;
        margin-top: 32px;
        & > div {
          position: absolute;
          height: 2px;
          width: 50%;
          background: #509c5b;
          border-radius: 15px;
          .ball {
            position: absolute;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            right: 0;
            top: -5px;
            background: #509c5b;
            div {
              width: 90px;
              padding-inline: 4px;
              background: #509c5b;
              border-radius: 2px;
              color: #fff;
              text-align: center;
              padding: 5px 0;
              position: absolute;
              z-index: 15;
              bottom: 150%;
              left: 50%;
              font-family: "Montserrat";
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 120%;
              color: #ffffff;

              // &::after {
              //   content: "";
              //   position: absolute;
              //   top: 100%;
              //   left: 50%;
              //   margin-left: -5px;
              //   border-width: 5px;
              //   border-style: solid;
              //   border-color: #509c5b transparent transparent transparent;
              // }
            }
          }
        }
      }
      & > div {
        &:last-child {
          display: flex;
          justify-content: space-between;
          margin-top: 8px;
          p {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 120%;
            color: #0f131e;
          }
        }
      }
    }
  }
  &:hover .tooltip__inner {
    visibility: visible;
  }
}

@media screen and (max-width: 560px) {
  .tooltip {
    &__inner {
      right: none;
      width: 350px;
      left: -172px;
      &::before {
        display: none;
      }
      &::after {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 389px) {
  .tooltip {
    &__inner {
      right: none;
      width: 300px;
      left: -182px;
      &::before {
        display: none;
      }
      &::after {
        display: none;
      }
    }
  }
}

.help {
  padding: 0 23px 84px 20px;
  min-height: calc(100vh - 200px);
  height: auto;
  color: #0f131e;
  &__inner {
    margin-top: 28px;
    display: flex;
    gap: 20px;
  }
  &__questions {
    flex: 60%;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  &__form {
    flex: 40%;
    background-color: #f8f8f8;
    padding: 16px 24px;
    h2 {
      width: 70%;
      &::before {
        background-color: #d7d3c1;
      }
    }
    &_inner {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: center;
      input {
        background-color: #fff;
        border-bottom: 2px solid #d7d3c1;
      }
      .textarea {
        display: block;
        width: 100%;
        margin: 0;
        &__title {
          padding-left: 16px;
          font-size: 16px;
          line-height: 130%;
          color: #0f131e;
        }
        textarea {
          width: 100%;
          margin-top: 8px;
          padding: 12px 16px;
          font-size: 16px;
          outline-color: transparent;
          outline-width: 0px;
          color: #0f131e;
          border: none;
          background-color: #fff;
          border-bottom: 2px solid #d7d3c1;
          transition: 0.2s ease-in-out;
          resize: none;
        }
      }
    }
  }
}
.question {
  padding-bottom: 24px;
  border-bottom: 2px solid #d7d3c1;
  cursor: pointer;
  &_title {
    display: flex;
    align-items: center;
    padding-left: 10px;
    h4 {
      font-family: "Montserrat";
      font-size: 16px;
      font-weight: 600;
      line-height: 19.2px;
      letter-spacing: -0.03em;
      flex: 9;
    }
    svg {
      flex: 1;
      width: 24px;
    }
  }
  p {
    padding: 16px;
    font-family: "Roboto";
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    display: none;
    color: #0F131E;
  }
  &.opened {
    border-bottom: 3px solid #509c5b;
    .question_title {
      svg {
        transform: rotateX(180deg);
      }
    }
    p {
      display: block;
    }
  }
}

@media (max-width: 1024px) {
  .help {
    height: auto;
    &__inner {
      flex-direction: column;
      gap: 60px;
    }
  }
}
@media screen and (max-width: 840px) {
  .help {
    height: auto;
    padding-bottom: 0;
    min-height: 900px;
  }
}
@media screen and (max-width: 768px) {
  .help {
    padding-inline: 10px;
  }
}

@media screen and (max-width: 475px) {
  .help {
    &__form {
      padding: 16px 10px;
    }
  }
}
@media screen and (max-width: 375px) {
  .help {
    &__form {
      &_inner {
        a {
          width: 100%;
          justify-content: center;
        }
      }
    }
  }
}

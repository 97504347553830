@import "../../styling/variables.scss";

.reset {
  padding: 62px 0;
  height: calc(100vh - 249px);
  min-height: 650px;
  overflow: hidden;
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__left {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: flex-start;
    &_img {
      background: #ffffff;
      box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.25);
      border-radius: 50%;
      padding: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__right {
    width: 457px;
    height: 392px;
    background: #f8e9b6;
    position: relative;
    margin-top: 75px;
    img {
      position: absolute;
      left: 50%;
      top: -67px;
      transform: translateX(-50%);
    }
  }
  &__title {
    margin: 0;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 42px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #0f131e;
  }
  &__steps {
    display: flex;
    & > div {
      padding: 10px 16px;
      color: $gray;
      font-size: 20px;
      font-weight: 500;
      border-bottom: 2px solid $gray;
      &.active {
        color: $green;
        border-bottom: 2px solid $green;
        background-color: #c6eccc;
        span {
          &:after {
            content: "step";
            font-size: 16px;
          }
        }
      }
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
    label {
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &_buttons {
      a {
        margin-right: 12px;
      }
    }
    &_error {
      margin-bottom: 12px;
      color: red;
      font-size: 14px;
    }
  }
  &__text {
    font-size: 20px;
  }
  &__code {
    &_wrapper {
      display: flex;
      gap: 10px;
      input {
        border: 1px solid $yellow;
        max-width: 46px;
        font-size: 24px;
        padding: 12px 16px;
        border-radius: 2px;
        &:focus {
          outline: none;
          border: 1px solid $green;
        }
      }
    }
    &_message {
      margin-top: 4px;
      color: #ca4b15;
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
  &__formError{
    color: red;
    font-size: 14px;
  }
}
.error {
  input {
    border: 1px solid #ca4b15;
    &:focus {
      border: 1px solid #ca4b15;
    }
  }
}
.success {
  background: $lightGreen;
}

@media (max-width: 991px) {
  .reset {
    height: auto;
    &__right {
      width: 327px;
      height: 322px;
      img {
        width: 90%;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .reset {
    padding-top: 36px;
    &__wrapper {
      flex-direction: column-reverse;
      align-items: center;
    }
    &__left {
      width: 100%;
      margin-top: 56px;
    }
    &__right {
      width: 327px;
      height: 282px;
      img {
        width: 80%;
      }
    }
  }
}
@media screen and (max-width: 576px) {
  .reset {
    &__right {
      width: 248px;
      height: 213px;
      img {
        width: 80%;
        top: -47px;
      }
    }
    &__title {
      font-size: 32px;
    }
    &__info {
      div, a{
        font-size: 16px;
      }
    }
    &__form{
      &_buttons{
        display: flex;
        & > a{
          flex: 1;
          justify-content: center;
          padding-inline: 24px;
        }
        a:last-child{
          margin-right: 0;
        }
      }
    }
  }
}


.contact {
  padding: 53px 0;
  height: calc(100dvh - 249px);
  min-height: 500px;
  position: relative;

  &__head {
    display: flex;
    gap: 1rem;
    h2 {
      margin-bottom: 1.5rem;
      flex: 1;
    }
    h3 {
      color: #000;
      font-family: "Montserrat";
      font-size: 2rem;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      flex: 1;
    }
  }
  &__wrapper {
    position: absolute;
    width: 50%;
    background-color: #509c5b;
    border-bottom: 6px solid #d7d3c1;
    height: 350px;
    z-index: 0;
  }
  &__inner {
    display: flex;
    gap: 1rem;
    z-index: 10;
    position: relative;
  }
  &__rect {
    flex: 1;
    padding-top: 2.38rem;
    display: flex;
    flex-direction: column;
    gap: 2.63rem;
    h3 {
      color: #fff;
      font-family: "Montserrat";
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: 130%;
      display: flex;
      gap: 0.62rem;
      align-items: center;
    }
    p {
      color: #fff;
      font-family: "Roboto";
      font-size: 0.9375rem;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      margin-top: 0.66rem;
      margin-left: 2.62rem;
    }
  }
  &__form {
    flex: 1;
    h3 {
      color: #000;
      font-family: "Montserrat";
      font-size: 2rem;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      display: none;
    }
    form {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      a {
        max-width: fit-content;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .contact {
    height: auto;
    &__wrapper {
      display: none;
    }
    &__head{
      h3{
        display: none;
      }
    }
    &__inner {
      flex-direction: column;
    }
    &__rect {
      flex: auto;
      border-bottom: none;
      width: 100%;
      background-color: #509c5b;
      padding: 32px 20px;
    }
    &__form{
      h3{
        display: block;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .contact {
    &__form {
      h3 {
        font-size: 20px;
      }
      form {
        a {
          width: 100%;
          max-width: 100%;
          justify-content: center;
        }
      }
    }
  }
}

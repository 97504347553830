.dectGrow {
}
.title {
  font-family: "Montserrat";
  font-size: 42px;
  font-weight: 600;
  line-height: 46.2px;
  letter-spacing: -0.03em;
  text-align: center;
  color: #0f131e;
}
.hero {
  padding-block: 40px;
  &__inner {
    & > h1 {
      font-family: "Montserrat";
      font-size: 54px;
      font-weight: 600;
      line-height: 59.4px;
      letter-spacing: -0.03em;
      color: #509c5a;
      text-align: center;
      margin-bottom: 24px;
    }
    & > p {
      font-family: "Montserrat";
      font-size: 18px;
      font-weight: 700;
      line-height: 23.4px;
      text-align: center;
      max-width: 940px;
      margin-inline: auto;
    }
  }
}
.info {
  padding-block: 60px;
  &__inner {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    &_block {
      display: flex;
      gap: 16px;
      & > div {
        &:first-child {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 150px;
          min-height: 150px;
          background: #e8eecd;
          border-bottom: 10px solid #509c5a;
        }
        &:last-child {
          display: flex;
          flex-direction: column;
          gap: 16px;
          h3 {
            font-family: "Montserrat";
            font-size: 18px;
            font-weight: 700;
            line-height: 23.4px;
          }
          p {
            font-family: "Roboto";
            font-size: 15px;
            font-weight: 400;
            line-height: 21px;
          }
        }
      }
    }
  }
}
.image {
  &__inner {
    margin-inline: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.problem {
  padding-block: 60px;
  &__inner {
    display: flex;
    gap: 24px;
    margin-top: 24px;
    & > * {
      flex: 1;
    }
    p {
      font-family: "Roboto";
      font-size: 15px;
      font-weight: 400;
      line-height: 21px;
      color: #0f131e;
    }
  }
}
.target {
  padding-block: 60px;
  &__inner {
    display: flex;
    gap: 24px;
    margin-top: 24px;
    & > * {
      flex: 1;
    }
    p {
      font-family: "Roboto";
      font-size: 15px;
      font-weight: 400;
      line-height: 21px;
      color: #0f131e;
    }
  }
}
@media (max-width: 1024px) {
  .image {
    &__inner {
      img {
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .info {
    &__inner {
      grid-template-columns: 1fr;
    }
  }
}
@media screen and (max-width: 768px) {
  .title {
    font-size: 24px;
    font-weight: 700;
    line-height: 31.2px;
  }
  .hero {
    padding-block: 20px;
    &__inner {
      & > h1 {
        font-size: 32px;
        font-weight: 600;
        line-height: 38.4px;
      }
      & > p {
        font-size: 18px;
        font-weight: 700;
        line-height: 23.4px;
      }
    }
  }
  .info {
    padding-block: 40px 80px;
    &__inner {
      &_block {
        flex-direction: column;
        gap: 16px;
        & > div {
          &:first-child {
            max-width: 78px;
            min-width: 78px;
            max-height: 78px;
            min-height: 78px;
            border-bottom: 5px solid #509c5a;
            img {
              width: 50px;
            }
          }
          &:last-child {
            h3 {
              font-size: 18px;
              font-weight: 700;
              line-height: 23.4px;
            }
            p {
              font-size: 15px;
              font-weight: 400;
              line-height: 21px;
            }
          }
        }
      }
    }
  }
  .problem {
    padding-block: 80px 40px;
    h2 {
      text-align: left;
    }
    &__inner {
      flex-direction: column;
      p {
        font-size: 15px;
        font-weight: 400;
        line-height: 21px;
      }
    }
  }
  .target {
    padding-block: 80px 40px;
    h2 {
      text-align: left;
    }
    &__inner {
      flex-direction: column;
      p {
        font-size: 15px;
        font-weight: 400;
        line-height: 21px;
      }
    }
  }
}

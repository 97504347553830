.container, .footerContainer, .fullContainer {
    max-width: 1140px;
    margin: 0 auto;
}
.gray{
    background: #f8f8f8;
    padding: 2rem;
}

.container-fluid {
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}
.container-fluid-2 {
    max-width: 100%;
}


@media screen and (max-width: 1200px) {
    .container, .footerContainer, .fullContainer {
        max-width: 960px;
    }
}

@media screen and (max-width: 991px) {
    .container, .footerContainer, .fullContainer {
        max-width: 720px;
    }
}

@media screen and (max-width: 768px) {
    .container {
        max-width: 540px;
    }

    .container-fluid {
        padding-left: 15px;
        padding-right: 15px;
    }

    .footerContainer, .fullContainer {
        max-width: 100%;
        padding-inline: 15px;
    }
}

@media screen and (max-width: 576px) {
    .gray{
        padding: 1rem;
    }
    .container {
        max-width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }

    .container-fluid {
        padding-right: 10px;
        padding-left: 10px;
    }

    .footerContainer {
        padding-inline: 10px;
    }
    .fullContainer{
        padding-inline: 0;
    }
}

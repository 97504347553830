.notification {
  padding: 20px 16px;
  display: flex;
  align-items: flex-start;
  background: #e8eecd;
  border-left: 2px solid #509c5b;
  flex: 2;
  svg{
    min-width: 24px;
  }
  &__text {
    margin-left: 16px;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #509c5b;
  }
}

// .auto{
//   max-width: 344px;
// }
// .period{
//   max-width: 601px;
// }

@media screen and (max-width: 560px) {
  .notification {
    border-top: 2px solid #509c5b;
    border-left: none;
    &__text {
      font-size: 12px;
      line-height: 14px;
    }
  }
}
.return {
  background: #f5f5f5;
  padding-block: 40px 74px;
  background-image: url("../../../../img/plant-return.svg");
  background-repeat: no-repeat;
  background-position: 4% bottom;
  h2 {
    text-align: center;
  }
  &__inner {
    display: flex;
    gap: 54px;
    margin-top: 40px;
    justify-content: center;
    & > div {
      // flex: 1;
      width: 80%;
    }
    &_text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap:24px;
      h5 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: -0.03em;
        color: #0f131e;
        width: 70%;
      }
      p {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        color: #0f131e;
        width: 80%;
      }
    }
  }
}
@media (max-width: 991px){
  .return{
    background-position: 96% bottom;
    &__inner{
      flex-direction: column;
      align-items: center;
    }
  }
}
@media screen and (max-width: 576px){
  .return{
    padding-top: 20px;
    border-top: 18px solid #C5D483;
    background-size: 30%;
    &__inner{
      margin-top: 26px;
      gap: 24px;
      &_text{
        h5{
          width: 100%;
        }
        p{
          width: 100%;
        }
      }
      & > div {
        width: 100%;
      }
    }
  }
}
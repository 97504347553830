.documents {
  padding-inline: 20px;
  padding-block: 9px;
  padding-bottom: 50px;
  overflow: hidden;
  &__empty {
    max-width: 1600px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 26px;
    padding: 24px 44px 24px 24px;
    gap: 50px;
    background: #f8f8f8;
    &_wrap {
      flex-wrap: nowrap;
    }
    h3 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 140%;
      color: #0f131e;
      text-align: center;
    }
    a {
      &:first-child {
        img {
          object-fit: contain;
        }
      }
    }
  }
  &__short {
    width: 100%;
    height: 250px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__name {
    font-size: 15px;
    margin-top: 13px;
    text-align: center;
    width: 100%;
  }
  &__block {
    max-width: 260px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    cursor: pointer;
    text-decoration: none;
    color: #0f131e;
    &_wrapper {
      display: flex;
      align-items: flex-start;
    }
    &:visited {
      text-decoration: none;
      color: #0f131e;
    }
    img {
      transform: scale(1);
      transition: 0.2s ease-in-out;
    }
    &:hover {
      img {
        transform: scale(1.05);
        transition: 0.2s ease-in-out;
      }
    }
  }
  &__block2 {
    max-width: 480px;

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    cursor: pointer;
    text-decoration: none;
    color: #0f131e;
    position: relative;
    &_wrapper {
      display: flex;
      align-items: flex-start;
    }
    &:visited {
      text-decoration: none;
      color: #0f131e;
    }
    img {
      transform: scale(1);
      transition: 0.2s ease-in-out;
    }
    &:hover {
      img {
        transform: scale(1.05);
        transition: 0.2s ease-in-out;
      }
    }
  }
  &__date {
    margin-top: 4px;
    color: rgba(60, 60, 60, 0.8);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-align: center;
    width: 100%;
  }
  &__download {
    width: 24px;
    min-width: 24px;
    height: 24px;
    border-radius: 4px;
    background: #ededed;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
  }
}

.modal {
  max-width: 1104px;
  width: 100%;
  position: fixed;
  top: 75px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: flex-start;
  z-index: 3;
  video {
    width: 100%;
    border-radius: 6px;
    box-shadow: 0px 4px 20px -4px rgba(0, 0, 0, 0.5);
  }
  button {
    background: #509c5b;
    width: 34px;
    min-width: 34px;
    // margin-left: 16px;
    height: 34px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 15px;
    img {
      width: 100%;
    }
  }
}
.cloud {
  position: absolute;
  right: 18px;
}

@media screen and (max-width: 1370px) {
  .documents {
    &__short {
      width: 150px;
      height: 220px;
    }
    &__block {
      &:first-child {
        .documents__short {
          width: 220px;
          height: 150px;
        }
      }
    }
  }
}

@media screen and (max-width: 1240px) {
  .documents {
    &__empty {
      gap: 30px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .documents {
    &__empty {
      flex-wrap: wrap;
      row-gap: 50px;
      a {
        max-width: 100%;
        width: 40%;
      }
    }
  }
  .modal {
    max-width: 90%;
  }
}

@media screen and (max-width: 1100px) {
  .documents {
    &__wrapper {
      justify-content: space-between;
    }
  }
}

@media screen and (max-width: 991px) {
  .documents {
    &__empty {
      justify-content: space-between;
    }
  }
  .modal {
    max-width: 704px;
  }
}
@media screen and (max-width: 768px) {
  .modal {
    max-width: 90%;
  }
}

@media screen and (max-width: 576px) {
  .documents {
    &__empty {
      flex-direction: column;
      margin-top: 40px;
      align-items: center;
      a {
        max-width: 260px;
        width: 100%;
      }
    }
    &__block {
      align-items: center;
    }
    &__name {
      text-align: center;
    }
  }
}

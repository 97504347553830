/** @format */

@import "../../styling/variables.scss";

.aside {
  width: 239px;
  min-width: 239px;
  background: #d7d3c1;
  border-right: 1px solid #b3b3b3;
  box-shadow: 0px 4px 30px -3px rgba(0, 0, 0, 0.1);
  padding: 17px 12px 0px 11px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex: 1;
  & > a {
    img {
      width: 100%;
    }
  }
  &__info {
    width: 181px;
    min-width: 181px;
    margin: 13px auto 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid $green;
    padding-bottom: 10px;
    &_status {
      margin-top: 3px;
      display: flex;
      align-items: center;
      &_title {
        color: rgba(60, 60, 60, 0.8);
        font-size: 13px;
        line-height: 207%;
        margin-right: 5px;
      }
      &_status {
        color: #0f131e;
        font-size: 13px;
        font-weight: 500;
        line-height: 207%;
      }
    }
    &_wrapper {
      padding: 0 12px 0 13px;
    }
    &_photo {
      width: 100px;
      height: 100px;
      min-width: 100px;
      min-height: 100px;
      border-radius: 100%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &_name {
      margin-top: 16px;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      color: rgba(60, 60, 60, 0.8);
    }
  }
  &__adds {
    padding: 0 12px 0 0px;
    margin-bottom: 20px;
    a {
      svg {
        path {
          stroke: rgba(60, 60, 60, 0.8);
        }
      }
      div {
        color: rgba(60, 60, 60, 0.8);
      }
      &:hover {
        svg {
          path {
            stroke: #0f131e;
          }
        }
        div {
          color: #0f131e;
        }
      }
    }
    &_active {
      a {
        svg {
          path {
            stroke: #0f131e;
          }
        }
      }
    }
  }
  &__links {
    margin-top: 25px;
  }
  a:last-child {
    margin-bottom: 0;
  }
  &__button {
    margin-top: 24px;
    width: 100%;
    outline: none;
    border: 1px solid #509c5b;
    background: #509c5b;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    padding: 10px 14px;
    color: #ffffff;
    border-radius: 4px;
    &:hover {
      background: #ffffff;
      color: #509c5b;
    }
  }
  &__inner {
    &_wrapper {
      background: #ffffff;

      border-radius: 2px;
      position: relative;
      display: flex;
      align-items: center;
      margin-top: 10px;
    }
    &_code {
      padding: 6px 10px;
      color: #509c5b;
      text-align: center;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      &_wrapper {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;

        & div {
          word-break: break-all;
          font-weight: 500;
          font-size: 12px;
          line-height: 120%;
          color: #509c5b;
          margin-top: 4px;
          text-align: left;
        }
      }
      &_text {
        color: rgba(60, 60, 60, 0.8);
        font-weight: 500;
        font-size: 13px;
        line-height: 120%;
        color: rgba(60, 60, 60, 0.8);
      }
    }
  }
  &__copy {
    cursor: pointer;
    border: none;
    outline: none;
    min-width: 33px;
    height: 31px;
    background: #ffffff;
    position: relative;
    &_container {
      text-align: center;
      margin-top: 3px;
      margin-bottom: 4px;
    }
    &_state {
      text-align: center;
      pointer-events: none;
      opacity: 0;
      z-index: -1;
      transition: 0.2s ease-in-out;
      &_active {
        opacity: 1;
        z-index: 1;
        color: #509c5b;
        transition: 0.2s ease-in-out;
      }
    }
    img {
      width: 21px;
      height: 21px;
    }
  }
  &__ref {
    padding: 4px 10px;
    background: #ffffff;
    border-radius: 2px;
    &_title {
      font-weight: 500;
      font-size: 13px;
      line-height: 120%;
      color: rgba(60, 60, 60, 0.8);
    }
    &_link {
      word-break: break-all;
      font-weight: 500;
      font-size: 13px;
      line-height: 120%;
      color: #509c5b;
      margin-top: 4px;
    }
    &_copy {
      border: none;
      background: none;
      outline: none;
      margin-top: 4px;
      padding: 0;
      cursor: pointer;
    }
  }
  &__sale {
    &_tip {
      display: flex;
      align-items: flex-start;
      margin-bottom: 10px;
      svg {
        min-width: 13px;
      }
      div {
        margin-left: 5px;
        font-size: 12px;
        line-height: 120%;
        color: #0f131e;
      }
    }
  }
  &__overflow {
    overflow: auto;
    &::-webkit-scrollbar {
      width: 4px;
      background: #d7d3c1;
    }
    &::-webkit-scrollbar-thumb {
      width: 4px;
      background: #d7d3c1;
      border-radius: 2px;
    }
  }
  &__head {
    &_note {
      display: flex;
      align-items: flex-start;
      margin-top: 8px;
      svg {
        min-width: 16px;
        margin-right: 8px;
      }
      color: #0f131e;
      font-size: 12px;
      line-height: 120%;
    }
  }
}
@media screen and (max-width: 840px) {
  .aside {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 82px;
    background-color: #fff;
    z-index: 4;
    min-height: 0px;
    padding: 20px 10px 0 10px;
    display: none;
    & > img {
      display: none;
    }
    & > div {
      & > img {
        display: none;
      }
    }
    &__info {
      display: none;
    }
    &__sale {
      &_tip {
        display: none;
      }
    }
    &__links {
      display: flex;
      width: 100%;
      justify-content: center;
      gap: 24px;
      border-bottom: 1px solid #d7d3c1;
      align-items: center;
      margin-top: 0;
      padding-bottom: 20px;
      br {
        display: none;
      }
      a {
        padding-block: 9px;
        margin-bottom: 0;
      }
      &__referall {
        display: none;
      }
    }
    &__button {
      display: none;
    }
    &__inner {
      &_wrapper {
        display: none;
      }
    }
    &__copy {
      &_container {
        display: none;
      }
    }
    &__ref {
      display: none;
    }
    &__head {
      &_note {
        display: none;
      }
    }
  }
}
@media screen and (max-width: 400px) {
  .aside {
    &__info_wrapper {
      padding-inline: 0;
    }
    &__links {
      gap: 12px;
    }
  }
}
@media screen and (max-width: 320px) {
  .aside {
    padding-bottom: 0px;
    &__links {
      padding-bottom: 0px;
      gap: 8px;
      a {
        gap: 4px;
        svg {
          width: 20px;
          height: 20px;
        }
        div {
          font-size: 14px;
          margin-left: 0;
        }
      }
    }
  }
}

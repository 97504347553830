@import "../../../../../../styling/variables.scss";

.wrapper {
  display: flex;
}

.container {
  width: 100%;
  position: relative;
  height: 100vh;
  flex: 7;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 4px;
    background: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    width: 4px;
    background: #b3b3b3;
    border-radius: 2px;
  }
}
.checkout {
  padding: 12px 24px 60px 21px;
  height: calc(100vh - 200px);
  min-height: 790px;
  &__green {
    &_link {
      color: #509c5b;
      font-size: 16px;
      letter-spacing: -0.3px;
    }
  }
  &__text {
    color: #0f131e;
    font-weight: bolder;
    margin-top: 15px;
  }
  &__wrapper {
    margin-top: 21px;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    &_reverse {
      flex-direction: row;
    }
    &_title {
      position: relative;
      font-weight: 600;
      font-size: 20px;
      line-height: 120%;
      letter-spacing: -0.03em;
      font-family: "Montserrat", sans-serif;
      color: #0f131e;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -10px;
        width: 4px;
        height: 100%;
        background: #d7d3c1;
      }
      &_side {
        margin-bottom: 14px;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: -10px;
          width: 4px;
          height: 100%;
          background: #509c5a;
        }
      }
    }
  }
  &__left {
    width: calc(60% - 10px);
    border: 1px solid #b3b3b3;
    border-radius: 2px;
    padding: 16px 38px 42px 41px;
  }
  &__error {
    color: red;
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
  }
  &__right {
    width: calc(40% - 10px);
    background: #c6eccc;
    border: 1px solid #d7d3c1;
    border-radius: 2px;
    padding: 18px 24px 18px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &_bottom {
      margin-top: 20px;
    }
    &_product {
      width: 40%;
    }
    &_quantity {
      width: 30%;
      text-align: right;
    }
    &_subtotal {
      width: 30%;
      text-align: right;
    }
    &_table {
      margin-top: 14px;
      &_row {
        display: flex;
        align-items: center;
        &_values {
          display: flex;
          align-items: center;
          padding: 9px 0 0 0;
        }
      }
      &_total {
        margin-top: 17px;
        padding: 17px 0 0 0;
        display: flex;
        align-items: center;
        border-top: 1px solid #509c5b;
        justify-content: space-between;
        &_value {
          font-family: "Montserrat", sans-serif;
          width: 50%;
          font-weight: 600;
          font-size: 16px;
          line-height: 140%;
          letter-spacing: -0.02em;
          color: #0f131e;
          &:last-child {
            text-align: right;
          }
        }
      }
      &_stats {
        margin-top: 5px;
        padding: 5px 0 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &_value {
          font-family: "Montserrat", sans-serif;
          width: 50%;
          font-weight: 600;
          font-size: 16px;
          line-height: 140%;
          letter-spacing: -0.02em;
          color: #0f131e;
          &:last-child {
            text-align: right;
          }
        }
      }
      &_label {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.2px;
        color: rgba(60, 60, 60, 0.8);
      }
      &_value {
        font-family: "Montserrat", sans-serif;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: #0f131e;
      }
    }
    &_direct {
      margin: 8px 0;
      label {
        padding-left: 30px;
        span {
          background: #fff;
        }
        div {
          font-weight: 400;
          font-size: 16px;
          letter-spacing: -0.3px;
          color: #2d3643;
        }
      }
      &_note {
        font-size: 13px;
        line-height: 16px;
        letter-spacing: -0.3px;
        color: rgba(60, 60, 60, 0.8);
      }
    }
    &_terms {
      margin-bottom: 8px;
      label {
        span {
          background: #fff;
        }
      }
      &_value {
        padding-left: 35px;
        font-size: 16px;
        letter-spacing: -0.3px;
        color: #0f131e;
        margin-bottom: 8px;
        &__error {
          color: #ca4b15;
        }
        a {
          color: #509c5a;
        }
        .link {
          color: #509c5b;
          font-size: 16px;
          letter-spacing: -0.3px;
          text-decoration: underline;
          background: #c6eccc;
          cursor: pointer;
        }
        .text {
          font-size: 16px;
          letter-spacing: -0.3px;
          background: #c6eccc;
        }
      }
      // &__error span:not(.link) {
      //   border: 2px solid #ca4b15;
      // }
      &__error {
        label {
          label {
            span {
              &::after {
                left: 5px !important;
                top: 1px !important;
              }
            }
          }
        }
      }
      &_err {
        font-weight: 500;
        font-size: 11px;
        line-height: 130%;
        color: #ca4b15;
        margin: 2px 0 0 4px;
        &_wrap {
          display: flex;
          align-items: center;
          margin-top: 5px;
          margin-left: 31px;
        }
      }
    }
    &_btn {
      margin: 41px auto 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 244px;
      height: 39px;
      min-height: 39px;
      background: #509c5b;
      border-radius: 4px;
      border: 1px solid #509c5b;
      font-weight: 500;
      font-size: 14px;
      line-height: 110%;
      color: #ffffff;
      cursor: pointer;
      transition: 0.2s ease-in-out;
      &:hover {
        background: #ffffff;
        color: #509c5b;
        transition: 0.2s ease-in-out;
      }
    }
  }
  &__form {
    margin-top: 9px;
    &_row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 11px;
      label {
        width: calc(50% - 6px);
        input {
          border-color: #b3b3b3;
        }
      }
      > div {
        width: calc(50% - 6px);
      }
      &_light {
        justify-content: flex-start;
        margin-top: 11px;
        display: flex;
        label {
          width: 170px;
          margin-right: 20px;
          input {
            border-color: #b3b3b3;
          }
        }
      }
    }
  }
  &__modal {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(60, 60, 60, 0.05);
    backdrop-filter: blur(5px);
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    &_inner {
      max-width: 451px;
      width: 100%;
      padding: 40px 31px;
      position: relative;
      background: #fff;
    }
    &_close {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      background: none;
      border: none;
      outline: none;
    }
    &_title {
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      font-size: 18px;
      line-height: 130%;
      text-align: center;
      color: #0f131e;
    }
    &_btn {
      width: 199px;
      height: 39px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 44px auto 0 auto;
      background: #509c5b;
      border-radius: 4px;
      border: 1px solid #509c5b;
      cursor: pointer;
      font-weight: 500;
      font-size: 14px;
      line-height: 110%;
      color: #ffffff;
      text-decoration: none;
      transition: 0.2s ease-in-out;
      &:hover {
        color: #509c5b;
        background: #fff;
        transition: 0.2s ease-in-out;
      }
    }
  }
}

.radio {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &__title {
    font-size: 15px;
    line-height: 130%;
    color: #0f131e;
  }
  &__offset {
    margin-top: 14px;
  }
}

/* Hide the browser's default radio button */
.radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 50%;
}

/* When the radio button is checked, add a blue background */
.radio input:checked ~ .checkmark {
  border: 1px solid #b3b3b3;
  background-color: #fff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio .checkmark:after {
  top: 50%;
  left: 50%;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #509c5a;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 1170px) {
  .checkout {
    min-height: 870px;
  }
}

@media screen and (max-width: 991px) {
  .checkout {
    height: auto;
    &__wrapper {
      flex-direction: column-reverse;
      gap: 36px;
    }
    &__right {
      width: 100%;
    }
    &__left {
      width: 100%;
    }
  }
}
@media screen and (max-width: 840px) {
  .container {
    height: 100vh;
  }
  .footer {
    display: none;
  }
}
@media screen and (max-width: 554px) {
  .checkout {
    padding-inline: 16px;
    &__left {
      border: none;
      padding-inline: 0;
      .checkout__wrapper {
        &_title {
          margin-left: 10px;
        }
      }
    }
  }
}
@media screen and (max-width: 475px) {
  .checkout {
    padding-inline: 10px;
    &__right {
      padding-inline: 10px;
      gap: 24px;
      .checkout__wrapper {
        &_title {
          margin-left: 10px;
        }
      }
      & > div > a {
        width: 100%;
      }
    }
    &__form {
      &_row {
        flex-direction: column;
        gap: 10px;
        label,
        div {
          width: 100%;
        }
        &_light {
          gap: 20px;
          & > label {
            width: 100%;
            margin-right: 0;
          }
        }
      }
    }
  }
}


.wrapper {
  display: flex;
}

.block {
  width: 100%;
  position: relative;
  height: 100vh;
  flex: 7;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 4px;
    background: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    width: 4px;
    background: #b3b3b3;
    border-radius: 2px;
  }
}

@media screen and (max-width: 840px) {
  .block {
    height: 100vh;
  }
  .footer {
    display: none;
  }
}

/** @format */

.morePartners {
  // margin-top: 67px;
  background-image: url("../../../../img/partners-bg.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 2rem;
  &__inner {
    h2 {
      text-align: center;
      color: #fff;
    }
  }
  &__grid {
    padding: 3rem;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-template-rows: auto;
    a {
      border-radius: 0.75rem;
      width: 100%;
      min-height: 130px;
      background: #fff;
      display: grid;
      place-items: center;
      picture {
        display: block;
        width: 95%;
        img {
          object-fit: contain;
          width: 100%;
          aspect-ratio: 2/1;
          opacity: 0.8;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .morePartners {
    &__grid {
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

      div {
        min-height: 100px;
      }
    }
  }
}

@media screen and (max-width: 553px) {
  .morePartners {
    margin-top: 40px;
    &__grid {
      a {
        min-height: 80px;
      }
    }
  }
}
@media screen and (max-width: 553px) {
  .morePartners {
    &__grid {
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

      div {
        min-height: 80px;
      }
    }
  }
}

@media screen and (max-width: 467px) {
  .morePartners {
    &__grid {
      gap: 10px;

      grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));

      div {
        min-height: 70px;
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .morePartners {
    &__grid {
      gap: 5px;

      grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
      a{
        
      }
    }
  }
}
@media screen and (max-width: 300px) {
  .morePartners {
    &__grid {
      grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));

      div {
        min-height: 40px;
      }
    }
  }
}

.switcher {
  width: 34px;
  height: 16px;
  padding: 2px;
  display: flex;
  align-items: center;
  background: #509c5b;
  border-radius: 50px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  &__circle {
    min-width: 12px;
    width: 12px;
    height: 12px;
    min-height: 12px;
    background: #ffffff;
    transform: translateX(0px);
    border-radius: 50%;
    transition: 0.2s ease-in-out;
  }
  &__off {
    background: #ffffff;
    border: 1px solid #b3b3b3;
    transition: 0.2s ease-in-out;
    .switcher__circle {
      background: #509c5b;
      transform: translateX(17px);
      transition: 0.2s ease-in-out;
    }
  }
}

.switcherColorful {
  width: 46px;
  height: 24px;
  padding: 2px;
  display: flex;
  align-items: center;
  background: #509c5b;
  border-radius: 50px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  &__circle {
    min-width: 20px;
    width: 20px;
    height: 20px;
    min-height: 20px;
    background: #ffffff;
    transform: translateX(0px);
    border-radius: 50%;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15), 0px 1px 1px rgba(0, 0, 0, 0.08);
    transition: 0.2s ease-in-out;
  }
  &__off {
    background: #ca4b15;
    transition: 0.2s ease-in-out;
    .switcherColorful__circle {
      transform: translateX(22px);
      transition: 0.2s ease-in-out;
    }
  }
}

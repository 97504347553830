/** @format */

@import "../../../../styling/variables.scss";

.banner {
  
  padding-block: 2rem;
  padding-top: 67px;
  padding-bottom: 67px;



  background-color:white;
  &__wrapper {
    // background: url("../../../../img/cta.webp") no-repeat 50% 90%;
    // background-size: cover;
    position: relative;
    display: flex;
    padding: 1rem;
    justify-content: center;
    border: 4px solid #509c5b;
    background: #f8f8f8;
  }
  &__left {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 2.5rem;
    img {
      object-fit: cover;
      aspect-ratio: 2.25/1;
      height: 200px;
    }
  }
  &__image {
    position: absolute;
    left: 6%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 100px;
    }
  }
  &__right {
    color: #fff;
    padding: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    h3 {
      font-family: "Montserrat";
      font-size: 24px;
      font-weight: 500;
      text-align: center;
      color: #000;
    }
    &_down {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      a {
        width: auto;
      }
      p {
        font-weight: 500;
        font-size: 14px;
      }
      p {
        font-size: 24px;
        font-weight: 600;
      }
      // .cursor {
      //   position: absolute;
      //   right: 5px;
      //   bottom: -15px;
      //   scale: 2;
      // }
    }
    a {
      // flex: 1;
      align-self: center;
      justify-content: center;
    }
  }
}
.mobile {
  display: none;
}
@media (max-width: 1200px) {
  .banner {
    &__image{
      left: 5%;
      top: 50%;
      transform: translateY(-50%);
    }
    &__right {
      padding: 24px;
      gap: 20px;
      &_inner {
        flex: 4;
        h3 {
          font-size: 28px;
        }
        p {
          font-size: 28px;
        }
      }
      &_down {
        width: 100%;
        justify-content: space-between;
        gap: 0;
      }
    }
  }
}
@media (max-width: 991px) {
  .banner {
    &__wrapper {
      display: block;
      justify-content: center;
      padding-left: 1rem;
    }
    &__left {
      height: 123px;
      width: 123px;
      background-size: 80%;
    }
    &__image{
      position: static;
      transform: translateY(0);
    }
    &__right {
      &__inner {
        flex: 3;
      }
      a {
        justify-content: center;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 780px) {
  .banner {
    padding-block: 0px;
    &__right {
      &_down {
        flex-direction: column;
        gap: 10px;
      }
    }
  }
  .banner {
    &__left {
      display: none;
    }
  }
}

@media screen and (max-width: 576px) {
  .banner {
    padding-block: 40px;
    padding-inline: 10px;
    // margin-top: 40px;
    // margin-top: 50px;
    &__wrapper {
      padding: 0.5rem;
    }
    &__right {
      flex-direction: column;
      padding-block: 14px 20px;
      padding-inline: 0;
      gap: 12px;
      h3 {
        font-size: 12px;
        line-height: 130%;
      }
      &_inner {
        p {
          /* caption */
          font-family: "Montserrat";
          font-weight: 600;
          font-size: 14px;
          line-height: 120%;
          letter-spacing: -0.03em;
        }
      }
    }
    &__image {
      display: flex;
      justify-content: center;
      gap: 1rem;
      svg {
        height: 60px;
        color: #509c5b;
        width: 60px;
      }
      img {
        &:first-child {
          height: 100px;
          width: 100px;
        }
        &:last-child {
          width: 100px;
          height: auto;
        }
      }
    }
  }
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
}
@media screen and (max-width: 425px) {
  .banner {
    &__right {
      a {
        width: 100%;
      }
    }
  }
}

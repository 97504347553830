@import '../../styling/variables.scss';

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &__text{
    font-size: 16px;
    line-height: 130%;
    color: #0F131E;
  }
}


.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}


.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
}


.container input:checked ~ .checkmark {
  background-color: $green;
}


.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}


.container input:checked ~ .checkmark:after {
  display: block;
}


.container .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.error{
  margin-top: 2px;
  display: flex;
  align-items: center;
  svg{
    min-width: 12px;
    width: 12px;
  }
  div{
    font-weight: 500;
    font-size: 11px;
    line-height: 130%;
    color: #CA4B15;
    margin: 2px 0 0 4px;
  }
}

.fixxx{
  display: flex;
  flex-direction: column;
  justify-content: center;
  .container__text{
    padding-left: 35px;
  }
  .checkmark{
    top: 50%;
    transform: translateY(-50%);
  }
}
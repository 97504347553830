.finances{
  margin-top: 26px;
  &__header{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    div{
      &:last-child{
        margin-left: 24px;
      }
    }
  }
  &__subheader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    &_info{
      font-size: 13px;
      line-height: 20px;
      color: #B3B3B3;
    }
  }
}
@media screen and (max-width:660px){
  .finances{
    &__subheader{
      justify-content: flex-end;
    }
  }
}

@media screen and (max-width: 425px){
  .finances{
    & > div{
      &:last-child{
        justify-content: center;
      }
    }
  }
}
@import "../../styling/variables.scss";

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
  padding: 0;
}
h1,
h2 {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 36px;
}

.globalTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 110%;
  letter-spacing: -0.03em;
  color: #509c5b;
  margin-top: 60px;
  margin-left: 66px;
}

.video {
  display: none;
}
.countersWrapper {
  background: #d7d3c1;
  padding: 67px;
  padding-bottom: 38px;
  padding-top: 67px;
  margin-top: 99px;
}
.counters {
  display: grid;
  
  gap: 2rem;
  grid-template-columns: repeat(4, 1fr);

  padding-block: 2rem;
}

@media (max-width: 1200px) {
  .globalTitle {
    margin-left: 32px;
  }
}
@media (max-width: 991px) {
  .globalTitle {
    margin-left: 16px;
  }
}
@media screen and (max-width: 768px) {
  .counters {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 576px) {
  .globalTitle {
    font-size: 24px;
    margin-top: 28px;
  }
  h2 {
    font-size: 24px;
    font-weight: 700;
  }
  .countersWrapper{
    margin-top: 40px;
    padding-inline: 20px;
  }
}

@media screen and (max-width: 576px) {
  .video {
    display: flex;
    justify-content: center;
  }
  .counters{
    margin-top: 0;
    padding-top: 40px;
    // padding: 0;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 425px){
  .countersWrapper{
    padding-inline: 10px;

  }
  .counters{
    gap: 16px;
  }
}

.header {
  padding: 20px 0;
  position: sticky;
  z-index: 100;
  top: 0;
  background-color: rgba(255, 255, 255, 0.975);
  display: flex;
  align-items: flex-start;
  padding: 25px 23px 20px 20px;
  gap: 12px;
  justify-content: flex-end;
  &__wrapper {
    display: flex;
  }
  &__stats {
    display: flex;
    justify-content: space-between;
    &_info {
      display: flex;
      gap: 10px;
    }
    &_btns {
      display: none;
      align-items: center;
      gap: 10px;
      a {
        display: flex;
        align-items: center;
      }
    }
  }
  &__actions {
    display: flex;
    justify-content: space-between;
    margin-left: 30px;
    &_langLogout {
      display: flex;
      align-items: center;
      gap: 12px;
    }
    &_logo {
      display: none;
      img {
        width: 163px;
      }
    }
  }
  &__mobile {
    display: none;
  }
  &__menu {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100svh;
    background: #509c5a;
    padding-block: 16px;
    &_overflow {
      height: 100%;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 0px;
        background: #fff;
      }
      &::-webkit-scrollbar-thumb {
        width: 0px;
        background: #fff;
        border-radius: 2px;
      }
    }
    &_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-inline: 20px;
      margin-bottom: 24px;
      &_logo {
        background: #fff;
        display: flex;
        align-items: center;
      }
      &_button {
        display: flex;
        align-items: center;
        outline: none;
        padding: 0;
        border: none;
        cursor: pointer;
        background: none;
        color: #fff;
        svg {
          width: 24px;
          height: 24px;
        }
        a {
          margin-right: 16px;
        }
      }
    }
    &_client {
      &_image {
        width: 40px;
        height: 40px;
        min-width: 40px;
        max-width: 40px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 4px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-inline: 20px;
        &_wrapper {
          display: flex;
          align-items: center;
        }
      }
      &_name {
        color: #ffffff;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
      }
      &_status {
        margin-top: 4px;
        color: #509c5a;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
      }
      &_btn {
        width: 32px;
        min-width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #d7d3c1;
        cursor: pointer;
        margin-left: 11px;
        transition: 0.2s ease-in-out;
        svg {
          transition: 0.2s ease-in-out;
        }
        &_active {
          transition: 0.2s ease-in-out;
          svg {
            transform: rotate(180deg);
            transition: 0.2s ease-in-out;
          }
        }
      }
      &_right {
        display: flex;
        align-items: center;
      }
    }
    &_add {
      height: 0px;
      overflow: hidden;
      transition: 0.2s ease-in-out;
      &_active {
        padding: 11px 0 0 0;
        height: 90px;
        transition: 0.2s ease-in-out;
        &_sale {
          padding: 11px 0 0 0;
          height: 51px;
          transition: 0.2s ease-in-out;
        }
      }
    }
    &_subheader {
      background: rgba(255, 255, 255, 0.08);
      padding-inline: 20px;
      font-family: "Roboto";
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
      color: #fffff8;
      margin-block: 20px 14px;
    }
    &_menu {
      margin-top: 24px;
    }
    &_footer {
      margin-top: 24px;
      &_divider {
        display: block;
        border: none;
        background: #c5d483;
        height: 1.2px;
        margin-inline: 20px;
      }
    }
    &_switcher {
      display: flex;
      align-items: center;
      img {
        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }
  &__mobileBtn {
    display: none;
    border: none;
    background: transparent;
    cursor: pointer;
    &_visible {
      display: block;
      border: none;
      background: transparent;
    }
  }
}
@media (max-width: 1200px) {
  .header {
    flex-direction: column-reverse;
    align-items: flex-end;
  }
}
@media (max-width: 991px) {
  .header {
    &__actions {
      margin-left: 84px;
    }
  }
}

@media (max-width: 840px) {
  .header {
    &__wrapper {
      flex-direction: column-reverse;
      align-items: normal;
      gap: 32px;
      width: 100%;
    }
    &__actions {
      margin-left: 0;
      &_logo {
        display: block;
      }
    }
    &__stats {
      display: none;
      gap: 10px;
      &_btns {
        display: flex;
      }
    }
    &__mobile {
      display: block;
    }
    &__mobileBtn {
      padding: 0;
      display: block;
    }
  }
  .desktop{
    display: none;
  }
}
@media screen and (max-width: 575px) {
  .header {
    padding-inline: 10px;
    padding-block: 14px;
    border-bottom: 1px solid #c5d483;
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 414px) {
  .header {
    &__actions {
      &_logo {
        display: flex;
        align-items: center;
      }
      &_langLogout {
        gap: 8px;
        > div {
          &:first-child {
            width: auto;
          }
        }
        > button {
          img {
            width: 20px;
          }
        }
      }
    }
  }
}

@import "../../styling/variables.scss";

.aside {
  width: 227px;
  min-width: 227px;
  max-width: 227px;
  background: $lightGreen;
  padding-block: 26px;
  height: auto;
  min-height: 551px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  gap: 60px;
  overflow-y: auto;
  padding-inline: 0;
  &::-webkit-scrollbar {
    background: transparent;
    width: 0px;
  }
  &::-webkit-scrollbar-thumb{
    background-color: transparent;
  }
  &__list {
    margin-top: 48px;
    &_subtitle {
      background: rgba(255, 255, 255, 0.08);
      padding-inline: 16px;
      font-family: "Roboto";
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
      color: #fffff8;
      margin-block: 32px 16px;
    }
  }

  &__logo {
    display: flex;
    justify-content: center;
    a {
      display: block;
    }
  }

  &__dect {
    padding-top: 10px;
    border-top: 1px solid #d7d3c1;
    p {
      font-family: Roboto;
      font-size: 15px;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: #fff;
      margin-bottom: 8px;
    }
  }
  &__referral {
    margin-top: 48px;
    padding: 10px;
    padding-inline: 26px;
    p {
      font-family: Roboto;
      font-size: 15px;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: #fff;
      margin-bottom: 8px;
    }
  }
  &__adds{
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(3px);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  &__inner {
    max-width: 500px;
    width: 500px;
    border-radius: 8px;
    position: relative;
    padding: 30px 30px 50px 30px;
    background: #ffffff;
    &_close {
      cursor: pointer;
      border: none;
      outline: none;
      background: none;
      position: absolute;
      top: 15px;
      right: 15px;
    }
    &_wrapper {
      position: relative;
      display: flex;
      align-items: center;
      margin-top: 16px;
      border-bottom: 2px solid #509c5a;
      background: #f8f8f8;
    }
    &_title {
      font-family: "Montserrat", sans-serif;
      color: #0f131e;
      text-align: center;
      font-size: 24px;
      font-weight: 700;
      line-height: 130%;
    }
    &_code {
      padding: 6px 10px;
      color: #0f131e;
      font-size: 16px;
      line-height: 130%;
      text-align: center;
      border-radius: 8px;
      width: 100%;
      word-break: break-all;
    }
    &_helper{
      font-size: 12px;
      text-align: left;
      margin-top: 32px;
      opacity: 0.8;
    }
  }
  &__copy {
    cursor: pointer;
    border: none;
    outline: none;
    min-width: 33px;
    height: 31px;
    background: transparent;
    position: relative;
    &_state {
      text-align: center;
      pointer-events: none;
      opacity: 0;
      z-index: -1;
      transition: 0.2s ease-in-out;
      &_active {
        opacity: 1;
        z-index: 1;
        color: #509c5b;
        transition: 0.2s ease-in-out;
      }
    }
    img {
      width: 21px;
      height: 21px;
    }
  }
  &__tip {
    padding: 20px 16px;
    border-left: 2px solid #fa8a07;
    background: #f8e9b6;
    display: flex;
    align-items: center;
    margin-top: 8px;
    svg {
      min-width: 21px;
      height: 21px;
    }
    div {
      margin-left: 12px;
      color: #fa8a07;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.2px;
    }
  }
  &__btn {
    border-radius: 4px;
    background: #509c5a;
    max-width: 386px;
    margin: 16px auto 0 auto;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 110%;
    padding: 12px 12px;
    text-align: center;
    border: 1px solid #509c5a;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover {
      color: #509c5a;
      background: #fff;
      transition: 0.2s ease-in-out;
    }
  }
}

@media screen and (max-width: 840px) {
  .aside {
    display: none;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 82px;
    background-color: #fff;
    z-index: 2;
    min-height: 0px;
    padding: 20px 10px;
    &__links {
      align-items: center;
      &_logo {
        display: none;
      }
      a {
        padding-block: 9px;
        margin-bottom: 0;
      }
    }
    &__list {
      display: flex;
      width: 100%;
      justify-content: space-between;
      border-bottom: 1px solid #d7d3c1;
      margin-top: 0;
    }
    &__adds {
      display: none;
    }
  }
}

@media screen and (max-width: 540px) {
  .modal {
    &__inner {
      max-width: 90%;
      width: 100%;
    }
    &__tip {
      line-height: 120%;
      padding: 12px 9px;
      div {
        font-size: 14px;
      }
    }
    &__inner {
      &_code {
        width: calc(100% - 33px);
      }
    }
    &__copy {
      min-width: 33px;
    }
  }
}

@media screen and (max-width: 400px) {
  .modal {
    &__inner {
      padding: 20px 15px 30px 15px;
      &_code {
        word-break: break-all;
      }
    }
  }
}

.new {
  padding-top: 40px;
  min-height: calc(100svh - 150px);
  &__inner {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 60px;
  }
  &__body {
    figure {
      margin: 0 auto;
    }
    img {
      width: 100%;
      object-fit: cover;
      height: 450px;
    }
    span {
      display: block;
      margin-top: 24px;
      font-family: "Roboto";
      font-size: 12px;
      font-weight: 400;
      line-height: 16.8px;
      color: #000;
      border-left: 2px solid #509c5b;
      padding-left: 5px;
    }

    h1 {
      font-family: "Montserrat";
      font-size: 32px;
      font-weight: 600;
      line-height: 38.4px;
      margin-top: 8px;
    }
    p {
      margin-block: 18px;
      font-family: "Roboto";
      font-size: 15px;
      font-weight: 400;
      line-height: 21px;
      text-align: left;
      color: #3c3c3ccc;
    }
  }
  &__aside {
    h3 {
      font-family: "Montserrat";
      font-size: 24px;
      font-weight: 700;
      line-height: 31.2px;
      text-align: left;
    }
    & > div {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
}

.card {
  position: relative;
  a {
    position: relative;
    display: block;
  }
  &__main {
    width: 100%;
    height: 200px;
    background-size: 170%;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.5s ease;
    border-radius: 2px;
  }
  &__overlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 100%;
    justify-content: flex-end;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 100%
    );

    h5 {
      font-family: "Montserrat";
      font-size: 18px;
      font-weight: 700;
      line-height: 23.4px;
      color: #fff;
    }
    span {
      font-family: "Roboto";
      font-size: 12px;
      font-weight: 400;
      line-height: 16.8px;
      color: #fff;
      border-left: 2px solid #509c5b;
      padding-left: 5px;
    }
  }
  &:hover {
    .card__main {
      background-size: 160%;
    }
    .card__overlay {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    }
  }
}

.tempNew {
  &__images {
    display: flex;
    gap: 20px;
    img {
      width: 48%;
      height: auto;
    }
  }
  &__floatLeft {
    img {
      height: auto;
      width: 30%;
      float: left;
    }
  }
  &__floatRight {
    img {
      height: auto;
      width: 40%;
      float: right;
      object-fit: contain;
    }
  }
}

@media screen and (max-width: 991px) {
  .new {
    &__inner {
      grid-template-columns: 1fr;
    }
  }
}

@media screen and (max-width: 768px) {
  .tempNew {
    & > img {
      width: 100%;
      object-fit: contain;
      height: auto;
    }
  }
}

.pending {
  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    color: #0f131e;
    margin: 0;
    padding: 0;
    font-family: "Montserrat", sans-serif;
    margin-top: 45px;
  }
  &__table {
    margin-top: 24px;
    &_header {
      display: flex;
      align-items: center;
      div {
        padding: 8px 0;
        font-weight: 500;
        font-size: 16px;
        line-height: 110%;
        color: rgba(60, 60, 60, 0.8);
        flex-grow: 1;
        width: 33.3%;
        &:first-child {
          padding-left: 16px;
          width: 38.3%;
          min-width: 170px;
        }
        &:last-child {
          padding-right: 16px;
          width: 33.3%;
        }
      }
    }
  }
}
@media screen and (max-width: 560px) {
  .pending {
    &__table {
      &_header {
        justify-content: space-between;
        padding-inline: 16px;
        div {
          &:first-child {
            display: none;
          }
          &:nth-child(2){
          width: 65%
          }
          &:last-child {
            text-align: left;
            padding-right: 0;
            width: 35%;
          }
        }
      }
    }
  }
}

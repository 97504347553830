.dectToken {
}
.title {
  font-family: "Montserrat";
  font-size: 42px;
  font-weight: 600;
  line-height: 46.2px;
  letter-spacing: -0.03em;
  color: #0f131e;
  margin-bottom: 24px;
}
.subtitle {
  font-family: "Roboto";
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  color: #0f131e;
}
.hero {
  padding-block: 76px;
  &__inner {
    display: flex;
    justify-content: space-between;
    gap: 90px;
    align-items: center;
  }
  &__left {
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex: 1.5;
    & > h1 {
      font-family: "Montserrat";
      font-size: 54px;
      font-weight: 600;
      line-height: 59.4px;
      letter-spacing: -0.03em;
      color: #509c5a;
    }
    & > p {
      font-family: "Montserrat";
      font-size: 18px;
      font-weight: 500;
      line-height: 23.4px;
      color: #0f131e;
    }
    & > ul {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 0;
      margin: 0;
      li {
        display: flex;
        align-items: center;
        gap: 12px;
        p {
          font-family: "Montserrat";
          font-size: 16px;
          font-weight: 600;
          line-height: 19.2px;
          letter-spacing: -0.03em;
          color: #0f131e;
        }
      }
    }
  }
}
.stats {
  background: #f5f5f5;
  padding-block: 62px;
  &__inner {
    & > h2 {
      text-align: center;
      max-width: 754px;
      margin-inline: auto;
    }
    & > p {
      text-align: center;
      max-width: 584px;
      margin-inline: auto;
    }
    &_blocks {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 32px;
      margin-top: 32px;
      & > div {
        border-left: 4px solid #c5d483;
        padding-left: 6px;
        h3 {
          font-family: "Roboto";
          font-size: 42px;
          font-weight: 400;
          line-height: 50.4px;
          letter-spacing: -0.03em;
          color: #0f131e;
        }
        span {
          font-family: "Roboto";
          font-size: 24px;
          font-weight: 500;
          line-height: 28.8px;
          color: #509c5a;
        }
      }
    }
  }
}
.advantages {
  padding-block: 70px 60px;
  &__inner {
    & > h2 {
      text-align: center;
    }
    & > p {
      text-align: center;
      max-width: 584px;
      margin-inline: auto;
      margin-bottom: 40px;
    }
    &_list {
      display: flex;
      flex-wrap: wrap;
      gap: 32px;
      margin-top: 32px;
      justify-content: center;
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        flex: 1;
        img {
          margin-bottom: 6px;
        }
        h3 {
          font-family: "Roboto";
          font-size: 24px;
          font-weight: 600;
          line-height: 33.6px;
          color: #0f131e;
          text-align: center;
        }
        p {
          font-family: "Roboto";
          font-size: 15px;
          font-weight: 400;
          line-height: 21px;
          text-align: center;
        }
      }
    }
  }
}
.mission {
  &__inner {
    background: #509c5a;
    padding: 32px 40px;
    display: flex;
    align-items: center;
    gap: 64px;
    &_left {
      flex: 1.5;
      h2 {
        color: #fff;
      }
      p {
        color: #fff;
        font-family: "Roboto";
        font-size: 15px;
        font-weight: 400;
        line-height: 21px;
      }
    }
    ul {
      flex: 1;
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 8px;
      li {
        display: flex;
        gap: 8px;
        align-items: center;
        p {
          font-family: "Roboto";
          font-size: 15px;
          font-weight: 400;
          line-height: 21px;
          color: #fff;
        }
      }
    }
  }
}
.skills {
  margin-top: 50px;
  &__inner {
    display: flex;
    align-items: center;
    gap: 20px;
    &_left {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      flex: 1.5;
      & > div {
        padding: 25px 32px 20px 40px;
        h3 {
          font-family: "Montserrat";
          font-size: 18px;
          font-weight: 600;
          line-height: 23.4px;
          color: #0f131e;
          margin-bottom: 10px;
        }
        span {
          font-family: "Roboto";
          font-size: 42px;
          font-weight: 600;
          line-height: 50.4px;
          letter-spacing: -0.03em;
          color: #0f131e;
        }
        &:first-child {
          background: rgba(215, 211, 193, 1);
        }
        &:nth-child(2) {
          background: rgba(215, 211, 193, 0.7);
        }
        &:nth-child(3) {
          background: rgba(215, 211, 193, 0.4);
        }
        &:last-child {
          background: rgba(215, 211, 193, 0.1);
        }
      }
    }
    &_right {
      flex: 1;
      p {
        font-family: "Roboto";
        font-size: 15px;
        font-weight: 400;
        line-height: 21px;
        color: #0f131e;
      }
    }
  }
}

@media (max-width: 991px) {
  .hero {
    &__inner {
      flex-direction: column-reverse;
      gap: 16px;
      & > img {
        width: 300px;
      }
    }
  }
  .stats {
    &__inner {
      &_blocks {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
      }
    }
  }
}
@media screen and (max-width: 781px) {
  .skills {
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 768px) {
  .mission {
    &__inner {
      flex-direction: column;
    }
  }
  .skills {
    &__inner {
      flex-direction: column-reverse;
    }
  }
}

@media screen and (max-width: 576px) {
  .title {
    font-size: 24px;
    font-weight: 700;
    line-height: 31.2px;
    margin-bottom: 16px;
  }
  .subtitle {
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
  }
  .hero {
    padding-top: 0;
    padding-bottom: 62px;
    &__inner {
      & > img {
        width: 156px;
        align-self: flex-end;
      }
    }
    &__left {
      h1 {
        font-size: 32px;
        font-weight: 600;
        line-height: 38.4px;
      }
      p {
        font-size: 18px;
        font-weight: 500;
        line-height: 23.4px;
      }
    }
  }
  .stats {
    padding-block: 40px;
    &__inner {
      & > h2 {
        text-align: left;
      }
      & > p {
        text-align: left;
      }
      &_blocks {
        margin-top: 16px;
        row-gap: 16px;
        column-gap: 20px;
        & > div {
          h3 {
            font-size: 30px;
            font-weight: 400;
            line-height: 36px;
            letter-spacing: -0.03em;
          }
          span {
            font-size: 18px;
            font-weight: 500;
            line-height: 21.6px;
          }
        }
      }
    }
  }
  .advantages {
    padding-block: 40px;
    &__inner {
      &_list {
        display: flex;
        flex-direction: column;
      }
    }
  }
  .mission {
    &__inner {
      padding: 40px 10px;
      align-items: flex-start;
    }
  }
  .skills {
    &__inner {
      &_left {
        width: 100%;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        & > div {
          padding: 10px 24px;
          display: flex;
          justify-content: space-between;
          gap: 32px;
        }
      }
    }
  }
}

.wrapper {
  display: flex;
}

.container {
  width: 100%;
  position: relative;
  height: 100vh;
  flex: 7;
  overflow: auto;
}
.statistics {
  padding: 0 23px 84px 20px;
  height: auto;
  min-height: calc(100vh - 200px);
}
.table {
  margin-top: 1rem;
  &__row {
    display: flex;
    padding: 16px;
    & > div {
      flex: 1;
      &:nth-child(2){
        flex: 1.5;
      }
      & > p {
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: rgba(60, 60, 60, 0.8);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: none;
      }
      & > div {
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: #0f131e;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
  &__body {
    .empty {
      background: #fff !important;
      text-align: center;
      margin-top: 5rem;
      font-size: 1.5rem;
      color: #509c5b;
    }
    & > div:nth-child(odd) {
      background: #f5f5f5;
    }
  }
}

.pagination {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1rem;
  &__pages {
    display: flex;
    gap: 0.5rem;
  }
  &__arrows {
    display: flex;
    place-content: center;
    cursor: pointer;
  }
}

@media (max-width: 1024px) {
  .table {
    &__header {
      display: none;
    }
    &__row {
      flex-direction: column;
      > div {
        display: flex;
        justify-content: space-between;
        & > p {
          display: block;
        }
      }
    }
  }
}

.newspaper {
  padding-top: 67px;
  // padding-bottom: 40px;
  overflow: hidden;

  // background: #f8f8f8;
  &__outer{
    padding-block: 32px;
    background: #509c5b;
  }
  &__wrapper {
    // position: absolute;
    left: 0;
    display: flex;
    gap: 32px;
    // align-items: center;
    // justify-content: space-between;
    // max-width: 1000px;
    margin: 0 auto;
    // padding-right: 30px;
    // a {
    //   width: 180px;
    //   max-width: 180px;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;

    //   picture {
    //     img {
    //       width: 100%;
    //       object-fit: contain;
    //       max-height: 90px;
    //     }
    //   }
    //   svg {
    //     width: 100%;
    //     object-fit: contain;
    //     max-height: 90px;
    //   }
    //   &:first-child {
    //     img {
    //       padding: 10px;
    //     }
    //   }
    // }
  }
  &__title {
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 36px;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 991px) {
  .newspaper {
    &__wrapper {
      padding-right: 0px;

      // flex-wrap: wrap;
      a {
        width: 50%;
        max-width: 100%;
        picture {
          img {
            max-width: 180px;
          }
        }
        svg {
          max-width: 180px;
        }
        &:nth-child(3),
        &:nth-child(4) {
          picture {
            img {
              max-width: 220px;
            }
          }
          svg {
            max-width: 220px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .newspaper {
    padding-block: 40px;
    padding-top: 60px;
    &__title {
      font-size: 24px;
      font-weight: 700;
    }
    &__outer{
      padding-block: 16px;
    }
    &__wrapper {
      padding-bottom: -20px;

      // flex-direction: column;
      // display: flex;
      // padding-bottom: 0;
      // flex-wrap: wrap;
      // gap: 1.5rem;
      gap: 16px;

      a {
        padding: 5px;
        margin-bottom: 20px;
        // width: 50%;
        // max-width: 100%;
        &:first-child {
          // order: 3;
          // width: 30%;
          img {
            padding: 0px;
          }
        }
        &:nth-child(2) {
          // order: 1;
          // width: 30%;
        }
        &:nth-child(3) {
          // order: 4;
          // width: 60%;

          margin-right: 20px;
        }
        &:last-child {
          // order: 2;
          margin-right: 20px;
          // width: 60%;
        }
        picture {
          img {
            max-height: 50px;
          }
        }
      }
    }
  }
}

.finances {
  padding: 0 23px 84px 20px;
  height: calc(100vh - 200px);
  overflow-y: scroll;
  &__info {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 24px;
  }
  &__plants {
    margin-top: 30px;
    &_title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 24px;
      font-family: "Montserrat";
      font-weight: 700;
      > span {
        font-weight: 600;
        font-size: 20px;
        line-height: 140%;
      }
    }

    &__plants {
      margin-top: 30px;

      &_title {
        font-size: 24px;
        font-family: "Montserrat";
        font-weight: 700;
      }
    }
  }

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 60px;

    &_title {
      margin: 20px auto 0 auto;
      max-width: 269px;
      text-align: center;
      font-weight: 600;
      font-size: 24px;
      line-height: 140%;
      color: #0f131e;
    }

    a {
      margin-top: 20px;
      width: 244px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      width: 200px;
    }
  }
  .card {
    border: 1px solid #f1f1f1;
    border-radius: 2px;
    padding: 24px 18px;
    display: flex;
    align-items: center;
    gap: 8%;
    transition: 0.2s ease-in-out;

    &__info {
      display: flex;
      justify-content: space-between;
      flex: 1.5;

      h2 {
        display: flex;
        align-items: center;
      }
    }

    &__value {
      font-size: 24px;
      line-height: 120%;
      display: flex;
      align-items: center;
      letter-spacing: -0.03em;
      color: #0f131e;
      font-weight: 400;
      transition: 0.1s ease-in-out;

      span {
        font-weight: 500;
        font-size: 20px;
        line-height: 100%;
        letter-spacing: -0.03em;
        color: #0f131e;
        padding-left: 9px;
      }
    }

    &__additional {
      flex: 2;
      display: flex;
      justify-content: space-between;
      gap: 10px;

      &_price {
        font-size: 18px;
        display: flex;
        flex: 2;
        align-items: center;
        gap: 4px;
        font-family: "Roboto";
        font-weight: 500;

        span {
          font-size: 24px;
          font-family: "Montserrat";
        }
      }

      a {
        width: 200px;
        min-width: 200px;
        max-width: 200px;
        justify-content: center;
        flex: 1;
      }
    }

    &__myPlants {
      &_empty {
        margin-left: auto;

        a {
          width: 200px;
          justify-content: center;
        }
      }
    }

    &__plants {
      &_emptyText {
        font-size: 20px;
        line-height: 140%;
        color: #6ba32b;
      }
    }

    &__harvest {
      &_empty {
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        font-size: 18px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #b3b3b3;
      }
    }

    &__empty {
      &_cost {
        font-family: "Montserrat";
        font-size: 24px;
        line-height: 120%;
        letter-spacing: -0.03em;
        color: #b3b3b3;
        font-weight: 500;

        span {
          font-weight: 500;
          font-size: 24px;
          line-height: 120%;
          letter-spacing: -0.03em;
          color: #b3b3b3;
          font-weight: 500;
          font-family: "Montserrat";
        }
      }
    }

    &__auto {
      display: flex;
      align-items: center;
      margin-left: auto;

      a {
        width: 199px;
        min-width: 199px;
      }

      &_noty {
        display: flex;
        align-items: flex-start;
        margin-right: 31px;

        div {
          margin-left: 8px;
          font-weight: 500;
          font-size: 16px;
          line-height: 120%;
          letter-spacing: -0.2px;
          color: #6ba32b;
          max-width: 310px;
        }
      }
    }
  }

  .text {
    display: flex;
    justify-content: space-between;
    flex: 1.5;
    h2 {
      display: flex;
      align-items: center;
    }
  }
  &__value {
    font-size: 24px;
    line-height: 120%;
    display: flex;
    align-items: center;
    letter-spacing: -0.03em;
    color: #0f131e;
    font-weight: 400;
    transition: 0.1s ease-in-out;
    span {
      font-weight: 500;
      font-size: 20px;
      line-height: 100%;
      letter-spacing: -0.03em;
      color: #0f131e;
      padding-left: 9px;
    }
  }
  &__additional {
    flex: 2;
    display: flex;
    justify-content: flex-end;
    &_price {
      font-size: 20px;
      display: flex;
      flex: 2;
      align-items: center;
      gap: 8px;
      font-family: "Inter";
      font-weight: 600;
      span {
        font-size: 24px;
        font-family: "Montserrat";
      }
    }
    a {
      max-width: 200px;
      justify-content: center;
      flex: 1;
    }
  }
  &__myPlants {
    &_empty {
      margin-left: auto;
      a {
        width: 200px;
        justify-content: center;
      }
    }
  }
  &__plants {
    &_emptyText {
      font-size: 20px;
      line-height: 140%;
      color: #509c5b;
    }
  }
  &__harvest {
    &_empty {
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      font-size: 18px;
      line-height: 140%;
      letter-spacing: -0.02em;
      color: #b3b3b3;
    }
  }
  &__empty {
    &_cost {
      font-size: 24px;
      line-height: 120%;
      letter-spacing: -0.03em;
      color: #b3b3b3;
      span {
        font-weight: 500;
        font-size: 20px;
        line-height: 120%;
        letter-spacing: -0.03em;
        color: #b3b3b3;
      }
    }
  }
  &__auto {
    display: flex;
    align-items: center;
    a {
      width: 199px;
      min-width: 199px;
    }
    &_noty {
      display: flex;
      align-items: flex-start;
      margin-right: 31px;
      div {
        margin-left: 8px;
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: -0.2px;
        color: #509c5b;
        max-width: 310px;
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .text {
    justify-content: flex-start;

    h2 {
      margin-right: 20px;
    }
  }
  .card {
    gap: 2%;

    &__info {
      justify-content: flex-start;
      flex: 1;
      max-width: 220px;

      h2 {
        margin-right: 20px;
      }
    }
  }
}
@media screen and (max-width: 840px) {
  .finances {
    height: auto;
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 768px) {
  .finances {
    padding-inline: 10px;
  }
}

@media screen and (max-width: 768px) {
  .card {
    flex-wrap: wrap;
    padding: 9px 18px;
    gap: 10px;
  }
}

@media screen and (max-width: 475px) {
  .card {
    flex-direction: column;
    align-items: flex-start;

    &__auto {
      flex-direction: column;
      gap: 10px;

      &_noty {
        margin-left: 0;

        & > div {
          max-width: 475px;
        }
      }
    }
    &__value {
      justify-content: flex-end;
    }

    .text {
      width: 100%;
    }
    &__additional {
      flex-direction: column;
      width: 100%;
      margin-top: 10px;
      a {
        align-self: flex-end;
      }
    }
    &__info {
      max-width: none;
      width: 100%;
    }
  }
}

@media screen and (max-width: 375px) {
  .card {
    &__myPlants {
      &_empty {
        width: 100%;
        margin-left: 0;

        a {
          width: 100%;
          max-width: 400px;
          min-width: 0;
        }
      }
    }

    &__additional {
      a {
        width: 100%;
        max-width: 400px;
        min-width: 0;
      }
    }

    &__auto {
      a {
        width: 100%;
        max-width: 400px;
        min-width: 0;
      }
    }
  }
}

.founders {
  margin-top: 50px;
  h2 {
    text-align: center;
  }
  &__inner {
    gap: 18px;
    // max-width: 1000px;
    display: flex;
    margin: 38px auto 0 auto;
    &:nth-child(2) {
      // margin-right: -90px;
    }
  }
  &__founder {
    flex: 1;
    display: flex;
    align-items: flex-start;
    // flex-direction: column;
    gap: 14px;
    img {
      width: 180px;
    }
    &_info {
      // border-left: 4px solid #d7d3c1;
      padding: 10px 10px 10px 16px;
      display: flex;
      flex-direction: column;
      gap: 14px;
      // max-width: 400px;
      & > div {
        &:first-child {
          h3 {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 130%;
            color: #0f131e;
          }
          h4 {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 120%;
            letter-spacing: -0.03em;
            color: #509c5b;
          }
        }
        &:nth-child(2) {
          h4 {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 120%;
            letter-spacing: -0.03em;
            color: #000000;
            margin-bottom: 10px;
          }
          p {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            color: rgba(60, 60, 60, 0.8);
          }
        }
        &:last-child {
          h4 {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 120%;
            letter-spacing: -0.03em;
            color: #000000;
          }
          ul {
            list-style-position: inside;
            padding-left: 12px;
            li {
              font-family: 'Roboto';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 140%;
              color: rgba(60, 60, 60, 0.8);
            }
          }
        }
      }
    }
  }
}

// @media screen and (max-width: 1200px) {
//   .founders {
//     &__inner {
//       padding-left: 120px;
//     }
//   }
// }

@media screen and (max-width: 990px) {
  .founders {
    &__inner {
      flex-direction: column;
      // padding-left: 80px;
      &:nth-child(2) {
        margin-right: 0px;
      }
    }
  }
}

@media screen and (max-width: 770px) {
  .founders {
    &__founder {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &__inner {
      &:nth-child(2) {
        margin-right: 0px;
      }
      padding-left: 0px;

      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
  }
}

@media screen and (max-width: 490px) {
  .founders {
    &__founder {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

.imprint {
  min-height: 650px;
  padding-top: 20px;
  &__image {
    height: 260px;
    width: 100%;
    background: url("../../img/imprint.webp");
    position: relative;
    background-position: center;
    background-size: cover;
    &::after {
      content: "";
      height: 19px;
      width: 58%;
      background-color: #509c5b;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
  h2 {
    margin-top: 10px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 42px;
    line-height: 110%;
    letter-spacing: -0.03em;
    color: #0f131e;
  }
  &__wrapper {
    margin-top: 32px;
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
    margin-bottom: 100px;
  }
  &__block {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 45%;
    &_title {
      background: #f5f5f5;
      border-left: 3px solid #509c5b;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 130%;
      color: #000000;
      padding-block: 10px;
      padding-inline: 32px;
    }
    &_body {
      padding-inline: 32px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      color: #000000;
    }
  }
  &__address,
  &__authority,
  &__terms,
  &__privacy {
    & > div {
      display: flex;
      flex-direction: column;
      gap: 10px;
      div {
        display: flex;
        gap: 10px;
        align-items: flex-start;
      }
    }
  }
  &__bundesbank,
  &__verification {
    flex: 100%;
    & > div {
      display: flex;
      gap: 10px;
      & > div {
        display: flex;
        gap: 10px;
        align-items: flex-start;
        flex-direction: column;
        flex: 1;
        &:last-child {
          padding-left: 32px;
        }
        div {
          padding-left: 32px;
          display: flex;
          align-items: flex-start;
          gap: 10px;
        }
      }
    }
  }
  &__register,
  &__liability,
  &_copy {
    flex: 100%;
  }
}

@media (max-width: 991px) {
  .imprint {
    &__block {
      flex: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  .imprint {
    &__bundesbank,
    &__verification {
      & > div {
        flex-direction: column;
        & > div {
          &:last-child {
            padding-left: 0;
          }
          div {
            padding-left: 0;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 576px) {
  .imprint {
    &__image {
      height: 120px;
      &::after {
        height: 10px;
      }
    }
    h2 {
      font-size: 24px;
      margin-top: 20px;
    }
    &__wrapper {
      margin-top: 20px;
      margin-bottom: 50px;
    }
    &__block {
      gap: 8px;
      &_title {
        font-size: 18px;
        padding-inline: 10px;
        padding-block: 6px;
      }
      &_body {
        padding-inline: 10px;
      }
    }
  }
}

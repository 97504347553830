.main {
  padding: 0 23px 84px 20px;
  height: calc(100vh - 200px);
  overflow-y: scroll;
  &__wrapper {
    width: 100%;
  }
  &__error {
    margin-top: 2px;
    padding-left: 16px;
    display: flex;
    align-items: center;
    svg {
      min-width: 12px;
      width: 12px;
    }
    div {
      font-weight: 500;
      font-size: 11px;
      line-height: 130%;
      color: #ca4b15;
      margin: 2px 0 0 4px;
    }
  }
  &__info {
    padding-top: 24px;
  }
  &__image {
    width: 300px;
    margin-right: 36px;
    img {
      width: 100%;
    }
  }
  &__list {
    margin: 0;
    padding: 0;
    &_item {
      position: relative;
      font-size: 16px;
      line-height: 140%;
      letter-spacing: -0.2px;
      color: #0f131e;
      padding-left: 16px;
      &::before {
        content: "";
        position: absolute;
        top: 8px;
        left: 0;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: #0f131e;
      }
    }
  }
  &__labels {
    width: 100%;
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      position: relative;
      & > img:not(.miniimg) {
        width: 280px;
      }
      &:last-child {
        margin-right: 2rem;
      }
      .miniimg {
        position: absolute;
        width: 140px;
        left: 0;
        bottom: 64px;
      }
    }
  }
  &__table {
    margin-top: 24px;
    width: 589px;
    &_row {
      padding: 8px 7px 8px 10px;
      display: flex;
      border-bottom: 1px solid #d7d3c1;
    }
    &_title {
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      color: rgba(60, 60, 60, 0.8);
      width: 100%;
    }
    &_data {
      display: flex;
    }
    &_weight {
      width: 80px;
      min-width: 80px;
      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      letter-spacing: -0.02em;
      color: #0f131e;
      text-align: right;
      font-family: "Montserrat", sans-serif;
    }
    &_value {
      width: 124px;
      min-width: 124px;
      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      letter-spacing: -0.02em;
      color: #0f131e;
      text-align: right;
      font-family: "Montserrat", sans-serif;
    }
  }
  &__action {
    padding: 16px;
    background: #f5f5f5;
    margin-top: 54px;
    border: 2px solid #509c5a;
    &_wrapper {
      display: flex;
      flex-direction: column;
      max-width: 280px;
    }
    &_title {
      position: relative;
      font-weight: 600;
      font-size: 20px;
      line-height: 120%;
      letter-spacing: -0.03em;
      font-family: "Montserrat", sans-serif;
      color: #0f131e;
      padding-left: 10px;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 4px;
        height: 100%;
        background: #d7d3c1;
      }
    }
    &_setter {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &_button {
      height: 40px;
      border: 1px solid #d7d3c1;
      border-radius: 0 2px 2px 0;
      border-left: none;
      background: #ffffff;
      color: #0f131e;
      cursor: pointer;
      outline: none;
      padding: 0 16px 0 8px;
      &:first-child {
        border-radius: 2px 0 0 2px;
        border: 1px solid #d7d3c1;
        border-right: none;
        padding: 0 8px 0 16px;
      }
    }
    &_input {
      height: 40px;
      border: 1px solid #d7d3c1;
      border-left: none;
      border-right: none;
      outline: none;
      text-align: center;
      font-size: 20px;
      line-height: 120%;
      letter-spacing: -0.03em;
      color: #0f131e;
      width: 70px;
      text-align: right;
      padding-right: 4px;
    }
    &_text {
      font-size: 20px;
      line-height: 120%;
      letter-spacing: -0.03em;
      color: #0f131e;
      background: #ffffff;
      border: 1px solid #d7d3c1;
      height: 40px;
      display: flex;
      align-items: center;
      border-left: none;
      border-right: none;
      width: 110px;
    }
    &_noty {
      margin-top: 3px;
      text-align: center;
      font-size: 12px;
      line-height: 24px;
      letter-spacing: -0.2px;
      color: rgba(60, 60, 60, 0.8);
      display: flex;
      gap: 0.5rem;
      justify-content: space-between;
    }
    &_err {
      color: red;
      font-size: 12px;
      text-align: center;
    }
    &_amount {
      display: flex;
      flex-direction: column;
      margin-top: 16px;
    }
    &_left {
      display: flex;
      align-items: center;
    }
    &_total {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding-inline: 36px;
      justify-content: space-between;
      margin-top: 8px;
      &_label {
        font-family: "Roboto";
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.2px;
        color: #0f131e;
      }
      &_value {
        font-family: "Montserrat";
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: -0.02em;
        color: #0f131e;
      }
    }
    &_right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      a {
        text-decoration: none;
      }
    }
  }
}
.checkout {
  padding: 12px 24px 60px 21px;
  height: calc(100vh - 200px);
  min-height: 790px;
  &__green {
    &_link {
      color: #509c5b;
      font-size: 16px;
      letter-spacing: -0.3px;
    }
  }
  &__text {
    color: #0f131e;
    font-weight: bolder;
    margin-top: 15px;
  }
  &__wrapper {
    margin-top: 54px;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    &_reverse {
      flex-direction: row;
    }
    &_title {
      position: relative;
      font-weight: 600;
      font-size: 20px;
      line-height: 120%;
      letter-spacing: -0.03em;
      font-family: "Montserrat", sans-serif;
      color: #0f131e;
      padding-left: 10px;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0px;
        width: 4px;
        height: 100%;
        background: #d7d3c1;
      }
      &_side {
        margin-bottom: 14px;
      }
    }
  }
  &__left {
    width: calc(60% - 10px);
    border: 1px solid #b3b3b3;
    border-radius: 2px;
    padding: 16px 32px 42px 32px;
  }
  &__error {
    color: red;
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
  }
  &__right {
    width: calc(40% - 10px);
    background: #c6eccc;
    border: 1px solid #d7d3c1;
    border-radius: 2px;
    padding: 18px 24px 18px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &_bottom {
      margin-top: 20px;
    }
    &_product {
      width: 40%;
    }
    &_quantity {
      width: 30%;
      text-align: right;
    }
    &_subtotal {
      width: 30%;
      text-align: right;
    }
    &_table {
      margin-top: 14px;
      &_row {
        display: flex;
        align-items: center;
        &_values {
          display: flex;
          align-items: center;
          padding: 9px 0 0 0;
        }
      }
      &_total {
        margin-top: 17px;
        padding: 17px 0 0 0;
        display: flex;
        align-items: center;
        border-top: 1px solid #509c5b;
        justify-content: space-between;
        &_value {
          font-family: "Montserrat", sans-serif;
          width: 50%;
          font-weight: 600;
          font-size: 16px;
          line-height: 140%;
          letter-spacing: -0.02em;
          color: #0f131e;
          &:last-child {
            text-align: right;
          }
        }
      }
      &_stats {
        margin-top: 5px;
        padding: 5px 0 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &_value {
          font-family: "Montserrat", sans-serif;
          width: 50%;
          font-weight: 600;
          font-size: 16px;
          line-height: 140%;
          letter-spacing: -0.02em;
          color: #0f131e;
          &:last-child {
            text-align: right;
          }
        }
      }
      &_label {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.2px;
        color: rgba(60, 60, 60, 0.8);
      }
      &_value {
        font-family: "Montserrat", sans-serif;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: #0f131e;
      }
    }
    &_direct {
      margin: 8px 0;
      label {
        padding-left: 30px;
        span {
          background: #fff;
        }
        div {
          font-weight: 400;
          font-size: 16px;
          letter-spacing: -0.3px;
          color: #2d3643;
        }
      }
      &_note {
        font-size: 13px;
        line-height: 16px;
        letter-spacing: -0.3px;
        color: rgba(60, 60, 60, 0.8);
      }
    }
    &_terms {
      margin-bottom: 8px;
      label {
        span {
          background: #fff;
        }
      }
      &_value {
        padding-left: 35px;
        font-size: 16px;
        letter-spacing: -0.3px;
        color: #0f131e;
        margin-bottom: 8px;
        &__error {
          color: #ca4b15;
        }
        a {
          color: #509c5a;
        }
        .link {
          color: #509c5b;
          font-size: 16px;
          letter-spacing: -0.3px;
          text-decoration: underline;
          background: #c6eccc;
          cursor: pointer;
        }
        .text {
          font-size: 16px;
          letter-spacing: -0.3px;
          background: #c6eccc;
        }
      }
      // &__error span:not(.link) {
      //   border: 2px solid #ca4b15;
      // }
      &__error {
        label {
          label {
            span {
              &::after {
                left: 5px !important;
                top: 1px !important;
              }
            }
          }
        }
      }
      &_err {
        font-weight: 500;
        font-size: 11px;
        line-height: 130%;
        color: #ca4b15;
        margin: 2px 0 0 4px;
        &_wrap {
          display: flex;
          align-items: center;
          margin-top: 5px;
          margin-left: 31px;
        }
      }
    }
    &_btn {
      margin: 41px auto 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 244px;
      height: 39px;
      min-height: 39px;
      background: #509c5b;
      border-radius: 4px;
      border: 1px solid #509c5b;
      font-weight: 500;
      font-size: 14px;
      line-height: 110%;
      color: #ffffff;
      cursor: pointer;
      transition: 0.2s ease-in-out;
      &:hover {
        background: #ffffff;
        color: #509c5b;
        transition: 0.2s ease-in-out;
      }
    }
  }
  &__form {
    margin-top: 9px;
    &_row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 11px;
      label {
        width: calc(50% - 6px);
        input {
          border-color: #b3b3b3;
        }
      }
      > div {
        width: calc(50% - 6px);
      }
      &_light {
        justify-content: flex-start;
        margin-top: 11px;
        display: flex;
        label {
          width: 170px;
          margin-right: 20px;
          input {
            border-color: #b3b3b3;
          }
        }
      }
    }
  }
  &__modal {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(60, 60, 60, 0.05);
    backdrop-filter: blur(5px);
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    &_inner {
      max-width: 451px;
      width: 100%;
      padding: 40px 31px;
      position: relative;
      background: #fff;
    }
    &_close {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      background: none;
      border: none;
      outline: none;
    }
    &_title {
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      font-size: 18px;
      line-height: 130%;
      text-align: center;
      color: #0f131e;
    }
    &_btn {
      width: 199px;
      height: 39px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 44px auto 0 auto;
      background: #509c5b;
      border-radius: 4px;
      border: 1px solid #509c5b;
      cursor: pointer;
      font-weight: 500;
      font-size: 14px;
      line-height: 110%;
      color: #ffffff;
      text-decoration: none;
      transition: 0.2s ease-in-out;
      &:hover {
        color: #509c5b;
        background: #fff;
        transition: 0.2s ease-in-out;
      }
    }
  }
}

.radio {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &__title {
    font-size: 15px;
    line-height: 130%;
    color: #0f131e;
  }
  &__offset {
    margin-top: 14px;
  }
}

/* Hide the browser's default radio button */
.radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 50%;
}

/* When the radio button is checked, add a blue background */
.radio input:checked ~ .checkmark {
  border: 1px solid #b3b3b3;
  background-color: #fff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio .checkmark:after {
  top: 50%;
  left: 50%;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #509c5a;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 1400px) {
  .main {
    &__info {
      margin-right: 20px;
    }
    &__table {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1170px) {
  .checkout {
    min-height: 870px;
  }
}

@media (max-width: 1072px) {
  .main {
    min-height: 700px;
    &__action {
      &_right {
        margin-top: 16px;
      }
    }
  }
}
@media (max-width: 990px) {
  .main {
    min-height: 800px;
    height: fit-content;
  }
  .checkout {
    height: auto;
    &__wrapper {
      flex-direction: column-reverse;
      gap: 36px;
    }
    &__right {
      width: 100%;
    }
    &__left {
      width: 100%;
    }
  }
}
@media screen and (max-width: 840px){
  .main{
    height: auto;
    padding-bottom: 24px;
  }
}
@media screen and (max-width: 660px) {
  .main {
    padding-bottom: 84px;
    padding-inline: 0;
    &__wrapper {
      padding-inline: 16px;
      flex-direction: column-reverse;
      align-items: center;
      margin-top: 24px;
    }
    h2 {
      margin-left: 16px;
    }
    &__action {
      flex-direction: column;
      align-items: center;
      &_input {
        flex: 1;
      }
      &_text {
        width: 140px;
        width: 44%;
      }
      &_setter {
        width: 100%;
      }
      &_total {
        justify-content: space-between;
        margin-bottom: 24px;
        &_value {
          width: auto;
        }
      }
      &_right {
        width: 100%;
        a {
          width: 100%;
        }
      }
    }
  }
}
@media screen and (max-width: 575px) {
  .main {
    &__wrapper {
      margin-top: 0;
    }
    &__labels {
      margin-bottom: 24px;
      & > div {
        &:last-child {
          // display: none;
          div {
            // display: none;
          }
          .miniimg {
            bottom: 110px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 554px) {
  .main {
    &__action {
      margin-inline: 16px;
      &_wrapper {
        width: 100%;
        max-width: none;
        // padding-inline: 16px;
      }
    }
  }
  .checkout {
    padding-inline: 16px;
    &__left {
      border: none;
      padding-inline: 16px;
    }
    &__right {
      padding-inline: 16px;
    }
  }
}

@media screen and (max-width: 475px) {
  .main {
    h2 {
      margin-left: 10px;
    }
    &__wrapper {
      padding-inline: 10px;
    }
    &__info {
      margin-right: 0;
    }
  }
  .checkout {
    padding-inline: 10px;
    &__right {
      padding-inline: 10px;
      gap: 24px;
      .checkout__wrapper {
        &_title {
          margin-left: 10px;
        }
      }
      & > div > a {
        width: 100%;
      }
    }
    &__form {
      &_row {
        flex-direction: column;
        gap: 10px;
        label,
        div {
          width: 100%;
        }
        &_light {
          gap: 20px;
          & > label {
            width: 100%;
            margin-right: 0;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 425px) {
  .main {
    &__action {
      padding-inline: 10px;
      &_setter {
        width: 100%;
      }
      & > div {
        width: 100%;
        max-width: none;
      }
    }
    &__table {
      &_row {
        justify-content: space-between;
      }
      &_title {
        max-width: 154px;
      }
      &_data {
        flex-direction: column;
      }
      &_weight {
        min-width: none;
        width: auto;
      }
      &_value {
        min-width: none;
        width: auto;
        font-size: 14px;
        color: #3c3c3ccc;
      }
    }
  }
}

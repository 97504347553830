.dropdown{
  position: relative;
  width: 100%;
  &__inner{
    padding: 0 16px;
    cursor: pointer;
    height: 45px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F8F8F8;
    border-bottom: 2px solid rgba(60, 60, 60, 0.8);
  }
  &__label{
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 130%;
    color: rgba(60, 60, 60, 0.8);
    padding-left: 16px;
  }
  &__block{
    width: 100%;
    position: absolute;
    top: 71px;
    padding: 10px 0;
    background: #fff;
    z-index: 3;
    border: 2px solid rgba(60, 60, 60, 0.3);
    border-top: 0;
    transform: translateY(-100px);
    opacity: 0;
    visibility: hidden;
    transition: .2s ease-in-out;
    max-height: 200px;
    overflow: auto;
    z-index: 1001;
    &::-webkit-scrollbar {
      width: 4px;
      background: #F5F5F5;
    }
    &::-webkit-scrollbar-thumb {
      width: 4px;
      background: #B3B3B3;
      border-radius: 2px;
    }
    &_value{
      font-size: 16px;
      line-height: 130%;
      color: #0F131E;      
      padding: 10px;
      cursor: pointer;
      transition: .2s ease-in-out;
      &:hover{
        background: #F8F8F8;
        transition: .2s ease-in-out;
      }
    }
    &_active{
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
      transition: .2s ease-in-out;
    }
  }
  &__error{
    margin-top: 2px;
    padding-left: 16px;
    display: flex;
    align-items: center;
    svg{
      min-width: 12px;
      width: 12px;
    }
    div{
      font-weight: 500;
      font-size: 11px;
      line-height: 130%;
      color: #CA4B15;
      margin: 2px 0 0 4px;
    }
  }
}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');
@import "../../styling/variables.scss";

@mixin notification-body {
  display: flex;
  justify-content: left;
  padding: 10px 16px;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  margin-top: 20px;
  min-height: 52px;
}

@mixin icon-size {
  width: 32px;
  height: 32px;
}

@mixin notification-descr {
  margin-left: 8px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.2px;
}

.notificationRed {
  @include notification-body;
  border-left: 2px #fa8a07 solid;
  background-color: #ffc9b1;

  &__icon {
    @include icon-size;
  }
  &__description {
    @include notification-descr;
    color: #ca4b15;
  }
}

.notificationGreen {
  @include notification-body;
  border-left: 2px $green solid;
  background-color: rgba(87, 180, 87, 0.249);

  &__icon {
    @include icon-size;
  }
  &__description {
    @include notification-descr;
    color: $green;
  }
}

.stats {
  margin-top: 67px;
  padding-block: 2rem;
  padding-bottom: 99px;

  background: #f8f8f8;

  &__wrapper {
    display: flex;
    gap: 67px;
  }
  &__left {
    flex: 60%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      color: #0f131e;
    }
    &_tiles {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      column-gap: 80px;
      row-gap: 16px;
    }
    h2 {
      font-size: 36px;
    }
  }
  &__right {
    flex: 40%;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  &__adds {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 26px;
    margin-top: 2rem;
    margin-left: -10px;
    &_block {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      p {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: -0.03em;
        color: #0f131e;
        text-align: center;
      }
    }
  }
}
.mobile {
  display: none;
}
@media (max-width: 1200px) {
  .stats {
    &__wrapper {
      flex-direction: column;
    }
    &__left {
      flex: 100%;
      &_tiles {
        margin-top: 0;
        column-gap: 0;
      }
    }
    &__right {
      flex-direction: row;
      flex: 100%;
      img {
        object-fit: cover;
        flex: 1;
      }
    }
    &__adds {
      &_block {
        p {
          br {
            display: none;
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .stats {
    &__right {
      img {
        width: 45%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .stats {
    &__left {
      &_tiles {
        column-gap: 20px;
      }
    }
    &__right {
      flex-direction: column;
      flex: 100%;
      img {
        width: 100%;
      }
    }
    &__adds {
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    }
  }
}
@media screen and (max-width: 576px) {
  .stats {
    margin-top: 0;
    padding-block: 40px;
    h2 {
      font-size: 24px;
      text-align: center;
    }
    &__wrapper {
      gap: 26px;
    }
    &__left {
      gap: 16px;
      &_tiles {
        grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
        column-gap: 20px;
      }
    }
    &__adds {
      margin-top: 24px;
      flex-wrap: wrap;
      &_block {
        flex: 45%;
      }
    }
  }
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
}

@media screen and (max-width: 425px) {
  .stats {
    &__left {
      &_tiles {
        grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
      }
    }
  }
}

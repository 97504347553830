@import "../../../../styling/variables.scss";

.mission {
  margin-top: 67px;
  display: flex;
  overflow: hidden;
  &__left {
    flex: 1;
    background-color: #509c5b;
    // min-height: 730px;
    padding: 40px 0 40px 0;
    &_inner {
      display: flex;
      flex-direction: column;
      gap: 24px;
      align-items: flex-start;
      div{
        display: flex;
        gap: 4rem;
      }
      h2 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 42px;
        line-height: 120%;
        letter-spacing: -0.03em;
        color: #ffffff;
      }
      p {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        color: #ffffff;
      }
    }
  }
  &__right {
    overflow: hidden;
    flex: 1;
    background-color: $yellow;
    position: relative;
    padding-top: 74px;
    padding-inline: 20px;
    padding-bottom: 24px;
    height: 680px;
    margin-top: 30px;
    // background-image: url("../../../../img/mission-bg.svg");
    background-repeat: no-repeat;
    background-position: 85% 30%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    &_top {
      img {
        width: 55%;
      }
    }
    &_down {
      display: flex;
      gap: 20px;
      align-items: flex-start;
      img {
        object-fit: cover;
        &:first-child {
          width: 44%;
          height: 320px;
        }
        &:last-child {
          width: 44%;
          height: 250px;
        }
      }
    }
  }
  &__phi{
    position: absolute;
    top: 30px;
    left: 20px;
  }
}

@media (max-width: 1200px) {
  .mission {
    &__left {
      padding-left: 100px;
    }
  }
}
@media (max-width: 991px) {
  .mission {
    &__right {
      &_down {
        flex-direction: column;
        img {
          &:first-child {
            width: 100%;
            height: 240px;
          }
          &:last-child {
            width: 100%;
            height: 240px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .mission {
    flex-direction: column;
    &__left {
      width: 98%;
      padding-inline: 86px;
    }
    &__right {
      display: none;
      width: 98%;
      height: auto;
      margin-left: 2%;
      top: -24px;
      padding: 60px 12px 18px 16px;
      img{
        position: relative;
        width: 70%;
        top: 0;
      }
      &_down {
        img {
          &:first-child {
            height: 340px;
          }
          &:last-child {
            height: 340px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 576px) {
  .mission {
    margin-top: 40px;
    &__left {
      padding-block: 30px 30px;
      padding-inline: 10px;
      width: 100%;
      &_inner {
        max-width: none;
        h2 {
          font-size: 24px;
        }
        div{
          flex-direction: column;
        }
      }
    }
    &__right {
      &_top {
        img {
          height: 210px;
          width: 214px;
        }
      }
      &_down {
        img {
          &:first-child {
            height: 200px;
          }
          &:last-child {
            height: 200px;
          }
        }
      }
    }
  }
}

.footer {
  margin-top: 2rem;
  padding: 30px 0 1px 0;
  border-top: 1px solid #d9d9d9;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &_left {
      ul {
        padding: 0;
        margin: 15px 0 0 0;
        list-style-type: none;
        display: flex;
        align-items: center;
        li {
          margin-right: 23px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      picture{
        img{  
          height: 40px;

        }
      }
    }

    &_right {
      display: flex;
      align-items: center;
      gap: 20px;

      img {
        display: block;
      }
    }
  }

  &__list {
    display: flex;
    align-items: center;
    gap: 52px;
    list-style-type: none;
    margin: 0 0 0 150px;
    padding: 0;

    li {
      // margin-right: 52px;

      a {
        font-weight: 500;
        font-size: 13px;
        line-height: 207.52%;
        color: #0f131e;
        text-decoration: none;
      }
    }
  }

  &__copy {
    margin-top: 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 207.52%;
    color: #b3b3b3;
  }

  &__right {
    display: flex;
    align-items: center;
    ul {
      margin-right: 35px;
    }
  }
}

@media (max-width: 1200px) {
  .footer {
    &__wrapper {
      align-items: flex-end;

      &_left {
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;

        img {
          height: 60px;
        }
        img.footer__logo {
          height: 40px;
        }

        ul {
          li {
            img {
              height: 20px;
            }
          }
        }
      }
    }
    &__list {
      margin-left: 0;
    }
  }
}
@media (max-width: 991px) {
  .footer {
    &__list {
      margin-left: 0;
      gap: 24px;

      li {
        margin-right: 0;
      }
    }

    &__right {
      flex-direction: column-reverse;
      align-items: flex-end;
      ul {
        margin: 12px 0 0 0;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .footer {
    &__wrapper {
      flex-direction: column;
      align-items: center;
      ul {
        margin-top: 24px;
        li {
          img {
            width: 32px;
            height: 32px;
          }
        }
      }
      &_left {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0;
      }
    }
    &__right {
      flex-direction: column;
      align-items: center;
      margin-bottom: 24px;
      ul {
        flex-direction: column;
        margin-bottom: 24px;
      }
    }
    &__copy {
      text-align: center;
    }
  }
}

@media screen and (max-width: 425px) {
  .footer {
    &__list {
      flex-direction: column;
      gap: 8px;
    }
  }
}

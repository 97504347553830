.row {
  padding: 24px 16px;
  border-radius: 2px;
  border: 1px solid #f1f1f1;
  width: 100%;
  display: flex;
  gap: 18px;
  &__id {
    font-family: "Montserrat", sans-serif;
    margin: 18px 0;
    overflow: hidden;
    color: rgba(60, 60, 60, 0.8);
    text-align: center;
    text-overflow: ellipsis;
    font-size: 18px;
    font-weight: 600;
    line-height: 130%;
  }
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img {
      max-width: 240px;
      filter: grayscale(1);
      opacity: 0.5;
      transition: 0.2s ease-in-out;
    }
  }
  &__table {
    width: 70%;
    display: grid;
    grid-template-columns: 1fr;
  }
  &__row {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    padding: 4px 12px;
    gap: 4rem;
    &_label {
      color: rgba(60, 60, 60, 0.8);
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      min-width: 90px;
    }
    &_value {
      color: rgba(60, 60, 60, 0.8);
      text-align: right;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    &:nth-child(even) {
      background: #f8f8f8;
    }
    &_direction {
      // flex-direction: column;
      // align-items: flex-start;
    }
  }
  &__download {
    background: #509c5b;
    border: 2px solid #509c5b;
    margin-top: 14px;
    text-align: center;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    color: #ffffff;
    font-weight: 500;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover {
      background: #ffffff;
      color: #509c5b;
    }
  }
  &:hover {
    .row__icon {
      img {
        opacity: 1;
        filter: grayscale(0);
      }
    }
  }
  &.view {
    flex-direction: column;
    width: auto;
    .row__table {
      width: 100%;
    }
  }
}
.hide {
  display: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #0f131e;
  font-family: "Montserrat", sans-serif;
}

// @media screen and (max-width: 1200px) {
//   .row {
//     width: calc(33% - 15px);
//   }
// }
@media (max-width: 1050px) {
  .row {
    &__table {
      width: 100%;
    }
  }
}
@media screen and (max-width: 991px) {
  .row {
    width: calc(50% - 15px);
  }
}

@media screen and (max-width: 930px) {
  .row {
    flex-direction: column;
    gap: 0;
    &__table {
      width: 100%;
    }
  }
}

@media screen and (max-width: 991px) {
  .row {
    width: 100%;
  }
}
@media screen and (max-width: 685px) {
  .row {
    &.view {
      width: 100%;
    }
  }
}


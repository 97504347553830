.table {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    color: #0f131e;
  }
  &__head {
    display: flex;
    font-size: 16px;
    color: #3c3c3ccc;
    justify-content: left;
    font-weight: 500;
    & > div {
      &:first-child {
        padding-left: 16px;
        // width: 15%;
        // flex-grow: 1;
        width: 33.3%;
        min-width: 115px;
      }

      &:nth-child(2) {
        // width: 20%;
        // flex-grow: 1;
        width: 33.3%;
        min-width: 180px;
      }

      &:nth-child(3) {
        // width: 15%;
        // flex-grow: 1;
        width: 33.3%;
        min-width: 115px;
      }

      &:nth-child(4) {
        width: 305px;
        min-width: 305px;
      }

      // &:last-child {
      //   flex: 3;
      //   text-align: left;
      // }
    }
  }

  &__body {
    display: grid;
    margin-top: 6px;
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(600px,1fr));
  }
}

@media (max-width: 1100px) {
  .table {
    &__head {
      & > div {
        &:nth-child(4) {
          flex: 1;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .table {
    &__head {
      & > div {
        &:nth-child(1) {
          width: 150px;
        }
        &:nth-child(2) {
          width: 150px;
        }
        &:nth-child(4) {
          min-width: 190px;
        }
      }
    }
  }
}

@media screen and (max-width: 970px) {
  .table {
    &__head {
      & > div {
        &:nth-child(2) {
          min-width: 150px;
        }
      }
    }
  }
}

@media screen and (max-width: 925px) {
  .table {
    &__head {
      & > div {
        &:nth-child(3) {
          width: 80px;
          min-width: 80px;
        }
      }
    }
  }
}

@media screen and (max-width: 890px) {
  .table {
    &__head {
      & > div {
        &:nth-child(2) {
          width: 100px;
          min-width: 100px;
        }
      }
    }
  }
}

@media screen and (max-width: 840px) {
  .table {
    &__head {
      & > div {
        &:nth-child(2) {
          width: 20%;
          max-width: 100px;
        }
        &:nth-child(3) {
          width: 15%;
        }
      }
    }
  }
}

// @media screen and (max-width: 768px){
//   .table{
//     &__head{
//       & > div{
//         &:nth-child(2){
//           flex: 1.5;
//         }
//       }
//     }
//   }
// }
// @media screen and (max-width: 660px){
//   .table{
//     &__head{
//       display: none;
//     }
//   }
// }

@media screen and (max-width: 576px) {
  .table {
    width: 100%;
    &__head {
      div {
        &:nth-child(1) {
          display: none;
        }
        &:nth-child(2) {
          width: 50%;
        }
        &:nth-child(3) {
          display: none;
        }
        &:nth-child(4) {
          width: 50%;
          text-align: right;
        }
      }
    }
    &__body{
      width: 100%;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
  }
}

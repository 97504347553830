@import "../../../../styling/variables.scss";

.link {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  text-decoration: none;
  &:hover {
    .link__text {
      color: #0f131e;
    }
    svg path {
      transition: 0.2s ease-in-out;
      stroke: #0f131e;
    }
  }
  svg {
    min-width: 24px;
    width: 24px;
    height: 24px;
    transition: 0.2s ease-in-out;
  }
  &__text {
    margin-left: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: $semiBlack;
    transition: 0.2s ease-in-out;
  }
  &__active {
    .link__text {
      color: #0f131e6c;
      font-weight: 500;
      transition: 0.2s ease-in-out;
    }
  }
  &__activeMob {
    .link__text {
      color: #0f131e;
      font-weight: 500;
      transition: 0.2s ease-in-out;
    }
    border-bottom: 3px solid $green;
  }
}

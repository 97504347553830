.datepicker {
  position: relative;
  display: flex;
  font-weight: 400;
  align-items: center;
  gap: 12px;
  &__title {
    font-weight: 500;
  }
  &__input {
    padding: 12px 48px 12px 16px;
    border: 1px solid #d7d3c1;
    border-radius: 2px;
    background-color: #fff;
    cursor: pointer;
    user-select: none;
    font-size: 16px;
    max-width: 150px;
    &:focus {
      outline: none;
    }

    &_settings {
      padding: 12px 16px 12px 48px;
      border: 1px solid #d7d3c1;
      border-radius: 2px;
      background-color: #fff;
      cursor: pointer;
      user-select: none;
      font-size: 16px;
      max-width: 150px;
      &:focus {
        outline: none;
      }
    }
  }
  
  &__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    right: 16px;
    height: 24px;
    pointer-events: none;
    &_settings {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      pointer-events: none;
      left: 16px;
    }
  }
}

.header {
  position: sticky;
  position: -webkit-sticky;
  padding: 20px 0;
  z-index: 100;
  -webkit-overflow-scrolling: touch;
  top: 0;
  background-color: rgba(255, 255, 255, 0.975);
  &__btns {
    display: flex;
  }
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &_logo {
      display: flex;
      align-items: center;
      a {
        text-decoration: none;
        img {
          height: 40px;
          object-fit: contain;
        }
        &:last-child {
          margin-left: 20px;
          div {
            background: #509c5b;
            border: 1px solid #509c5b;
            color: #ffffff;
            border-radius: 4px;
            font-weight: 500;
            font-size: 14px;
            line-height: 110%;
            padding: 0 32px;
            height: 39px;
            min-height: 39px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.2s ease-in-out;
            &:hover {
              background: #fff;
              color: #509c5b;
              transition: 0.2s ease-in-out;
            }
          }
        }
      }
    }
    & > a {
      display: none;
    }
  }

  &__info {
    display: flex;
    align-items: center;

    a {
      margin: 0 12px;

      &:last-child {
        margin: 0;
      }
    }
  }

  &__right {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  &__user {
    display: flex;
    align-items: center;
    &__list {
      display: flex;
      align-items: center;
      > a {
        margin-bottom: 0;
        margin-right: 10px;
        > div {
          font-weight: 500;
          font-size: 13px;
          color: #000000;
          &:hover {
            color: #000000 !important;
          }
        }
      }
    }
    &__stats {
      margin-left: 10px;
      display: flex;
      align-items: center;
      margin-right: 10px;
    }
  }
  &__stats {
    &_btns {
      a {
        color: #000;
      }
    }
  }

  &__mobileBtn {
    display: none;
    border: none;
    background: transparent;
    cursor: pointer;
    &_visible {
      display: block;
      border: none;
      background: transparent;
    }
  }

  &__mobile {
    position: relative;
    top: -60px;
    width: 100%;
    background: #fff;
    z-index: 10;
    height: 100%;
    &_head {
      height: 84px;
      display: flex;
      padding: 20px 10px;
      align-items: center;
      justify-content: flex-end;
    }

    &_body {
      display: flex;
      flex-direction: column;
      gap: 80px;
      padding-inline: 10px;
      padding-bottom: 40px;
      height: 100vh;
      overflow: auto;

      img {
        object-fit: contain;

        width: 100%;
      }
    }

    &_inner {
      display: flex;
      flex-direction: column;
      gap: 52px;

      ul {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        gap: 24px;
        margin: 0;

        li {
          font-weight: 600;
          color: #0f131e;
          cursor: pointer;
          a {
            color: #0f131e;
            text-decoration: none;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }

    &_langs {
      display: flex;
      justify-content: center;
      gap: 32px;

      & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2px;

        img {
          width: 21px;
          height: 15px;
        }
      }
    }

    &_btns {
      display: flex;
      gap: 16px;
      & > a {
        flex: 1;
        justify-content: center;
      }
    }
  }

  &__menu {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100dvh;
    background: #509c5a;
    padding-block: 16px;
    &_overflow {
      height: 100%;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 0px;
        background: #fff;
      }
      &::-webkit-scrollbar-thumb {
        width: 0px;
        background: #fff;
        border-radius: 2px;
      }
    }
    &_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-inline: 20px;
      margin-bottom: 24px;
      &_logo {
        background: #fff;
        display: flex;
        align-items: center;
      }
      &_wrapper {
        display: flex;
        align-self: center;
        gap: 16px;
        & > a {
          color: #fff;
        }
      }
      &_button {
        display: flex;
        align-items: center;
        outline: none;
        padding: 0;
        border: none;
        cursor: pointer;
        background: none;
        color: #fff;
        svg {
          width: 24px;
          height: 24px;
        }
        a {
          margin-right: 16px;
        }
      }
    }
    &_client {
      &_image {
        width: 40px;
        height: 40px;
        min-width: 40px;
        max-width: 40px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 4px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-inline: 20px;
        &_wrapper {
          display: flex;
          align-items: center;
        }
      }
      &_name {
        color: #ffffff;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
      }
      &_status {
        margin-top: 4px;
        color: #509c5a;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
      }
      &_btn {
        width: 32px;
        min-width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #d7d3c1;
        cursor: pointer;
        margin-left: 11px;
        transition: 0.2s ease-in-out;
        svg {
          transition: 0.2s ease-in-out;
        }
        &_active {
          transition: 0.2s ease-in-out;
          svg {
            transform: rotate(180deg);
            transition: 0.2s ease-in-out;
          }
        }
      }
      &_right {
        display: flex;
        align-items: center;
      }
    }
    &_add {
      height: 0px;
      overflow: hidden;
      transition: 0.2s ease-in-out;
      &_active {
        padding: 11px 0 0 0;
        height: 90px;
        transition: 0.2s ease-in-out;
        &_sale {
          padding: 11px 0 0 0;
          height: 51px;
          transition: 0.2s ease-in-out;
        }
      }
    }
    &_subheader {
      background: rgba(255, 255, 255, 0.08);
      padding-inline: 20px;
      font-family: "Roboto";
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
      color: #fffff8;
      margin-block: 20px 14px;
    }
    &_menu {
      margin-top: 24px;
    }
    &_footer {
      margin-top: 24px;
      &_divider {
        display: block;
        border: none;
        background: #c5d483;
        height: 1.2px;
        margin-inline: 20px;
      }
    }
    &_switcher {
      display: flex;
      align-items: center;
      img {
        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }
}
.mobile {
  display: none;
}
@media screen and (max-width: 1300px) {
  .header__user__stats {
    flex-direction: column;
  }
}
@media screen and (max-width: 1250px) {
  .header__user__list {
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 75px;
    margin-right: 50px;
  }
}
@media screen and (max-width: 840px) {
  .desktop {
    display: none;
  }
}
@media screen and (max-width: 830px) {
  .header__user__list {
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 132px;
    margin-right: 10px;
  }
  .header__user__stats {
    margin-left: 0;
  }
}
@media screen and (max-width: 740px) {
  .header__user__list {
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 132px;
    margin-right: 10px;
  }
  .header__user__stats {
    max-width: 120px;
    > div {
      max-width: 100px;
      width: 100px;
      min-width: 120px;
      img {
        width: 24px;
      }
      // justify-content: flex-end;
      font-size: 12px;
      span {
        font-size: 12px;
      }
    }
    // img {
    //   display: none;
    // }
  }
  .header__btns {
    flex-direction: column;
    a:first-child {
      margin-bottom: 10px;
    }
  }
}

@media screen and (max-width: 660px) {
  .header {
    &__logo {
      width: 180px;
    }
  }
}
@media screen and (max-width: 587px) {
  .header__user__stats {
    margin-right: 0;
  }
}

@media screen and (max-width: 635px) {
  .header__wrapper > a > img {
    width: 150px;
  }
}
@media screen and (max-width: 576px) {
  .header {
    &__mobileBtn {
      display: block;
    }

    &__stats_btns {
      display: none;
    }
    &__user {
      display: none;
    }
    &__btns {
      display: none;
    }
    &__wrapper > a > img {
      // width: auto;
      width: 150px;
    }
    &__info {
      div {
        &:first-child {
          display: none;
        }
      }
    }

    &__wrapper {
      & > a {
        display: flex;
      }
      &_logo {
        a {
          &:last-child {
            margin-left: 12px;
            div {
              font-size: 12px;
              padding: 0 12px;
            }
          }
        }
      }
    }
  }
  .header__user__stats {
    display: none;
    flex-direction: row;
    max-width: 100%;
    div {
      padding-right: 8px;
      &:first-child {
        margin-right: 4px;
      }
    }
  }
  .mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      margin-bottom: 0;
      div {
        color: #000;
      }
    }
  }
}

@media screen and (max-width: 557px) {
  .header__user__stats {
    > div {
      min-width: 100px;
    }
    div {
      font-size: 10px;
      padding-right: 3px;
      span {
        font-size: 10px;
      }
      img {
        width: 16px;
      }
    }
  }
}

@media screen and (max-width: 557px) {
  .header__user__stats {
    flex-direction: column;
    div {
      &:first-child {
        margin-bottom: 4px;
      }
    }
  }
}

@media screen and (max-width: 475px) {
  .header__user__stats {
    > div {
      justify-content: space-between;
      min-width: 110px;
    }
    div {
      padding-right: 4px;
      &:first-child {
        margin-bottom: 4px;
      }
    }
  }
  .header {
    &__mobile {
      top: -60px;
    }
    &__wrapper {
      &_logo {
        a {
          img {
            width: 100px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 441px) {
  .header {
    &__wrapper {
      &_logo {
        a {
          &:last-child {
            div {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  header {
    a {
      &:first-child {
        img {
          width: 100px;
        }
      }
    }
  }
  .header {
    &__mobileBtn {
      img {
        width: 16px;
      }
    }
  }
}

@media screen and (max-width: 363px) {
  .header {
    a {
      &:first-child {
        img {
          width: 100px;
        }
      }
    }
    &__right {
      div {
        font-size: 11px;
        margin-right: 0;
      }
    }
    &__mobileBtn {
      img {
        width: 16px;
      }
    }
  }
  .header__user__stats {
    > div {
      justify-content: flex-end;
      min-width: 80px;
      img {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 330px) {
  .header {
    &__wrapper {
      &_logo {
        a {
          &:last-child {
            div {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
}

.news {
  padding: 15px 20px;
  height: calc(100vh - 100px);
  overflow-y: scroll;
  h2 {
    margin-left: 12px;
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 18px;
  }
  &__empty {
    margin-top: 24px;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
    h2 {
      font-size: 24px;
      text-align: center;
    }
  }
}

@media screen and (max-width: 840px) {
  .news {
    height: auto;
  }
}
@media screen and (max-width: 768px) {
  .news {
    h2 {
      margin-left: 0;
    }
  }
}

body {
  margin: 0;
}

* {
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-block-start: 0;
  margin-block-end: 0;
}

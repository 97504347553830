.news {
  margin-top: 67px;
  margin-bottom: 67px;
  h2 {
    text-align: center;
  }
  &__grid {
    margin-top: 80px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }
}

.card {
  position: relative;
  a {
    position: relative;
    display: block;
  }
  &__main {
    width: 100%;
    height: 250px;
    background-size: 128%;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.5s ease;
    border-radius: 2px;
  }
  &__overlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    
    gap: 8px;
    height: 100%;
    justify-content: flex-end;
   
    background: -moz-linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 100%
    );
    background: -o-linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 100%
    );
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 100%
    );

    h5 {
      font-family: "Montserrat";
      font-size: 18px;
      font-weight: 700;
      line-height: 23.4px;
      color: #fff;
      width: 100%;
    }
    p {
      font-family: "Roboto";
      font-size: 13px;
      font-weight: 400;
      line-height: 15.6px;
      color: #fff;
      transition: all 1s ease;
      max-height: 0px;
      opacity: 0;
      width: 100%;
    }
    span {
      font-family: "Roboto";
      font-size: 12px;
      font-weight: 400;
      line-height: 16.8px;
      color: #fff;
      border-left: 2px solid #509c5b;
      padding-left: 5px;
      width: 100%;
    }
  }
  &:hover {
    .card__main {
      background-size: 160%;
      box-shadow: 0px 15px 30px 0px #00000040;
    }
    .card__overlay {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
      p {
        max-height: 120px;
        opacity: 1;
      }
    }
  }
}

@media (max-width: 1200px) {
  .news {
    &__grid {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media screen and (max-width: 768px) {
  .news {
    &__grid {
      margin-top: 32px;
      grid-template-columns: 1fr;
    }
  }
}

@media screen and (max-width: 576px) {
  .news{
    margin-block: 40px;
  }
  .card {
    &__overlay {
      width: calc(100vw - 20px);
    }
  }
}

.banner {
  margin-top: 40px;
  background: #d7d3c1;
  border-left: 10px solid #509c5a;
  display: flex;
  gap: 8px;
  &__left {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 14px 0px 14px 14px;

    background-image: url("../../img/banner_arrow.svg");
    background-repeat: no-repeat;
    background-position: right center;
    h2 {
      font-family: "Montserrat";
      font-size: 32px;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: -0.02em;
      text-align: left;
      text-transform: uppercase;
      color: #0f131e;
      width: 80%;
    }
    & > div {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;
      max-width: 300px;
      a {
        width: fit-content;
      }
    }
  }
  &__right {
    flex: 1;
    padding: 22px 22px 22px 0px;
    width: fit-content;
    &_inner {
      border-radius: 4px;
      background: #f8f8f5;
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      h3 {
        font-family: "Roboto";
        font-size: 32px;
        font-weight: 500;
        line-height: 38.4px;
        color: #509c5a;
        // margin-bottom: 12px;
      }
      & > div {
        width: 100%;
        display: flex;
        gap: 16px;
        align-items: center;
        // margin-bottom: 16px;
        justify-content: center;
        & > picture {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          height: auto;
          gap: 16px;
          // a {
          //   width: 100%;
          //   justify-content: center;
          // }
        }
      }
    }
  }
  &__grid {
    display: grid;
    grid-template-columns: 1fr;
    // margin-bottom: 14px;
    gap: 12px;
    div {
      display: flex;
      align-items: center;
      gap: 10px;
      span {
        display: block;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        background: #509c5a;
      }
      a {
        text-decoration: none;
        &:hover {
          h4 {
            color: #509c5a;
          }
        }
      }
      h4 {
        font-family: "Montserrat";
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: -0.03em;
        text-align: left;
        color: #0f131e;
      }
    }
  }
}

@media screen and (max-width: 1080px) {
  .banner {
    flex-direction: column;
    &__left {
      background-image: url("../../img/banner_arrow_sm.svg");
      background-size: 20%;
    }
    &__right {
      padding-left: 14px;
      padding-top: 0;
      width: 100%;
    }
  }
}

@media screen and (max-width: 610px) {
  .banner {
    flex-direction: column;
    &__right {
      width: 100%;
      &_inner {
        & > div {
          grid-template-columns: 1fr;
          div {
            h4 {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 556px) {
  .banner {
    &__right {
      &_inner {
        & > div {
          flex-direction: column;
        }
      }
    }
  }
}

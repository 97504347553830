.element{
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
  transition: .2s ease-in-out;
  padding-inline: 16px;
  padding-block: 8px;
  &:hover{
    background: #F8F8F8;
  }
  &__text{
    margin-left: 2px;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    color: #0F131E;
    &_active{
      color: #509c5b;
    }
  }
  &__code {
    margin-left: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #0F131E;
  }
  &__icon{
    min-width: 22px;
  }
  &__check{
    margin-left: auto;
    display: block;
    min-width: 12px;
  }
}
/** @format */

.new {
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 3fr;
  max-width: 1300px;
  &__image {
    flex: 1;
    img {
      object-fit: cover;
      height: 200px;
      width: 100%;
    }
  }
  &__body {
    flex: 3;
    img {
      height: 450px;
      object-fit: cover;
      width: 100%;
    }
    &_date {
      font-size: 12px;
      text-align: right;
      margin-bottom: 4px;
      border-right: 1px solid #509c5b;
      padding-inline: 7px;
    }
    &_inner {
      display: flex;
      flex-direction: column;
      gap: 12px;
      justify-self: flex-end;
    }
    &_title {
      font-size: 20px;
      font-weight: 500;
    }
    &_desc {
      font-size: 16px;
      font-weight: 400;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      white-space: pre-wrap;
      max-height: 40px;
      transition: max-height 1s ease;
    }
    &_link {
      color: #509c5b;
      text-decoration: none;
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.tempNew {
  &__images {
    display: flex;
    gap: 20px;
    img {
      width: 48%;
      height: auto;
    }
  }
  &__floatLeft {
    img {
      height: auto;
      width: 30%;
      float: left;
    }
  }
  &__floatRight {
    img {
      height: auto;
      width: 40%;
      float: right;
      object-fit: contain;
    }
  }
}
@media screen and (max-width: 768px) {
  .new {
    flex-direction: column;
    gap: 8px;
    &__image {
      img {
        height: 300px;
      }
    }
    &__body {
      &_link {
        justify-content: flex-end;
      }
    }
  }

  .tempNew {
    & > img {
      width: 100%;
      object-fit: contain;
      height: auto;
    }
  }
}

@media screen and (max-width: 520px) {
  .new {
    &__image {
      img {
        height: 150px;
      }
    }
  }
}

.main {
  padding: 9px 22px 115px 20px;
  &__sort {
    display: flex;
    align-items: center;
    margin-top: 8px;
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 120%;
      letter-spacing: -0.03em;
      color: #0f131e;
      margin-left: 16px;
      &:first-child {
        margin: 0 16px 0 0;
      }
    }
    &_amount {
      display: flex;
      align-items: center;
      margin-left: 42px;
      font-size: 13px;
      line-height: 20px;
      color: #b3b3b3;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__export {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 32px;
    border: 1px solid #b3b3b3;
    border-radius: 4px;
    cursor: pointer;
    background: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 110%;
    color: #0f131e;
    transition: 0.2s ease-in-out;
    svg {
      margin-left: 10px;
    }
  }
  &__statuses {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &_row {
      margin-bottom: 4px;
      display: flex;
      align-items: center;
      &_status {
        width: 136px;
      }
    }
  }
  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    img {
      max-width: 135px;
    }
    &_title {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 140%;
      color: #0f131e;
      text-align: center;
    }
  }
}

@media screen and (max-width: 576px) {
  .main {
    &__header {
      flex-direction: column;
      align-items: flex-start;
      button {
        margin-top: 10px;
      }
    }
  }
}

@import '../../styling/variables.scss';

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &__text {
    color: #0f131e;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.2px;
  }
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #eee;
  border-radius: 32px;
  border: 1px solid #b3b3b3;
  box-sizing: border-box;
}

.container input:checked ~ .checkmark {
  background-color: #ffffff;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 3px;
  top: 3px;
  width: 8px;
  height: 8px;
  border-radius: 32px;
  background-color: $green;
}

.error {
  margin-top: 2px;
  display: flex;
  align-items: center;
  svg {
    min-width: 12px;
    width: 12px;
  }
  div {
    font-weight: 500;
    font-size: 11px;
    line-height: 130%;
    color: #ca4b15;
    margin: 2px 0 0 4px;
  }
}

.table {
  &__header {
    display: flex;
    align-items: center;
    padding-inline: 8px;
    padding-block: 16px;
    div {
      font-weight: 500;
      font-size: 14px;
      line-height: 110%;
      color: rgba(60, 60, 60, 0.8);
    }
  }
  &__row {
    &_info {
      display: flex;
      align-items: center;
      padding-inline: 8px;
      padding-block: 16px;
      // cursor: pointer;
      div {
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.2px;
        color: #0f131e;
      }
    }
    &:nth-child(odd) {
      div {
        background: #f5f5f5;
      }
    }
    &_extra {
      padding: 16px 16px 16px 42px;
      &_row {
        display: flex;
        align-items: center;
        margin-bottom: 2px;
        &_title {
          min-width: 164px;
          width: 164px;
          font-size: 14px;
          line-height: 120%;
          letter-spacing: -0.2px;
          color: rgba(60, 60, 60, 0.8);
          margin-right: 17px;
        }
        &_name {
          min-width: 100px;
          width: 100px;
          font-size: 14px;
          line-height: 120%;
          letter-spacing: -0.2px;
          color: #0f131e;
          margin-right: 17px;
        }
        &_address {
          font-size: 14px;
          line-height: 120%;
          letter-spacing: -0.2px;
          color: #0f131e;
        }
      }
    }
    &_opened {
      border-bottom: 1px solid #509c5b;
    }
  }
  &__order {
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 0.5;
    svg {
      margin-right: 6px;
      min-width: 12px;
    }
  }
  &__date {
    display: flex;
    align-items: center;
    flex: 1;
  }
  &__name {
    display: flex;
    align-items: center;
    flex: 1;
  }
  &__token {
    display: flex;
    align-items: center;
    flex: 0.5;
  }
  &__status {
    display: flex;
    align-items: center;
    flex: 1;
  }
  &__amount {
    display: flex;
    align-items: center;
    flex: 1;
  }
}

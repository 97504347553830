.main{
  padding: 9px 24px 90px 20px;
  &__info{
    margin-top: 16px;
  }
  &__row{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 20px;
    gap: 20px;
  }
  &__footer{
    font-size: 18px;
    line-height: 130%;
    color: rgba(60, 60, 60, 0.8);
    position: fixed;
    bottom: 0;
    background: #fff;
    position: fixed;
    left: 239px;
    padding: 5px 25px;
    bottom: 0;
    width: calc(100% - 239px);
    div{
      max-width: 900px;
      margin: 0 auto;
      text-align: center;
    }
  }
}

.table{
  margin-top: 28px;
}


@media screen and (max-width: 840px){
  .main{
    height: auto;
    &__footer{
      width: 100%;
      left: 0;
      bottom: 90px;
      div{
        max-width: 900px;
        margin: 0 auto;
        text-align: center;
      }
    }
  }

}

@media screen and (max-width: 560px){
  .main{
    padding-inline: 10px;
    &__row{
      flex-direction: column;
      gap: 4px;
      margin-bottom: 10px;
    }
  }
}
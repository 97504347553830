.startnow {
  margin-top: 67px;
  background: #f8f8f8;
  padding-block: 2rem;
  &__title {
    font-family: "Montserrat";
    font-size: 36px;
    font-weight: 600;
    line-height: 38.4px;
    color: #0f131e;
  }
  &__inner {
    margin-top: 16px;
    display: flex;
    & > div {
      flex: 1;
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      &:first-child {
        background: #d7d3c1;
      }
      &:nth-child(2) {
        background: #e0ded1;
      }
      &:last-child {
        background: #e9e7e0;
      }
      & > div {
        font-family: "Roboto";
        font-size: 42px;
        font-weight: 600;
        line-height: 50.4px;
        letter-spacing: -0.03em;
        color: #0f131e;
        display: flex;
        align-items: center;
        gap: 8px;
      }
      & > p {
        font-family: "Montserrat";
        font-size: 18px;
        font-weight: 600;
        line-height: 23.4px;
        color: #0f131e;
        width: 90%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
 
  .startnow {
    &__title {
      // font-size: 24px;
      font-weight: 600;
    }
    
    &__inner {
      &>div>div{
        font-size:25px;
        &>img{
          height: 28px;
          width: 28px;

        }

      }
      &>div>p{
        font-size:16px;


      }
      & > div {
        gap:0px;
        padding: 15px;
      }
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 576px) {
  .startnow {
    margin-top: 0;
    padding-block: 40px;
    &__title {
      font-size: 24px;
      font-weight: 700;
    }
  }
}

.wrapper {
    display: flex;
  }
  
  .container {
    width: 100%;
    height: 100vh;
    overflow: auto;
    flex: 7;
    &::-webkit-scrollbar {
      width: 4px;
      background: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
      width: 4px;
      background: #b3b3b3;
      border-radius: 2px;
    }
  }
  @media screen and (max-width: 840px) {
    .container {
      height: 100vh;
      padding-bottom: 100px;
    }
  }
  
/** @format */

.sales {
  margin-top: 67px;
  margin-bottom: 67px;

  background: white;
  padding-block: 2rem;
  h2 {
    text-align: center;
    margin: 0 auto;
    margin-bottom: 1.5rem;
  }
  &__inner {
    display: flex;
    margin-top: 24px;
    & + p {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      color: #000000;
      margin-left: 40%;
      padding-left: 24px;
      margin-top: 10px;
      display: flex;
      justify-content: flex-end;
    }
  }
  &__left {
    flex: 40%;
    display: flex;
    &_left {
      background: #509c5b;
      flex: 40%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &_right {
      background: #c5d483;
      flex: 60%;
      display: flex;
      flex-direction: column;
      padding-inline: 16px;
      justify-content: flex-end;
      h5 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 120%;
        letter-spacing: -0.03em;
        color: #509c5b;
      }
      p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 100px;
        line-height: 120%;
        letter-spacing: -0.03em;
        color: #509c5b;
      }
    }
  }
  &__right {
    flex: 60%;
    display: flex;
    flex-direction: column;
    &_top {
      flex: 40%;
      padding: 16px 20px;
      img {
        width: 470px;
      }
    }
    &_bottom {
      flex: 60%;

      display: flex;
      & > div {
        flex: 1;
        padding-block: 24px 20px;
        padding-inline: 36px 20px;
        h5 {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 130%;
          color: #0f131e;
          margin-bottom: 10px;
        }
        & > div {
          display: flex;
          align-items: center;
          span {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 600;
            font-size: 42px;
            line-height: 120%;
            letter-spacing: -0.03em;
            color: #0f131e;
          }
        }
        &:first-child {
          background-color: rgb(215, 211, 193);
        }
        &:nth-child(2) {
          background: rgba(215, 211, 193, 0.7);
        }
        &:last-child {
          background: rgba(215, 211, 193, 0.4);
        }
      }
    }
  }
  &__logoMobile {
    display: none;
    padding: 16px 20px;
    img {
      width: 470px;
    }
  }
}
@media (max-width: 991px) {
  .sales {
    &__inner {
      flex-direction: column;
      margin-top: 0;
      & + p {
        margin-left: 0;
        padding-left: 0;
        justify-content: center;
        text-align: center;
      }
    }
    &__logoMobile {
      display: block;
    }
    &__right {
      &_top {
        display: none;
      }
    }
    &__left {
      &_right {
        padding-top: 16px;
      }
    }
  }
}
.mobile{
  display: none;
}
@media screen and (max-width: 768px) {
  .sales {
    margin-bottom: 40px;

    &__logoMobile {
      display: none;
    }
    &__right {
      &_bottom {
        & > div {
          padding-block: 24px 8px;
          padding-inline: 14px 10px;
        }
      }
    }
  }
}
@media screen and (max-width: 576px) {
  .sales {
    margin-top: 40px;
    margin-bottom: 40px;

    padding-block: 0;
    &__logoMobile {
      padding: 18px 20px;
      img {
        width: 318px;
      }
    }
    &__left {
      &_right {
        h5 {
          font-size: 18px;
        }
        p {
          font-size: 60px;
        }
      }
      &_left {
        img {
          width: 80px;
          height: 80px;
        }
      }
    }
    &__right {
      &_bottom {
        & > div {
          padding-top: 16px;
          h5 {
            font-size: 14px;
          }
          & > div {
            span {
              font-size: 24px;
            }
          }
        }
      }
    }
    &__inner {
      & + p {
        margin-top: 28px;
      }
    }
  }
  .desktop{
    display: none;
  }
  .mobile{
    display: block;
  }
}

@media screen and (max-width: 455px) {
  .sales__right_bottom {
    flex-direction: column;
    & > div{
      display: flex;
      align-items: center;
      img{
        margin-right: 0.5rem;
      }
      h5{
        margin-bottom: 0;
        margin-right: 1.25rem;
      }
      div{
        margin-right: 0.5rem;
      }
    }
  }
}

@media screen and (max-width: 350px) {
  .sales {
    &__logoMobile {
      img {
        width: 100%;
      }
    }
  }
}

@import "../../../../styling/variables.scss";

.row {
  cursor: pointer;
  &__row {
    display: flex;
    padding: 16px;
    & > div {
      flex: 1;
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      color: #0f131e;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      & > p {
        flex: 1;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: #0f131e;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
  &__more {
    padding: 0 16px 16px 16px;
    border-bottom: 1px solid $green;
    display: flex;
    flex-direction: column;
    gap: 4px;
    &_wrapper {
      display: flex;
    }
    &_label {
      // width: 55px;
      font-size: 14px;
      line-height: 120%;
      letter-spacing: -0.2px;
      color: rgba(60, 60, 60, 0.8);
    }
    &_name {
      flex: 1;
      font-size: 14px;
      line-height: 120%;
      letter-spacing: -0.2px;
      color: #0f131e;
      display: flex;
      gap: 10px;
      align-items: center;
      &_id {
        color: $green;
      }
      & > div {
        &:first-child {
          padding-left: 20px;
        }
      }
    }
    &_email {
      flex: 1;
      display: flex;
      gap: 10px;
      align-items: center;
      font-size: 14px;
      line-height: 120%;
      letter-spacing: -0.2px;
      color: #0f131e;
    }
    &_address {
      flex: 1;
      display: flex;
      gap: 10px;
      align-items: center;
      font-size: 14px;
      line-height: 120%;
      letter-spacing: -0.2px;
      color: #0f131e;
    }
    &_place {
      flex: 1;
    }
  }
}
.name {
  display: flex;
  align-items: center;
}

.mobileInfo {
  display: none;
}

@media (max-width: 1024px) {
  .row {
    &__row {
      flex-direction: column;
      gap: 4px;
      padding-block: 14px 12px;

      .name {
        flex-direction: row-reverse;
        justify-content: space-between;
        p {
          text-align: left;
          font-weight: 600;
        }
      }

      .plants {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;

        &::before {
          // content: "Total tokens purchased";
          color: rgba(60, 60, 60, 0.8);
        }
      }
      .perPrice {
        display: flex;
        justify-content: space-between;
        &::before {
          // content: "Price per Token";
          color: rgba(60, 60, 60, 0.8);
        }
      }

      .total {
        display: flex;
        justify-content: space-between;

        &::before {
          // content: "Total Cost";
          color: rgba(60, 60, 60, 0.8);
        }
      }

      .sales {
        display: flex;
        justify-content: space-between;

        &::before {
          // content: "Sales Commision";
          color: rgba(60, 60, 60, 0.8);
        }
      }

      .salesName {
        display: flex;
        justify-content: space-between;

        &::before {
          // content: "Sales Name";
          color: rgba(60, 60, 60, 0.8);
        }
      }

      .salesHead {
        display: flex;
        justify-content: space-between;

        &::before {
          // content: "Head of Sales Commision";
          color: rgba(60, 60, 60, 0.8);
        }
      }

      .salesNameHead {
        display: flex;
        justify-content: space-between;

        &::before {
          // content: "Head of Sales Name";
          color: rgba(60, 60, 60, 0.8);
        }
      }

      .comission {
        display: flex;
        justify-content: space-between;

        &::before {
          // content: "My Comission";
          color: rgba(60, 60, 60, 0.8);
        }
      }

      & > div {
        padding: 0;
        text-align: right;
      }
    }

    &__more {
      padding: 16px;
      &_wrapper {
        flex-direction: column;
        row-gap: 4px;
      }

      &_name {
        flex: 20%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        & > div {
          &:first-child {
            flex: 1;
            padding-left: 0;
          }
        }
      }
      &_email{
        & > div {
          &:first-child {
            flex: 1;
          }
        }
      }
      &_address{
        & > div {
          &:first-child {
            flex: 1;
          }
        }
      }

      &_id {
        flex: 50%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &_place {
        display: none;
      }
    }
  }
  .mobileInfo {
    display: block;
    color: rgba(60, 60, 60, 0.8);
  }
}

@media screen and (max-width: 415px) {
  .row {
    &__more {
      &_place {
        padding-left: 0;
      }
    }
  }
}

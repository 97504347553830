.lang {
  position: relative;
  z-index: 5;
  width: 65px;
  &__info {
    display: flex;
    align-items: center;
    cursor: pointer;
    div {
      margin: 0 2px;
      font-weight: 500;
      font-size: 14px;
      line-height: 130%;
      color: #0f131e;
    }
  }
  &__menu {
    position: absolute;
    padding: 17px 17px 9px 17px;
    width: 96px;
    background: #ffffff;
    border: 1px solid #f5f5f5;
    box-shadow: 0px 13px 11px rgba(0, 0, 0, 0.05);
    border-radius: 2px;
    top: 32px;
    right: 0;
    opacity: 0;
    transform: translateY(-100px);
    visibility: hidden;
    transition: 0.2s ease-in-out;
  }
  &__open {
    .lang__menu {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
      transition: 0.2s ease-in-out;
    }
  }
}

@media screen and (max-width: 414px) {
  .lang {
    &__info {
      img {
        &:first-child {
          width: 16px;
        }
      }
      div {
        font-size: 12px;
      }
    }
  }
}

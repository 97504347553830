@import "../../styling/variables.scss";

.login {
  padding: 62px 0;
  height: calc(100vh - 233px);
  min-height: 650px;
  overflow: hidden;
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__left {
    width: 50%;
  }
  &__right {
    width: 457px;
    height: 392px;
    background: $lightGreen;
    position: relative;
    margin-top: 75px;
    img {
      position: absolute;
      left: 50%;
      top: -67px;
      transform: translateX(-50%);
    }
  }
  &__title {
    margin: 0;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 42px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #0f131e;
  }
  &__info {
    margin-top: 8px;
    display: flex;
    align-items: center;
    div {
      font-size: 20px;
      line-height: 130%;
      letter-spacing: -0.02em;
      color: #0f131e;
      margin-right: 10px;
    }
    a {
      font-weight: 600;
      font-size: 20px;
      line-height: 130%;
      letter-spacing: -0.02em;
      text-decoration-line: underline;
      color: #509c5b;
    }
  }
  &__form {
    margin-top: 32px;
    label {
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &_buttons {
      a {
        margin-right: 12px;
      }
    }
    &_error {
      margin-top: 24px;
    }
    &_error {
      margin-bottom: 12px;
      color: red;
      font-size: 14px;
    }
  }
  &__2fa {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 100vh;
    background: rgba(60, 60, 60, 0.05);
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;
    &_inner {
      max-width: 451px;
      width: 100%;
      background: #ffffff;
      border: 1px solid #f8f8f8;
      box-shadow: 0px 10px 20px -3px rgba(60, 60, 60, 0.6);
      border-radius: 2px;
      position: relative;
      padding: 40px 32px;
      &_title {
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        font-size: 24px;
        line-height: 130%;
        text-align: center;
        color: #0f131e;
        margin-bottom: 16px;
      }
    }
    &_error {
      color: red;
      margin-top: 8px;
      padding-left: 16px;
    }
    &_btn {
      margin: 18px auto 0 auto;
      width: 199px;
      height: 39px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 110%;
      color: #ffffff;
      background: #509c5b;
      border-radius: 4px;
      border: 1px solid #509c5b;
      cursor: pointer;
      transition: 0.2s ease-in-out;
      &:hover {
        background: #ffffff;
        color: #509c5b;
        transition: 0.2s ease-in-out;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .login {
    &__right {
      width: 327px;
      height: 322px;
      img {
        width: 90%;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .login {
    min-height: 850px;
    padding-top: 36px;
    &__wrapper {
      flex-direction: column-reverse;
      align-items: center;
    }
    &__left {
      width: 100%;
      margin-top: 56px;
    }
    &__right {
      width: 327px;
      height: 282px;
      img {
        width: 80%;
      }
    }
  }
}
@media screen and (max-width: 576px) {
  .login {
    &__right {
      width: 248px;
      height: 213px;
      img {
        width: 80%;
        top: -47px;
      }
    }
    &__title {
      font-size: 32px;
    }
    &__info {
      div,
      a {
        font-size: 16px;
      }
    }
    &__form {
      &_buttons {
        display: flex;
        & > a {
          flex: 1;
          justify-content: center;
          padding-inline: 24px;
        }
      }
    }
  }
}

@media screen and (max-width: 370px) {
  .login {
    &__form {
      &_buttons {
        flex-direction: column;
        a:last-child {
          margin-top: 24px;
        }
      }
    }
  }
}

.select {
  position: relative;
  display: inline-block;
  font-size: 14px;
  &__header {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    border: 1px solid #D7D3C1;
    border-radius: 2px;
    cursor: pointer;
    gap: 8px;

    & > span {
      flex: 1;
    }
  }

  &__options {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding: 8px 0;
    background-color: #fff;
    border: 1px solid #D7D3C1;
    border-top: none;
    border-radius: 2px;
    z-index: 1;
  }

  .option {
    padding: 8px 12px;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      background-color: #f7f7f7;
    }

    &.selected {
      background-color: #509c5b;
      color: #fff;
    }
  }
}

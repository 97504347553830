.dropdown{
  position: relative;
  &__block{
    width: 100%;
    position: absolute;
    top: 40px;
    padding: 10px 0;
    background: #fff;
    z-index: 1002;
    border: 2px solid rgba(60, 60, 60, 0.3);
    border-top: 0;
    transform: translateY(-100px);
    opacity: 0;
    visibility: hidden;
    transition: .2s ease-in-out;
    max-height: 200px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 4px;
      background: #F5F5F5;
    }
    &::-webkit-scrollbar-thumb {
      width: 4px;
      background: #B3B3B3;
      border-radius: 2px;
    }
    &_value{
      font-size: 16px;
      line-height: 130%;
      color: #0F131E;      
      padding: 10px;
      cursor: pointer;
      transition: .2s ease-in-out;
      &:hover{
        background: #F8F8F8;
        transition: .2s ease-in-out;
      }
    }
    &_active{
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
      transition: .2s ease-in-out;
    }
  }
  &__inner{
    width: 94px;
    height: 40px;
    cursor: pointer;
    border: 1px solid #D7D3C1;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #0F131E;
    z-index: 1001;
    svg{
      margin-left: 14px;
    }
  }
}
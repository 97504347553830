.table {
    overflow: auto;

    &__header {
        display: flex;
        align-items: center;
        padding: 8px 0;

        div {
            font-weight: 500;
            font-size: 14px;
            line-height: 110%;
            color: rgba(60, 60, 60, 0.8);
        }
    }

    &__row {
        display: flex;
        border-bottom: 1px solid #F5F5F5;

        div {
            font-family: 'Montserrat', sans-serif;
            font-weight: 500;
            font-size: 14px;
            line-height: 140%;
            color: #0F131E;
            padding-top: 16px;
            padding-bottom: 16px;
        }

        &:nth-child(odd) {
            background: #F5F5F5;
        }
    }
  &__date{
    padding-left: 16px;
    width: 20%;
  }
  &__harvest{
    width: 30%;
  }
  &__amount{
    width: 50%;
    padding-right: 16px;
    &__euro{
      width: 30%;
    }
  }
  &__iban{
    width: 50%;
    padding-right: 16px;
  }
}

@media screen and (max-width: 660px) {
    .table {
        &__header {
            padding-inline: 16px;

            & > div {
                &:first-child, &:last-child {
                    display: none;
                }

                &:nth-child(3) {
                    text-align: right;
                }
            }
        }

        &__row {
            flex-wrap: wrap;
            padding-inline: 16px;
            padding-block: 6px;

            & > div {
                padding: 0;

                &:first-child {
                    order: 3;
                    flex: 100%;
                    margin-top: 4px;
                    color: rgba(60, 60, 60, 0.8);
                }

                &:nth-child(2) {
                    order: 1;
                    flex: 45%;
                }

                &:nth-child(3) {
                    order: 2;
                    flex: 45%;
                    text-align: right;
                }

                &:last-child {
                    order: 4;
                    flex: 100%;
                    color: rgba(60, 60, 60, 0.8);
                }
            }
        }
    }
  }


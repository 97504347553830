.dropdown {
  position: relative;
  width: 100%;
  &__inner {
    padding: 0 16px;
    cursor: pointer;
    height: 45px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f8f8f8;
    border-bottom: 2px solid rgba(60, 60, 60, 0.8);
    &_border {
      border-color: #509c5b;
      &_red {
        border-color: #ca4b15;
      }
    }
  }
  &__search {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 45px;
    z-index: 9999;
    display: flex;
    align-items: center;
    input {
      padding: 8px 16px;
      font-size: 16px;
      width: 100%;
      height: 100%;
      border: none;
      background: #f8f8f8;
      &:focus {
        border: none;
        outline: 1px solid #509c5b;
        border-radius: 2px;
      }
    }
  }
  &__label {
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 130%;
    color: rgba(60, 60, 60, 0.8);
    padding-left: 16px;
  }
  &__block {
    width: 100%;
    position: absolute;
    top: 71px;
    padding: 10px 0;
    background: #fff;
    z-index: 3;
    border: 2px solid rgba(60, 60, 60, 0.3);
    border-top: 0;
    transform: translateY(-100px);
    opacity: 0;
    visibility: hidden;
    transition: 0.2s ease-in-out;
    max-height: 200px;
    overflow: auto;
    z-index: 1001;
    &::-webkit-scrollbar {
      width: 4px;
      background: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
      width: 4px;
      background: #b3b3b3;
      border-radius: 2px;
    }
    &_value {
      font-size: 16px;
      line-height: 130%;
      color: #0f131e;
      padding: 10px;
      cursor: pointer;
      transition: 0.2s ease-in-out;
      display: flex;
      align-items: center;
      gap: 8px;
      &:hover {
        background: #f8f8f8;
        transition: 0.2s ease-in-out;
      }
      
    }
    &_notFound {
      font-size: 16px;
      color: #b3b3b3;
      text-align: left;
      padding-inline: 16px;
    }
    &_active {
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
      transition: 0.2s ease-in-out;
    }
    & > input {
      width: 100%;
      position: fixed;
      top: 0;
    }
  }
  &__error {
    margin-top: 2px;
    padding-left: 16px;
    display: flex;
    align-items: center;
    svg {
      min-width: 12px;
      width: 12px;
    }
    div {
      font-weight: 500;
      font-size: 11px;
      line-height: 130%;
      color: #ca4b15;
      margin: 2px 0 0 4px;
    }
  }
}

/** @format */

@import '../../../../styling/variables.scss';

.questions {
  // margin-top: 107px;
  color: $blackGreen;
  // background: #f8f8f8;
  // padding-block: 2rem;
  padding-top: 67px;

  &__desc {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #0f131e;
  }
  &__head {
    display: flex;
    gap: 4px;
    & > div {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
    img {
      width: 30%;
      height: 200px;
      display: none;
    }
  }
  &__inner {
    display: flex;
    gap: 60px;
    margin-top: 40px;
    &_right {
      width: 100%;
    }
  }
  .question {
    margin-top: 24px;
    padding-bottom: 0.75rem;
    border-bottom: 2px solid $green;
    cursor: pointer;
    &_title {
      display: flex;
      align-items: center;
      h4 {
        font-family: 'Montserrat';
        font-size: 20px;
        flex: 9;
      }
      svg {
        flex: 1;
        width: 24px;
      }
    }
    p {
      padding: 16px;
      width: 90%;
      display: none;
    }
    &.opened {
      .question_title {
        svg {
          transform: rotateX(180deg);
        }
      }
      p {
        display: block;
      }
    }
  }
}

@media (max-width: 991px) {
  .questions {
    &__inner {
      flex-direction: column;
      gap: 24px;
      margin-top: 0;
      &_left {
        img {
          width: 40%;
          position: relative;
          left: 50%;
          display: none;
        }
      }
    }
    &__head {
      img {
        display: block;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .questions {
    margin-top: 40px;
    padding-top: 40px;
    &__head {
      img {
        height: 120px;
      }
      p {
        font-family: 'Roboto';
        font-weight: 400;
        line-height: 140%;
      }
    }
    .question {
      &_title {
        h4 {
          font-size: 12px;
        }
      }
      &.opened {
        p {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 130%;
        }
      }
    }
  }
}

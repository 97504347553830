.card {
  position: relative;
  overflow: hidden;
  min-width: 220px;
  width: 220px;
  height: 100px;
  background: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    width: 100%;
    object-fit: contain;
    max-width: 140px;
    max-height: 40px;
  }
}

.overlay {
  position: absolute;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 576px) {
  .card {
    min-width: 120px;
    width: 120px;
    height: 50px;
    & > img {
      max-width: 90px;
      max-height: 30px;
    }
  }
}

.main {
  display: flex;
  flex-direction: column;
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__header {
    margin-top: 20px;
    margin-bottom: 32px;
  }

  &__body {
    display: flex;
    gap: 20px;
    margin-bottom: 83px;
  }
  h2{
    font-family: 'Montserrat';
    font-size: 32px;
  }
}

.header {
  &__image {
    background: url("../../../img/AGBPageTitleImage.webp");
    width: 100%;
    height: 261px;
    background-position: center;
    background-size: cover;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 58%;
      height: 19px;
      right: 0;
      bottom: 0;
      background: #509c5b;
      z-index: 2;
    }
  }
}

.title {
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 42px;
  line-height: 110%;
  letter-spacing: -0.03em;
  color: #0f131e;
  margin-top: 10px;
}

.asideMenu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  &__wrapper {
    width: 368px;
    // max-width: 368px;
    min-width: 368px;
  }

  &__item {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    font-family: "Montserrat";
    font-style: normal;
    box-sizing: border-box;
    padding: 12px 16px;
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;

    color: rgba(60, 60, 60, 0.8);

    a {
      width: 250px;
      cursor: pointer;
    }

    &_active {
      background: #f8f8f8;
      border-left: 3px solid #509c5b;

      font-weight: 700;
      font-size: 18px;
      line-height: 130%;

      color: #0f131e;
    }
  }
}

.paragraphs {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.paragraph {
  display: flex;
  flex-direction: column;
  gap: 16px;
  &__link {
    a {
      padding: 0 4px;
      color: #509c5b;
      text-decoration: none;
    }
  }

  &__title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    color: #000000;

    &_wrapper {
      display: flex;
      align-items: center;
      height: 70px;
      padding: 12px 16px 12px 0;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 25px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;

    color: rgba(60, 60, 60, 0.8);
  }
}

.sticky {
  position: fixed;
  top: 0;
  left: calc((100% - 1140px) / 2);
  width: 368px;
  z-index: 999;
}

@media (max-width: 991px) {
  .asideMenu {
    &__wrapper {
      display: none;
    }
  }
}

@media screen and (max-width: 576px) {
  .main {
    &__header {
      margin-bottom: 16px;
    }
    &__body {
      margin-bottom: 50px;
    }
  }
  .header {
    &__image {
      height: 120px;
      &::after {
        height: 10px;
      }
    }
  }
  .title {
    font-size: 24px;
  }
  .paragraph {
    &__title {
      &_wrapper {
        height: auto;
      }
    }
  }
  .paragraphs {
    gap: 24px;
  }
}

@media screen and (max-width: 400px) {
  .text__over {
    word-break: break-all;
  }
}

.row {
  display: flex;
  &:nth-child(odd) {
    div {
      background: #f5f5f5;
    }
  }
  & > div {
    font-family: "Roboto", sans-serif;
    padding: 16px 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    color: #0f131e;
    flex-grow: 1;
    width: 33.3%;
    &:first-child {
      padding-left: 16px;
      width: 38.3%;
      min-width: 170px;
    }
    &:last-child {
      padding-right: 16px;
      width: 33.3%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      & > div{
        display: flex;
        gap: 10px;
        align-items: center;
        button{
          border: none;
          background: none;
          cursor: pointer;
          svg{
            width: 24px;
            height: 24px;
          }
        }
      }
    }
    a {
      border: none;
      cursor: pointer;
      background: none;
      outline: none;
      text-decoration: none;
    }
  }
}

.loading{
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 560px) {
  .row {
    flex-wrap: wrap;
    padding-inline: 16px;
    & > div {
      width: auto;
      flex: 1;
      gap: 4px;
      &:nth-child(2) {
        order: 1;
        flex: none;
        width: 65%;
        padding-bottom: 0;
        padding-top: 6px;
      }
      &:last-child {
        order: 2;
        flex: none;
        width: 35%;
        justify-content: flex-start;
        align-items: center;
        gap: 4px;
        padding-bottom: 0;
        padding-top: 6px;
        padding-right: 0;
        & > a {
          display: flex;
        }
      }
      &:first-child {
        padding-top: 4px;
        padding-bottom: 6px;
        padding-left: 0;
        order: 3;
        flex: 100%;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        letter-spacing: -0.03em;
        color: rgba(60, 60, 60, 0.8);
      }
    }
  }
}

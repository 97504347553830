.footer {
  padding: 30px 30px 1px 0;
  border-top: 1px solid #d9d9d9;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &_left {
      display: flex;
    }

    &_right {
      display: flex;
      align-items: center;
      gap: 20px;

      img {
        display: block;
      }
    }
  }

  &__list {
    display: flex;
    align-items: center;
    gap: 52px;
    list-style-type: none;
    margin: 0 0 0 30px;
    padding: 0;

    li {
      // margin-right: 52px;

      a {
        font-weight: 500;
        font-size: 13px;
        line-height: 207.52%;
        color: #0f131e;
        text-decoration: none;
      }
    }
  }

  &__copy {
    margin-top: 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 207.52%;
    color: #b3b3b3;
    margin-left: 30px;
  }
  &__social {
    display: flex;
    gap: 1rem;
    align-items: center;
    a {
      display: flex;
      align-items: center;
      color: #0f131e;
      transition: all 300ms ease;
      &:hover {
        color: #509c5b;
      }
    }
  }
}

@media (max-width: 1200px) {
  .footer {
    &__wrapper {
      // align-items: flex-end;

      &_left {
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;

        img {
          height: 60px;
        }
      }
    }
    &__list {
      margin-left: 30px;
    }
  }
}

@media screen and (max-width: 840px) {
  .footer {
    padding: 30px 0px 10px 0;
    &__wrapper {
      flex-direction: column;
      align-items: center;
      gap: 24px;

      &_left {
        img {
          display: none;
        }
      }
    }
    &__list {
      margin-left: 0;
      gap: 24px;

      li {
        margin-right: 0;
      }
    }

    &__copy {
      text-align: center;
      margin-top: 24px;
    }
  }
}
@media screen and (max-width: 425px) {
  .footer {
    &__list {
      flex-direction: column;
      gap: 8px;
    }
  }
}

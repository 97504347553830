.modal {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(3px);
  position: fixed;
  z-index: 100;
  &__close {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    cursor: pointer;
    svg {
      min-width: 20px;
      width: 20px;
      height: 20px;
    }
  }
  &__note {
    margin-top: 12px;
    font-size: 14px;
    color: rgba(60, 60, 60, 0.8);
    display: flex;
    align-items: flex-start;
    div {
      color: red;
      margin-right: 4px;
    }
  }
  &__download {
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 0;
    border-radius: 4px;
    border: 1px solid #509c5a;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 110%;
    background: #509c5a;
    cursor: pointer;
    text-decoration: none;
  }
  &__inner {
    max-width: 955px;
    width: 100%;
    position: relative;
    padding: 54px 30px 40px 30px;
    border-radius: 2px;
    background: #fff;
    box-shadow: 0px 10px 20px -3px rgba(60, 60, 60, 0.6);
  }
  &__title {
    font-family: "Montserrat", sans-serif;
    color: #0f131e;
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    line-height: 130%;
  }
  &__content {
    margin-top: 8px;
    height: calc(100vh - 300px);
    overflow: auto;
    &_bottom {
      margin-top: 2rem;
    }
    &_title {
      margin-top: 16px;
      color: #000;
      font-family: "Montserrat", sans-serif;
      font-size: 18px;
      font-weight: 600;
      line-height: 130%;
      padding: 12px 0;
    }
    &_bold {
      margin-top: 4px;
      color: #000;
      font-family: "Montserrat", sans-serif;
      font-size: 18px;
      font-weight: 600;
      line-height: 130%;
      // padding: 12px 0;
      text-align: center;
    }
    &_text {
      margin-top: 16px;
      color: rgba(60, 60, 60, 0.8);
      font-size: 16px;
      font-weight: 400;
      line-height: 140%;
    }
    &_table {
      border-collapse: collapse;
      margin-top: 1.4rem;
      width: 90%;
      margin: 1.4rem auto 0 auto;
      td {
        border: 1px solid black;
        padding: 8px;
      }
      thead{
        td{
          text-align: center;
          font-weight: bold;
        }
      }
    }
    &::-webkit-scrollbar {
      width: 4px;
      background: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
      width: 4px;
      background: #b3b3b3;
      border-radius: 2px;
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    &_btns {
      margin-top: 24px;
      button {
        &:first-child {
          margin-right: 24px;
        }
      }
    }
    &_btn {
      padding: 12px 32px;
      border-radius: 4px;
      border: 1px solid #509c5a;
      color: #509c5a;
      font-size: 14px;
      font-weight: 500;
      line-height: 110%;
      background: #fff;
      cursor: pointer;
      transition: 0.2s ease-in-out;
      &_accept {
        pointer-events: none;
        opacity: 0.5;
        &_active {
          pointer-events: all;
          opacity: 1;
        }
      }
      &:hover {
        background: #509c5a;
        color: #fff;
      }
    }
    &_scroll {
      cursor: pointer;
      display: flex;
      align-items: center;
      color: #509c5a;
      font-size: 14px;
      font-weight: 500;
      line-height: 110%;
      opacity: 1;
      visibility: visible;
      transition: 0.1s ease-in-out;
      svg {
        margin-left: 4px;
      }
      &_hide {
        visibility: hidden;
        opacity: 0;
      }
    }
    &_row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: -41px;
    }
  }
  &__check {
    display: flex;
    margin-top: 26px;
  }
}

@media screen and (max-width: 991px) {
  .modal {
    &__inner {
      max-width: 90%;
    }
  }
}

@media screen and (max-width: 900px) {
  .modal {
    &__download {
      width: 145px;
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 768px) {
  .modal {
    &__inner {
      padding: 21px 18px 29px 18px;
    }
    &__footer {
      justify-content: flex-start;
      &_row {
        margin-top: 12px;
      }
      &_scroll {
        margin-top: -101px;
      }
    }
    &__content {
      height: calc(100vh - 350px);
    }
  }
}

@media screen and (max-width: 576px) {
  .modal {
    &__footer {
      justify-content: center;
      &_scroll {
        margin-top: 0;
        justify-content: center;
      }
    }
    &__check {
      div {
        font-size: 14px;
      }
    }
    &__title {
      font-size: 21px;
    }
    &__content {
      &_title {
        padding: 8px 0;
        font-size: 16px;
        word-break: break-all;
      }
      &_text {
        font-size: 14px;
        margin-top: 8px;
      }
      &::-webkit-scrollbar {
        width: auto;
        background: initial;
      }
      &::-webkit-scrollbar-thumb {
        width: auto;
        background: initial;
        border-radius: initial;
      }
    }
  }
}

@media screen and (max-width: 460px) {
  .modal {
    &__content {
      height: calc(100vh - 400px);
    }
  }
}

@media screen and (max-width: 425px) {
  .modal {
    &__content {
      &_title {
        padding: 8px 0;
        font-size: 14px;
      }
      &_text {
        font-size: 12px;
        margin-top: 8px;
        word-break: break-all;
      }
    }
  }
}

@media screen and (max-width: 385px) {
  .modal {
    &__title {
      font-size: 19px;
    }
    &__footer {
      &_btns {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        button {
          padding: 8px 32px;
          font-size: 12px;
          width: 120px;
          &:first-child {
            margin-right: 0;
          }
        }
      }
    }
    &__download {
      padding: 8px 0;
    }
  }
}

@media screen and (max-width: 375px) {
  .modal {
    &__download {
      width: 120px;
      font-size: 11px;
    }
    &__footer {
      &_scroll {
        font-size: 12px;
      }
    }
  }
}

.module {
  padding-top: 67px;
  //   height: 591px;
  &__wrapper {
    background: linear-gradient(0deg, #fcffef66, #fcffef66),
      url("../../../../img/hero.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-block: 2rem;
    margin-bottom: 67px;
  }
  &__title {
    margin-bottom: 24px;
    text-align: center;
    // color: #fff;
  }
  &__subtitle {
    background-color: #509c5b;
    margin-bottom: 15px;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    padding: 4px 14px;
    border-radius: 5px;
    width: fit-content;
    text-align: center;
    margin-inline: auto;
    word-break: break-all;
  }
  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    &_info {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    &_modules {
      display: flex;
      flex-direction: column;
      gap: 24px;
      & > div {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 2px solid #a6a2a5;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        img {
          height: 24px;
        }
        div {
          position: absolute;
          bottom: -4px;
          right: -4px;
          height: 24px;
          width: 24px;
          border-radius: 50%;
          border: 2px solid #a6a2a5;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .module {
    &__inner {
      flex-direction: column;
      margin-top: 32px;
      & > picture {
        width: 100%;
        img {
          width: 100%;
          height: auto;
        }
      }
      &_info {
        flex-direction: row;
        width: 100%;
        & > picture{
          flex: 1;
          img{
            width: 100%;
          }
        }
      }
      &_modules {
        flex-direction: row;
        gap: 10px;
      }
    }
  }
}

@media screen and (max-width: 400px){
  .module{
    &__subtitle{
      margin-inline: 10px;
    }
  }
}

.wrapper {
  display: flex;
  height: 100svh;
}

.container {
  width: 100%;
  position: relative;
  height: 100svh;
  flex: 7;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 4px;
    background: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    width: 4px;
    background: #b3b3b3;
    border-radius: 2px;
  }
}
.mainWrapper {
  height: calc(100vh - 200px);
  overflow-y: scroll;
  padding-inline: 20px;
}
@media screen and (max-width: 840px) {
  .mainWrapper {
    height: auto;
  }
}

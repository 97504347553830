.main {
  & > div{
    color: rgba(60, 60, 60, 0.8);
  }
  &__promo {
    position: relative;
    height: auto;
    &::after {
      content: "";
      width: 657px;
      height: 19px;
      background: #d7d3c1;
      position: absolute;
      bottom: 4px;
      right: 0;
    }
    img {
      width: 100%;
    }
  }
  &__title {
    font-family: "Montserrat", sans-serif;
    color: #0f131e;
    font-size: 42px;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: -1.26px;
    margin: 9px 0 32px 0;
  }
  &__text {
    color: rgba(60, 60, 60, 0.8);
    line-height: 140%;
    margin-bottom: 16px;
  }
  &__subtitle {
    color: #0f131e;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 130%;
    margin-bottom: 16px;
  }
  &__banner {
    margin-top: 80px;
  }
}

@media screen and (max-width: 991px) {
  .main {
    &__promo {
      &::after {
        width: 340px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .main {
    &__promo {
      &::after {
        width: 205px;
        height: 12px;
        background: #509c5a;
      }
    }
    &__banner {
      margin-top: 50px;
    }
  }
}

@media screen and (max-width: 550px) {
  .main {
    &__title {
      font-size: 32px;
    }
  }
}

@media screen and (max-width: 350px) {
  .main {
    &__title {
      font-size: 28px;
    }
  }
}

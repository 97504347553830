@import "../../../../styling/variables.scss";
.options {
  // color: $blackGreen;
  background: #f8f8f8;

  // margin-top: 67px;
  &__wrapper {
    display: flex;
    margin-bottom: 40px;
  }
  &__title {
    font-size: 36px;
  }
  &__left {
    flex: 35%;

    position: relative;
      img {
        width: 340px;
        height: 420px;
        object-fit: cover;
        position: absolute;
        left: 0;
      }
  }
  &__right {
    flex: 65%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    // max-width: 680px;
    padding-top: 32px;
  }
  &__pretitle {
    font-family: "Montserrat";
    font-weight: 500;
  }
  &__desc {
    font-weight: 700;
    font-size: 18px;
    font-family: "Montserrat";
  }
  &__under {
    display: flex;
    &_left {
      flex: 35%;
      // background-color: #c5d483;
    }
    &_right {
      flex: 65%;
      background-color: #509c5b;
      padding-block: 24px;
      padding-inline: 50px;
      h4 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: -0.03em;
        color: #ffffff;
        margin-bottom: 12px;
      }
      &__list {
        display: flex;
        flex-direction: column;
        gap: 8px;
        & > div {
          display: flex;
          gap: 8px;
          p {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            color: #ffffff;
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .options {
    &__left {
      flex: 45%;
      img {
        left: 30px;
        top: 30px;
      }
      &::after {
        left: 88px;
        bottom: -267px;
      }
    }
    &__right {
      flex: 55%;
    }
  }
}

@media (max-width: 991px) {
  .options {
    &__wrapper {
      flex-direction: column;
    }
    &__left {
      display: flex;
      justify-content: center;
      img {
        position: static;
      }
      &::after {
        bottom: 0;
        left: 248px;
      }
    }
    &__under {
      flex-direction: column;
      &__left {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .options {
    &__left {
      img {
        height: auto;
        width: 100%;
        object-fit: contain;
      }
      &::before {
        content: "";
        width: 9px;
        height: 70%;
        background-color: #509c5b;
        position: absolute;
        bottom: 0;
        left: 0;
      }
      &::after {
        display: none;
      }
    }
    &__under {
      &_right {
        padding: 24px 20px;
      }
    }
  }
}
@media screen and (max-width: 576px) {
  .options {
    display: none;
    margin-top: 24px;
    h2 {
      font-size: 24px;
    }
    &__wrapper {
      margin-bottom: 16px;
    }
    &__left {
      img {
        height: 182px;
        object-fit: cover;
      }
    }
    &__right {
      gap: 16px;
      padding-top: 16px;
      p {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        color: #0f131e;
      }
    }
  }
}

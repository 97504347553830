.calendar {
  background: #f8f8f8;
  border-bottom: 2px solid #509c5b;
  cursor: pointer;
  position: relative;
  z-index: 3;
  input {
    border: none;
    padding: none;
    outline: none;
    padding: 12px 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #0f131e;
    background: #f8f8f8;
    z-index: 3;
    position: relative;
  }
  &__arrow {
    position: absolute;
    top: calc(50% + 3px);
    right: 16px;
    transform: translateY(-50%);
    z-index: 3;
  }
  &__first {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    display: none;
    z-index: 0;
    &_active {
      display: block;
      z-index: 10;
    }
  }
}

.main {
  padding: 9px 22px 115px 20px;
  position: relative;
  &__subheader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding-left: 26px;
    margin: 40px 0 16px 0;
    // &:first-child {
    //   margin-top: 0;
    // }
  }
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    column-gap: 20px;
    row-gap: 20px;
    &_info {
      display: flex;
      gap: 16px;
      width: 100%;
      &_block {
        display: flex;
        flex-direction: column;
        gap: 20px;
        flex: 1;
        & > * {
          flex: 1;
          &:last-child {
            background: #e1e1e1;
          }
        }
      }
    }
  }
  &__table {
    overflow: auto;
    &_wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 24px;
      margin-bottom: 14px;
    }
    &_values {
      > div:nth-child(odd) div {
        background: #f5f5f5;
      }
    }
    &_section {
      margin-top: 51px;
    }

    &_header {
      padding: 8px 16px;
      display: flex;
      & > div {
        flex: 1;
        font-weight: 500;
        font-size: 14px;
        line-height: 110%;
        color: rgba(60, 60, 60, 0.8);
      }
    }
  }
  &__link {
    &_copy {
      display: none;
      border: 1px solid #509c5b;
    }
  }
  & > a {
    display: none;
  }
  &__startnow {
    margin-top: 28px;
    // margin-bottom: 56px;

    display: flex;
    & > div {
      flex: 1;
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      &:first-child {
        background: #d7d3c1;
      }
      &:nth-child(2) {
        background: #e0ded1;
      }
      &:last-child {
        background: #e9e7e0;
      }
      & > div {
        font-family: "Roboto";
        font-size: 42px;
        font-weight: 600;
        line-height: 50.4px;
        letter-spacing: -0.03em;
        color: #0f131e;
        display: flex;
        align-items: center;
        gap: 8px;
      }
      & > p {
        font-family: "Montserrat";
        font-size: 18px;
        font-weight: 600;
        line-height: 23.4px;
        color: #0f131e;
        width: 90%;
      }
    }
    &_title {
      margin-top: 50px;
    }
  }
}

.chart {
  height: 300px;
  overflow: hidden;
  &__wrapper {
    width: calc(100% - 393px);
    flex: 2;
  }
  &__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 130%;
    color: #0f131e;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}
.table {
  display: flex;
  flex-direction: column;
  &__head {
    padding: 8px 16px;
    display: flex;

    & > div {
      flex: 1;
      font-weight: 500;
      font-size: 14px;
      line-height: 110%;
      color: rgba(60, 60, 60, 0.8);
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        // flex: 0.5;
      }
      &:nth-child(3) {
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    &_row {
      display: flex;
      padding: 16px;
      gap: 0.5rem;

      &:nth-child(odd) {
        background: #f5f5f5;
      }
      div {
        flex: 1;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: #0f131e;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          text-align: center;
        }
        &:nth-child(3) {
          flex: 0.5;
        }
      }
    }
  }
}
.mobileInfo {
  display: none;
  color: rgba(60, 60, 60, 0.8) !important;
  font-family: "Roboto" !important;
}
@media (max-width: 1024px) {
  .main {
    &__table {
      &_header {
        display: none;
      }
      &_values {
        > div:nth-child(odd) div {
          background: #fff;
        }
        > div:nth-child(even) div {
          background: #f5f5f5;
        }
      }
    }
  }
  .mobileInfo {
    display: block;
  }
  .table {
    &__head {
      display: none;
    }
    &__body {
      &_row {
        flex-direction: column;
        & > div {
          display: flex;
          justify-content: space-between;
          &:nth-child(2) {
            margin-top: 16px;
          }
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            text-align: left;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 840px) {
  .main {
    &__subheader {
      padding-left: 0;
    }
    &__link {
      &_copy {
        padding: 1rem;
        display: block;
        border-radius: 4px;
        margin-block: 0.5rem;
        &_wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 500;
        }
        &_title {
          color: #509c5b;
        }
        &_link {
          color: #509c5b;
          max-width: 100%;
          width: 100%;
          word-break: break-all;
        }
        &_btn {
          outline: none;
          border: none;
          background: none;
          cursor: pointer;
          position: relative;
        }
        &_status {
          position: absolute;
          top: 2px;
          white-space: nowrap;
          right: 41px;
          color: #509c5b;
          pointer-events: none;
          opacity: 0;
          font-size: 16px;
          z-index: -1;
          transition: 0.2s ease-in-out;
          &_active {
            opacity: 1;
            transition: 0.2s ease-in-out;
          }
        }
      }
    }
    & > a {
      display: flex;
    }
  }
}
@media screen and (max-width: 768px) {
  .main {
    &__dummy {
      display: none;
    }
    &__wrapper {
      &_info {
        flex-direction: column;
      }
    }
  }
  .chart {
    &__wrapper {
      width: 100%;
    }
  }
}
@media screen and (max-width: 560px) {
  .main {
    padding-inline: 10px;
    &__subheader {
      padding-left: 0;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      & > div {
        padding-left: 12px;
      }
    }
  }
}
@media screen and (max-width: 325px) {
  .main {
    &__wrapper {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
  }
  .chart {
    &__header {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  position: sticky;
  z-index: 100;
  top: 0;
  background-color: rgba(255, 255, 255, 0.975);
  justify-content: flex-end;
  padding: 25px 32px 20px 20px;
  &__generate {
    outline: none;
    border: 1px solid #509c5b;
    background: #509c5b;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    padding: 10px 14px;
    color: #ffffff;
    border-radius: 4px;
    &:hover {
      background: #ffffff;
      color: #509c5b;
    }
  }
  &__wrapper {
    display: flex;
  }
  &__stats {
    display: flex;
    justify-content: space-between;
    &_info {
      display: flex;
      gap: 4px;
      align-items: center;
      display: none;
      &__photo {
        width: 40px;
        height: 40px;
        min-width: 40px;
        min-height: 40px;
        border-radius: 100%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &__name {
        display: flex;
        align-items: center;
        & > p {
          color: rgba(60, 60, 60, 0.8);
          font-size: 14px;
          font-weight: 500;
          line-height: 100%;
          margin-right: 4px;
        }
      }
    }
    &_btns {
      align-items: center;
      gap: 10px;
      a {
        display: none;
        align-items: center;
      }
    }
  }
  &__actions {
    display: flex;
    justify-content: space-between;
    margin-left: 30px;
    &_langLogout {
      display: flex;
      align-items: center;
      gap: 12px;
    }
    &_logo {
      display: none;
      img {
        width: 163px;
      }
    }
  }
  &__mobile {
    display: none;
  }
  &__menu {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100vh;
    background: #fff;
    padding: 0 10px;
    &_overflow {
      height: 100%;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 0px;
        background: #fff;
      }
      &::-webkit-scrollbar-thumb {
        width: 0px;
        background: #fff;
        border-radius: 2px;
      }
    }
    &_header {
      padding: 14px 0 17px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &_logo {
        width: 163px;
      }
      &_wrapper {
        display: flex;
        align-items: center;
        svg {
          width: 24px;
          height: 24px;
        }
        a {
          margin-right: 16px;
        }
        button {
          outline: none;
          padding: 0;
          border: none;
          background: #fff;
          cursor: pointer;
        }
      }
    }
    &_client {
      padding-bottom: 10px;
      border-bottom: 2px solid #509c5a;
      &_image {
        width: 40px;
        height: 40px;
        min-width: 40px;
        max-width: 40px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 4px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &_wrapper {
          display: flex;
          align-items: center;
        }
      }
      &_name {
        color: rgba(60, 60, 60, 0.8);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
      }
      &_status {
        margin-top: 4px;
        color: #509c5a;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
      }
      &_btn {
        width: 32px;
        min-width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #d7d3c1;
        cursor: pointer;
        margin-left: 11px;
        transition: 0.2s ease-in-out;
        svg {
          transition: 0.2s ease-in-out;
        }
        &_active {
          background: #509c5a;
          transition: 0.2s ease-in-out;
          svg {
            transform: rotate(180deg);
            transition: 0.2s ease-in-out;
            path {
              stroke: #fff;
            }
          }
        }
      }
      &_right {
        display: flex;
        align-items: center;
      }
    }
    &_add {
      height: 0px;
      overflow: hidden;
      transition: 0.2s ease-in-out;
      &_active {
        padding: 11px 0 0 0;
        height: 102px;
        transition: 0.2s ease-in-out;
        &_sale {
          padding: 11px 0 0 0;
          height: 51px;
          transition: 0.2s ease-in-out;
        }
      }
    }
    &_link {
      padding: 0px 20px;
      text-decoration: none;
      display: flex;
      align-items: center;
      height: 40px;
      div {
        color: rgba(60, 60, 60, 0.8);
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        padding-left: 12px;
      }
      &_finances {
        margin-left: auto;
      }
      &_active {
        svg {
          path {
            stroke: #0f131e;
          }
        }
        div {
          color: #0f131e;
        }
      }
    }
    &_menu {
      padding: 16px 0;
      border-bottom: 2px solid #509c5a;
    }
    &_footer {
      padding: 16px 0;
    }
    &_switcher {
      display: flex;
      align-items: center;
      img {
        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }
  &__mobileBtn {
    display: none;
    border: none;
    background: transparent;
    cursor: pointer;
    &_visible {
      display: block;
      border: none;
      background: transparent;
    }
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(3px);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  &__inner {
    max-width: 500px;
    width: 500px;
    border-radius: 8px;
    position: relative;
    padding: 30px 30px 50px 30px;
    background: #ffffff;
    &_close {
      cursor: pointer;
      border: none;
      outline: none;
      background: none;
      position: absolute;
      top: 15px;
      right: 15px;
    }
    &_wrapper {
      position: relative;
      display: flex;
      align-items: center;
      margin-top: 16px;
      border-bottom: 2px solid #509c5a;
      background: #f8f8f8;
    }
    &_title {
      font-family: "Montserrat", sans-serif;
      color: #0f131e;
      text-align: center;
      font-size: 24px;
      font-weight: 700;
      line-height: 130%;
    }
    &_code {
      padding: 6px 10px;
      color: #0f131e;
      font-size: 16px;
      line-height: 130%;
      text-align: center;
      border-radius: 8px;
      width: 100%;
      word-break: break-all;
    }
  }
  &__copy {
    cursor: pointer;
    border: none;
    outline: none;
    min-width: 33px;
    height: 31px;
    background: transparent;
    position: relative;
    &_state {
      text-align: center;
      pointer-events: none;
      opacity: 0;
      z-index: -1;
      transition: 0.2s ease-in-out;
      &_active {
        opacity: 1;
        z-index: 1;
        color: #509c5b;
        transition: 0.2s ease-in-out;
      }
    }
    img {
      width: 21px;
      height: 21px;
    }
  }
  &__tip {
    padding: 20px 16px;
    border-left: 2px solid #fa8a07;
    background: #f8e9b6;
    display: flex;
    align-items: center;
    margin-top: 8px;
    svg {
      min-width: 21px;
      height: 21px;
    }
    div {
      margin-left: 12px;
      color: #fa8a07;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.2px;
    }
  }
  &__btn {
    border-radius: 4px;
    background: #509c5a;
    max-width: 386px;
    margin: 16px auto 0 auto;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 110%;
    padding: 12px 12px;
    text-align: center;
    border: 1px solid #509c5a;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover {
      color: #509c5a;
      background: #fff;
      transition: 0.2s ease-in-out;
    }
  }
}

@media (max-width: 1200px) {
  .header {
    flex-direction: column-reverse;
    align-items: flex-end;
  }
}
@media (max-width: 991px) {
  .header {
    &__actions {
      margin-left: 84px;
    }
  }
}

@media screen and (max-width: 840px) {
  .header {
    background-color: #d7d3c1;
    border-bottom: 1px solid #509c5b;
    &__wrapper {
      flex-direction: column-reverse;
      align-items: normal;
      gap: 32px;
      width: 100%;
    }
    &__actions {
      margin-left: 0;
      &_logo {
        display: block;
      }
    }
    &__role {
      display: flex;
      align-items: center;
      color: rgba(60, 60, 60, 0.8);
      font-size: 12px;
      line-height: 100%;
      margin-top: 2px;
      span {
        color: #0f131e;
        font-size: 12px;
        font-weight: 500;
        line-height: 100%;
        padding-left: 4px;
      }
    }
    &__stats {
      display: none;
      &_info {
        display: flex;
      }
      &_btns {
        display: flex;
        a {
          display: flex;
        }
        & > div {
          border: 1px solid #509c5b;
        }
      }
    }
    &__mobile {
      display: block;
    }
    &__mobileBtn {
      padding: 0;
      display: block;
    }
  }
}

@media screen and (max-width: 575px) {
  .header {
    padding-inline: 10px;
    padding-block: 14px;
    margin-bottom: 16px;
    &__stats {
      display: none;
      &_btns {
        & > div {
          min-width: 80px;
          font-size: 14px;
          width: 160px;
          img {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .modal {
    &__inner {
      max-width: 90%;
      width: 100%;
    }
    &__tip {
      line-height: 120%;
      padding: 12px 9px;
      div {
        font-size: 14px;
      }
    }
    &__inner {
      &_code {
        width: calc(100% - 33px);
      }
    }
    &__copy {
      min-width: 33px;
    }
  }
}

@media screen and (max-width: 414px) {
  .header {
    &__actions {
      &_logo {
        display: flex;
        align-items: center;
      }
      &_langLogout {
        gap: 8px;
        > div {
          &:first-child {
            width: auto;
          }
        }
        > button {
          img {
            width: 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .modal {
    &__inner {
      padding: 20px 15px 30px 15px;
      &_code {
        word-break: break-all;
      }
    }
  }
}

@import "../../../../styling/variables.scss";

.webcam {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;


  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
  }
  &__label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 10px;
    width: 100%;
    border-radius: 2px;
    background: rgba(80, 156, 90, 0.2);
    border-bottom: 2px solid #c5d483;
    margin-top: 1rem;
    div {
      display: flex;
      flex-direction: column;
      gap: 2px;
      h5 {
        color: #0f131e;
        font-family: "Montserrat";
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
      }
      span {
        color: rgba(60, 60, 60, 0.8);
        font-family: "Montserrat";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        letter-spacing: -0.42px;
      }
    }
  }
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: left;
    margin-bottom: 2rem;
  }
  &__mainCamera {
    width: 450px;
    min-width: 450px;
    margin-right: 20px;
    position: relative;
    img {
      width: 100%;
    }
    &__offline {
      position: absolute;
      text-align: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      color: #fff;
      font-size: 12px;
      & + img {
        filter: blur(2px);
      }
    }
    &_info {
      display: flex;
      width: 100%;
      margin-top: 10px;
      justify-content: space-between;
      align-items: center;
      &_wrapper {
        display: flex;
        align-items: center;
        div {
          font-size: 13px;
          line-height: 140%;
          color: #0f131e;
        }
        &:last-child {
          div:first-child {
            margin-right: 7px;
          }
        }
      }
      &_separator {
        margin: 0 8px;
        height: 16px;
        border: 1px solid #b3b3b3;
      }
    }
  }
  &__cameras {
    padding: 16px;
    background: #f8f8f8;
    display: flex;
    flex-wrap: wrap;
    column-gap: 17px;
    row-gap: 8px;
    &_item {
      width: calc(33.3% - 12px);
      img:first-child {
        width: 100%;
      }
      &__image {
        position: relative;
      }
      &_info {
        display: flex;
        align-items: center;
        div {
          font-size: 12px;
          line-height: 140%;
          color: #0f131e;
          &:first-child {
            padding-right: 8px;
            margin-right: 8px;
            border-right: 1px solid #cccccc;
          }
        }
      }
      &_online {
        position: absolute;
        left: 13px;
        bottom: 30px;
        width: 57px;
      }
      &_play {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -100%);
      }
      &_offline {
        position: absolute;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        color: #fff;
        font-size: 12px;
        & + img {
          width: 100%;
          filter: blur(2px);
        }
      }
    }
  }
  &__wrapper {
    &_blocks {
      gap: 30px;
      display: grid;
      transition: 0.2s ease-in-out;
      grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    }
    &_blocksMob {
      width: 100%;

      gap: 10px;
    }
    &::-webkit-scrollbar {
      width: 2px;
      background: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
      width: 4px;
      background: #b3b3b3;
      border-radius: 2px;
    }
  }
}

.plants {
  display: flex;
  gap: 48px;
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 50px;
  & > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
    h2 {
      font-family: "Roboto";
      font-size: 40px;
      font-weight: 500;
      line-height: 38.4px;
      // text-align: center;
      color: #509c5a;
    }
    & > div {
      display: flex;
      gap: 10px;
      // flex-direction: column;
      // align-items: center;
      ul {
        flex: 1;
        display: flex;
        flex-direction: column;
        list-style-type: none;
        margin: 0;
        padding: 0;
        // width: 100%;
        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 8px 16px;
          font-family: "Roboto";
          font-size: 14px;
          font-weight: 500;
          line-height: 15.6px;
          text-align: left;
          color: #0f131e;
          gap: 10px;
          & > * {
            flex: 1;
            &:last-child{
              text-align: right;
            }
          }
          &:nth-child(even) {
            background: #f8f8f8;
          }
        }
      }
    }
  }
  &__left {
    // flex: 0.75;
  }
  &__right {
    // flex: 1.25;
    img {
      opacity: 0.5;
    }
    &_table {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
    &_row {
      display: flex;
      gap: 10px;
      padding: 8px 16px;
      font-size: 14px;
      & > p{
        flex: 1;
        font-weight: 500;
        &:not(:first-child){
          text-align: center;
          display: flex;
          justify-content: flex-end;
        }
      }
      &:nth-child(even) {
        background: #f8f8f8;
      }
    }
  }
}
.construction {
  max-width: 136px;
  display: flex;
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 600;
  line-height: 16.8px;
  color: #509c5a;
  border-radius: 24px;
  background: #90dd9a;
  padding: 2px 16px;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.packages {
  &__wrapper {
    display: flex;
    margin-top: 26px;
    flex-direction: column;
    height: 350px;
    padding: 0 5px 40px 0;
    overflow: auto;
    position: relative;
    transition: 0.2s ease-in-out;
    &_blocksMob {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
      gap: 10px;
    }
    &::-webkit-scrollbar {
      width: 2px;
      background: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
      width: 4px;
      background: #b3b3b3;
      border-radius: 2px;
    }
  }
}

.main{
  &__statuses {
    margin: 25px 0 0 20px;
    display: flex;
    flex-wrap: wrap;
    max-width: 1500px;
    width: 100%;
  }
  &__status {
    &_row {
      display: flex;
      margin-bottom: 10px;

      width: 504px;
      &_text {
        font-weight: 400;
        font-size: 16px;
        line-height: 110%;
        color: #0f131e;
        margin-left: 20px;
      }
      div:first-child {
        width: 136px;
        min-width: 136px;
        div {
          display: inline;
        }
      }
    }
  }
}
@media screen and (max-width: 1363px) {
  .webcam {
    &__cameras {
      &_item {
        &_online {
          bottom: 43px;
        }
        &_play {
          transform: translate(-50%, -150%);
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .webcam {
    &__cameras {
      &_item {
        width: calc(50% - 20px);
        &_online {
          bottom: 30px;
        }
        &_play {
          transform: translate(-50%, -100%);
        }
      }
    }
  }
}

@media screen and (max-width: 1187px) {
  .webcam {
    &__cameras {
      &_item {
        &_online {
          bottom: 43px;
        }
        &_play {
          transform: translate(-50%, -150%);
        }
      }
    }
  }
}

@media screen and (max-width: 1150px) {
  .webcam {
    &__mainCamera {
      width: 400px;
      min-width: 400px;
    }
  }
  .main {
    &__statuses {
      justify-content: space-between;
    }
    &__status {
      &_row {
        width: calc(50% - 10px);
        &_text {
          font-size: 14px;
        }
        div:first-child {
          width: 120px;
          min-width: 120px;
        }
        &:nth-child(even) {
          margin-left: 0;
        }
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  .plants {
    margin-top: 32px;
    flex-direction: column;
    gap: 24px;
  }
}
@media screen and (max-width: 1050px) {
  .webcam {
    &__mainCamera {
      // width: 350px;
      // min-width: 350px;
      width: 70%;
    }
    &__wrapper {
      flex-direction: column;
      gap: 24px;
    }
    &__cameras {
      &_item {
        width: auto;
      }
    }
  }
}

@media screen and (max-width: 875px) {
  .webcam {
    &__tabs {
      // display: none;
    }
    &__tabsMob {
      display: flex;
      width: 100%;
    }
  }
}
@media screen and (max-width: 768px) {
  .webcam {
    &__cameras {
      max-width: 100%;
      overflow-x: scroll;
      height: 187px;
      position: relative;
      scrollbar-color: #d5ac68 #f1db9d;
      scrollbar-width: thin;
      -ms-overflow-style: none;
      flex-wrap: nowrap;
      &_item {
        min-width: 205px;
      }
    }
  }
  .plants {
    & > div {
      h2 {
        font-size: 32px;
      }
      & > div {
        ul {
          li {
            font-size: 16px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 650px) {
  .webcam {
    &__header {
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
    }
  }
  .main {
    &__statuses {
      flex-direction: column;
      margin-left: 10px;
    }
    &__status {
      &_row {
        width: 100%;
        margin-bottom: 2px;
        align-items: flex-start;
        &_text {
          font-size: 12px;
          color: rgba(60, 60, 60, 0.8);
        }
      }
    }
  }
}
@media screen and (max-width: 575px) {
  .webcam {
    &__wrapper {
      gap: 10px;
    }
    &__header {
      padding-inline: 10px;
    }
    &__mainCamera {
      padding-inline: 10px;
      width: 100%;
      margin-right: 0;
    }
    &__tabs {
      padding-inline: 10px;
      margin-top: 14px;
    }
  }
  .packages {
    &__empty {
      align-items: center;
      &_right {
        width: 100%;
        h5 {
          text-align: center;
        }
        &_inner {
          flex-direction: column;
          text-align: center;
          gap: 1rem;
          width: 100%;
        }
      }
    }
  }
  .plants {
    & > div {
      h2 {
        text-align: center;
      }
      & > div {
        flex-direction: column;
        align-items: center;
        ul {
          width: 100%;
          li {
            font-size: 16px;
          }
        }
      }
    }
    &__right{
      &_table{
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 425px) {
  .webcam {
    &__mainCamera {
      min-width: 0;
      &_info {
        flex-wrap: wrap;
      }
    }
  }
}

.wrapper {
  display: flex;
}

.container {
  width: 100%;
  position: relative;
  height: 100vh;
  flex: 7;
  overflow: auto;
  background: url("../../../../../../img/order.webp") no-repeat 100%;
  background-position: 90% 25%;
  background-size: 35%;
  &::-webkit-scrollbar {
    width: 4px;
    background: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    width: 4px;
    background: #b3b3b3;
    border-radius: 2px;
  }
}
.order {
  padding-inline: 20px;
  max-width: 765px;
  min-height: 760px;
  height: calc(100vh - 200px);
  &__head {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
    margin-bottom: 24px;
  }
  & > p {
    margin-bottom: 16px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #0f131e;
    &:last-child {
      margin-top: 24px;
      margin-bottom: 0;
    }
  }
  h4 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 130%;
    color: #0f131e;
  }

  &__transfer {
    padding-inline: 20px;
    margin-block: 24px;
    max-width: 560px;
    & > div {
      padding: 4px 16px;
      display: flex;
      gap: 16px;
      border-bottom: 1px solid #f5f5f5;
      & > div {
        &:first-child {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #509c5b;
          flex: 1;
        }
        &:last-child {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #0f131e;
          flex: 2;
        }
      }
    }
  }
  &__details {
    max-width: 560px;
    background: #f5f5f5;
    padding: 18px 20px;
    &_inner {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      & > div {
        padding-block: 4px;
        display: flex;
        border-bottom: 1px solid #b3b3b3;
        & > div {
          &:first-child {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #0f131e;
            flex: 1;
          }
          &:last-child {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #0f131e;
            flex: 1.5;
          }
        }
      }
    }
  }
}
@media (max-width: 1200px) {
  .container {
    background: none;
  }
}
@media screen and (max-width: 840px) {
  .container {
    height: 100vh;
  }
  .footer {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .order {
    max-width: none;
    width: 100%;
  }
}

@media screen and (max-width: 575px) {
  .order {
    padding-inline: 0;
    &__head {
      padding-inline: 10px;
      flex-direction: row-reverse;
      justify-content: space-between;
    }
    & > p {
      padding-inline: 10px;
    }
    & > h4 {
      padding-inline: 10px;
    }
    &__transfer {
      padding-inline: 10px;
      max-width: none;
      width: 100%;
      & > div {
        & > div {
          &:first-child {
            flex: 0.75;
          }
          &:last-child {
            flex: 2;
          }
        }
      }
    }
    &__details {
      max-width: none;
      width: 100%;
      background: #f5f5f5;
      padding: 18px 20px;
      &_inner {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        & > div {
          display: flex;
          & > div {
            &:first-child {
              flex: 1;
            }
            &:last-child {
              flex: 1.5;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .order {
    &__transfer {
      & > div {
        padding-inline: 0;
        & > div {
          &:first-child {
            flex: 1;
          }
          &:last-child {
            flex: 1;
          }
        }
      }
    }
    &__details {
      border-left: 3px solid #509c5b;
    }
  }
}

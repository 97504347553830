.stats {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 20px;
  padding: 4px 22px 4px 4px;
  display: flex;
  justify-content: space-between;
  width: auto;
  min-width: 150px;
  &__wrapper {
    margin-left: 5px;
    display: flex;
    align-items: center;
    span {
      margin-right: 6px;
    }
  }
}

@media screen and (max-width: 525px) {
  .stats {
    width: 170px;
    padding-right: 10px;
    font-size: 14px;
  }
}
@media screen and (max-width: 475px) {
  .stats {
    min-width: 110px;
    width: 100px;
    // border-radius: 0;
    // border: none;
    justify-content: flex-start;
    gap: 8px;
    img {
      width: 20px;
      height: 20px;
    }
  }
}

@media screen and (max-width: 363px) {
  .stats {
    min-width: 65px;
    width: 65px !important;
    border-radius: 0;
    border: none;
    justify-content: flex-start;
    gap: 8px;
    img {
      width: 20px;
      height: 20px;
    }
  }
}

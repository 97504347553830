.stats {
  padding: 9px 22px 115px 20px;
  &__subheader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 26px;
    margin: 26px 0 16px 0;
    &:first-child {
      margin-top: 0;
    }
  }
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    > div {
      flex: 1;
    }
  }
  &__chart {
    background-color: #f8f8f8;
    padding: 24px 28px;
    color: #0f131e;
    display: flex;
    flex-direction: column;
    gap: 24px;
    &_title {
      font-size: 24px;
      font-weight: 700;
      font-family: "Montserrat";
    }
    &_options {
      display: flex;
      justify-content: space-between;
    }
    &_bars {
      display: flex;
      gap: 15px;
      & > div {
        display: flex;
        align-items: center;
        gap: 2px;
        font-size: 14px;
        div {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #fa8a07;
        }
        &:nth-child(2) {
          div {
            background-color: #079ea8;
          }
        }
        &:last-child {
          div {
            background-color: #067be7;
          }
        }
      }
    }
    &_date {
      display: flex;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      &_bar{
        cursor: pointer;
      &_active{
        text-decoration: underline;
        cursor: pointer;
      }}
    }
  }
}

@media (max-width: 1078px) {
  .stats{
    &__wrapper{
     & > div{
      flex: 1;
      &:last-child{
        display: none;
      }
     } 
    }
  }
}
@media screen and (max-width: 662px){
  .stats{
    padding-inline: 10px;
    &__wrapper{
      flex-direction: column;
      gap: 10px;
      & > div{
        width: 100%;
      }
    }
    &__subheader{
      flex-direction: column;
      align-items: flex-start;
      padding-left: 0;
      gap: 4px;
    }
    &__chart{
      display: none;
    }
  }
}
@media screen and (max-width: 375px){
  .stats{
    &__back{
      a{
        width: 100%;
        justify-content: center;
      }
    }
  }
}
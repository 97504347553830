.check{
  padding: 53px 0;
  height: calc(100vh - 249px);
  min-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__title{
    font-family: 'Montserrat', sans-serif;
    margin: 4px 0 20px 0;
    font-weight: 600;
    font-size: 42px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #0F131E;
    text-align: center;
    max-width: 529px;
  }
  &__descr{
    font-size: 20px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #0F131E;
    text-align: center;
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 425px){
  .check{
    padding-inline: 10px;
    justify-content: flex-start;
    height: auto;
    &__title{
      font-size: 32px;
    }
    &__descr{
      font-size: 16px;
    }
    a{
      width: 100%;
      justify-content: center;
    }
  }
}
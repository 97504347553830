@import '../../../../../../styling/variables.scss';

.news{
  width: 294px;
  min-width: 294px;
  margin-right: 24px;
  &__image{
    width: 100%;
    height: 150px;
    position: relative;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__title{
    margin-top: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #0F131E;
  }
  &__descr{
    margin-top: 5px;
    font-size: 14px;
    line-height: 130%;
    color: #0F131E;
  }
  &__data{
    margin-top: 5px;
    position: relative;
    padding-left: 7px;
    border-left: 1px solid  #509c5b;
    font-size: 12px;
    line-height: 140%;
    color: #0F131E;
  }
  &:last-child{
    margin-right: 0;
  }
}
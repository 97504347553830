.insured {
  margin-top: 134px;
  &__inner {
    display: flex;
    gap: 96px;
    justify-content: space-between;
  }
  &__left {
    width: 387px;
    max-width: 387px;
    height: 392px;
    background: #c5d483;
    position: relative;
    flex: 1;
    img {
      position: absolute;
      top: -67px;
      right: -19px;
    }
  }
  &__right {
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex: 1.75;
    h2 {
      line-height: 110%;
      letter-spacing: -0.03em;
      color: #0f131e;
    }
    p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      color: #0f131e;
      width: 85%;
    }
    & > div {
      display: flex;
      flex-direction: column;
      gap: 10px;
      div {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }
}
@media (max-width: 1200px) {
  .insured {
    &__inner {
      gap: 72px;
    }
    &__right {
      flex: 1;
      p {
        width: 100%;
      }
    }
  }
}
@media (max-width: 991px) {
  .insured {
    &__left {
      img {
        width: 310px;
        top: -34px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .insured {
    &__inner {
      flex-direction: column;
    }
    &__left {
      width: 80%;
      max-height: 360px;
      display: flex;
      justify-content: center;
      margin: 0 auto;
      img {
        position: relative;
        right: -40px;
        top: -60px;
        height: 440px;
      }
    }
  }
}
@media screen and (max-width: 576px) {
  .insured {
    margin-top: 80px;
    &__left {
      width: 250px;
      max-height: 260px;
      img {
        top: -40px;
        height: 330px;
        width: 230px;
      }
    }
  }
}
@media screen and (max-width: 425px) {
  .insured {
    &__left {
      margin-left: 20px;
    }
  }
}

.modal {
  height: 100vh;
  width: 100%;
  background: rgba(60, 60, 60, 0.05);
  backdrop-filter: blur(5px);
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0f131e;
  &__inner {
    width: 450px;
    max-width: 450px;
    height: 400px;
    background-color: #fff;
    border: 1px solid #f8f8f8;
    box-shadow: 0px 10px 20px -3px rgba(60, 60, 60, 0.6);
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;
    gap: 32px;
    button{
      top: 15px;
      right: 15px;
      position: absolute;
      cursor: pointer;
      outline: none;
      background: none;
      border: none;
    }
    h3 {
      font-size: 24px;
      font-family: "Montserrat";
      font-weight: 700;
    }
  }
  &__harvest {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    &_input {
      padding: 8px 10px;
      display: flex;
      justify-content: space-between;
      width: 330px;
      border-bottom: 1px solid #509c5b;
      p {
        color: rgba(60, 60, 60, 0.8);
      }
      span {
        font-family: "Montserrat";
        font-weight: 600;
        font-size: 18px;
      }
    }
    &_button {
        width: 80%;
        border: 1px solid #D7D3C1;
        padding: 14px 16px;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        img{
            cursor: pointer;
        }
    }
  }
  p{
    font-weight: 600;
  }
  a{
    width: 200px;
    justify-content: center;
  }
}

.table {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  &__head {
    display: flex;
    gap: 4px;
    padding-inline: 16px;
    padding-block: 8px;
    div {
      font-size: 14px;
      color: rgba(60, 60, 60, 0.8);
      &:first-child {
        flex: 12%;
      }
      &:nth-child(2) {
        flex: 32.5%;
      }
      &:nth-child(3) {
        flex: 10.7%;
      }
      &:last-child {
        flex: 40.7%;
      }
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
  }
  &__row {
    display: flex;
    gap: 4px;
    padding-inline: 16px;
    padding-block: 18px;
    border-bottom: 1px solid #f5f5f5;
    flex-wrap: wrap;
    div {
      font-size: 14px;
      font-family: "Montserrat";
      font-weight: 500;
      &:first-child {
        flex: 12%;
      }
      &:nth-child(2) {
        flex: 32.5%;
      }
      &:nth-child(3) {
        flex: 10.7%;
        display: flex;
        justify-content: flex-start;
        div {
          font-weight: 600;
        }
      }
      &:last-child {
        flex: 40.7%;
      }
    }
    &:nth-child(even) {
      background-color: #f5f5f5;
    }
  }
}

@media (max-width: 1200px) {
  .table {
    &__head {
      div {
        &:first-child {
          flex: 12%;
        }
        &:nth-child(2) {
          flex: 20.5%;
        }
        &:nth-child(3) {
          flex: 10.7%;
        }
        &:last-child {
          flex: 35.7%;
        }
      }
    }
    &__row {
      div {
        &:first-child {
          flex: 12%;
        }
        &:nth-child(2) {
          flex: 20.5%;
        }
        &:nth-child(3) {
          flex: 10.7%;
        }
        &:last-child {
          flex: 35.7%;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .table {
    &__head {
      div {
        &:first-child {
          flex: 12%;
        }
        &:nth-child(2) {
          flex: 20.5%;
        }
        &:nth-child(3) {
          flex: 12.7%;
        }
        &:last-child {
          flex: 35.7%;
        }
      }
    }
    &__row {
      & > div {
        &:first-child {
          flex: 12%;
        }
        &:nth-child(2) {
          flex: 20.5%;
        }
        &:nth-child(3) {
          flex: 12.7%;
        }
        &:last-child {
          flex: 35.7%;
        }
      }
    }
  }
}
@media (max-width: 560px) {
  .table {
    &__head {
      display: none;
    }
    &__row {
      gap: 0;
      & > div {
        &:first-child {
          flex: 50%;
          order: 1;
        }
        &:nth-child(2) {
          flex: 100%;
          &:before {
            content: "Amount";
            color: rgba(60, 60, 60, 0.8);
            margin-right: 10px;
          }
          order: 3;
        }
        &:nth-child(3) {
          flex: 50%;
          order: 2;
          justify-content: flex-end;
          flex-direction: row-reverse;
        }
        &:last-child {
          flex: 100%;
          order: 4;
          &:before {
            content: "IBAN";
            color: rgba(60, 60, 60, 0.8);
            margin-right: 30px;
          }
        }
      }
    }
  }
}
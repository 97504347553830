.cultivation {
  margin-top: 67px;
  &__inner {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  &__subtitle{
    background-color: #509c5b;
    margin-bottom: 15px;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    padding: 4px 14px;
    border-radius: 5px;
    width: max-content;
    text-align: left;
  }
  &__left {
    width: 270px;
    background: #d7d3c1;
    position: relative;
    display: grid;
    place-content: center;
    img {
      object-fit: contain;
      width: 230px;
    }
  }
  &__right {
    flex: 2.75;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h2 {
      font-family: "Montserrat";
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      letter-spacing: -0.48px;
      margin-bottom: 9px;
    }
    h3 {
      font-family: "Montserrat";
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      margin-bottom: 16px;
    }
    a {
      width: fit-content;
    }
    p {
      font-family: "Roboto";
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      margin-bottom: 16px;
    }
  }
}
@media screen and (max-width: 991px) {
  .cultivation {
    &__inner {
      flex-direction: column;
      gap: 2rem;
      align-items: center;
    }
    &__left {
      height: 320px;
    }
  }
}
@media screen and (max-width: 576px) {
  .cultivation {
    display: none;
    &__left {
      width: 100%;
      height: 160px;
      img{
        width: 100px;
      }
    }
    &__right {
      h3 {
        font-size: 24px;
      }
    }
  }
}

.modal {
  height: 100vh;
  width: 100%;
  background: rgba(60, 60, 60, 0.05);
  backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  left: 239px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0f131e;
  font-size: 20px;
  font-family: 'Montserrat';
  font-weight: 100;
  text-align: center;
  z-index: 100;
  &__inner {
    width: 450px;
    background-color: #fff;
    border: 1px solid #f8f8f8;
    box-shadow: 0px 10px 20px -3px rgba(60, 60, 60, 0.6);
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;
    padding-inline: 24px;
    padding-block: 40px 32px;
    gap: 32px;
    transform: translateX(-119px);
  }
}

@media screen and (max-width: 840px){
  .modal{
    left: 0;
    padding-inline: 10px;
    &__inner{
      transform: translateX(0);
      min-width: 360px;
    }
  }
}

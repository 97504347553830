.lang{
  position: relative;
  z-index: 5;
  width: 65px;
  &__info{
    display: flex;
    align-items: center;
    cursor: pointer;
    div{
      margin: 0 2px;
      font-weight: 500;
      font-size: 14px;
      line-height: 130%;
      color: #0F131E;
    }
  }
  &__menu{
    position: absolute;
    padding-block: 8px;
    height: 155px;
    width: 277px;
    background: #FFFFFF;
    border: 1px solid #F5F5F5;
    box-shadow: 0px 13px 11px rgba(0, 0, 0, 0.05);
    border-radius: 2px;
    top: 32px;
    left: -20px;
    opacity: 0;
    transform: translateY(-100px);
    visibility: hidden;
    transition: .2s ease-in-out;
    overflow-y: scroll;
    z-index: 1001;
  }
  &__open{
    .lang__menu{
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
      transition: .2s ease-in-out;
    }
  }
}
/** @format */

@import "../../../../styling/variables.scss";

.packages {
  margin-top: 67px;
  &__en {
    margin-top: 123px;
  }
  h2 {
    text-align: center;
    margin-bottom: 24px;
  }
  &__desc {
    background-color: $green;
    margin-bottom: 25px;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    padding: 4px 14px;
    border-radius: 5px;
    width: max-content;
    text-align: center;
    margin-inline: auto;
  }
  &__first {
    display: flex;
    margin-top: 20px;
    &_left {
      flex: 1;
      display: flex;
      &__img {
        background: #509c5b;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &__text,
      &__text_mobile {
        &_mobile {
          display: none;
        }
        background: #d7d3c1;
        flex: 2.5;
        padding-block: 18px 14px;
        padding-inline: 24px;
        h5 {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 130%;
          color: #0f131e;
        }
        p {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 600;
          font-size: 32px;
          line-height: 120%;
          letter-spacing: -0.03em;
          color: #0f131e;
        }
      }
    }
    &_right {
      flex: 2;
      border: 1px solid $green;
      display: flex;
      & > div {
        flex: 1;
        padding-top: 18px;
        padding-inline: 36px 48px;
        display: flex;
        align-items: flex-start;
        gap: 16px;
        p {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 130%;
          color: #0f131e;
          margin-top: 0;
        }
        &:first-child {
          border-right: 1px solid $green;
        }
      }
    }
  }
  &__second {
    display: flex;
    margin-top: 24px;
    &_left {
      flex: 1;
      background: #e1e1e1;
      padding-left: 18px;
      display: flex;
      align-items: center;
      max-width: 379px;
      gap: 32px;
      & > div {
        display: flex;
        flex-direction: column;
        gap: 12px;
        p {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 120%;
          letter-spacing: -0.03em;
          color: #0f131e;
        }
        & > div {
          display: flex;
          gap: 14px;
          div {
            background-color: #509c5b;
            width: 42px;
            height: 42px;
            svg {
              width: 100%;
              height: 100%;
              padding: 8px;
            }
          }
        }
      }
    }
    &_right {
      flex: 2;
      margin-bottom: 40px;
      margin-left: 10px;
      .table {
        &__head,
        &__head_mobile {
          padding-block: 8px;
          padding-inline: 16px;
          display: flex;
          & > div {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 110%;
            color: rgba(60, 60, 60, 0.8);
            &:first-child {
              flex: 1;
            }
            &:nth-child(2) {
              flex: 1;
            }
            &:last-child {
              flex: 1;
              // text-align: right;
            }
          }
        }
        &__head_mobile {
          display: none;
        }
        &__body {
          display: flex;
          flex-direction: column;
        }
        &__month {
          display: flex;
          flex-wrap: wrap;
          div {
            padding-right: 4px;
          }
        }
        &__row {
          padding: 16px;
          display: flex;
          border-bottom: 1px solid #e1e1e1;;
          & > div {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 120%;
            letter-spacing: -0.03em;
            color: #0f131e;
            &:first-child {
              flex: 1;
            }
            &:nth-child(2) {
              flex: 1.5;
            }
            &:last-child {
              flex: 1;
              // text-align: right;
              font-weight: 700;
              color: #509c5b;
            }
          }
          &_month {
            font-weight: 600 !important;
            font-family: "Montserrat" !important;
          }
          &:nth-child(odd) {
            background-color: #e1e1e1;
          }
        }
      }
    }
  }
  &__adds {
    margin-top: -30px;
    text-align: right;
    padding-right: 20px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: rgba(60, 60, 60, 0.8);
  }
}
.mobile {
  display: none;
}
@media (max-width: 1200px) {
  .packages {
    &__second {
      &_left {
        gap: 12px;
        svg {
          width: 64px;
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .packages {
    &__first {
      flex-direction: column;
      &_right {
        & > div {
          padding-bottom: 24px;
        }
      }
    }
    &__second {
      flex-direction: column;
      &_left {
        gap: 32px;
        padding: 46px 30px 30px 18px;
        max-width: none;
        svg {
          width: 74px;
        }
      }
      &_right {
        margin: 0;
      }
    }
    &__adds{
      margin-top: 14px;
    }
  }
}
@media screen and (max-width: 768px) {
  .table__head_mobile {
    display: flex;
  }
  .table__head {
    display: none;
  }
  .packages {
    h2,
    &__desc {
      display: none;
    }
    &__second_left {
      display: none;
    }
    &__second {
      &_right {
        .table {
          &__head_mobile {
            display: flex;
          }

          &__head {
            display: none;
          }
        }
      }
    }

    &__first {
      &_left__text {
        display: none;
      }
      &_left__text_mobile {
        display: block;
      }
      &_right {
        display: none;
        flex-direction: column;
        & > div {
          &:first-child {
            border-right: 0;
            border-bottom: 1px solid #509c5b;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 576px) {
  .packages {
    margin-top: 40px;
    padding-block: 0;
    &__desc {
      font-weight: 600;
      font-size: 16px;
      line-height: 120%;
      letter-spacing: -0.03em;
    }
    &__first {
      margin-top: 12px;
      &_right {
        & > div {
          padding: 26px 30px 26px 26px;
        }
      }
    }
    &__second {
      &_left {
        & > div {
          & > div {
            flex-wrap: wrap;
          }
        }
      }
      &_right{
        .table{
          &__row{
            padding-inline: 8px;
          }
        }
      }
    }
    &__adds {
      text-align: left;
    }
    .table {
      &__month {
        div {
          padding: 0 4px 0 0;
        }
      }
    }
  }
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
}

@media screen and (max-width: 380px) {
  .packages {
    margin-top: 0px;
    &__second{
      &_right{
        .table{
          &__row{
            padding-inline: 8px;
            & > div{
              &:first-child{
                flex: 0.75;
              }
            }
          }
          &__month{
            flex: 0.5;
          }
        }
      }
    }
  }
}

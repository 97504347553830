.articles {
  margin-top: 67px;
  background: #f8f8f8;
  padding-block: 2rem;
  &__inner {
    display: flex;
    width: 100%;
    gap: 1rem;
  }
}

.article {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  h3 {
    text-align: left;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 24px;
  }
  &__body {
    display: flex;
    gap: 0.8rem;
    picture {
      display: block;
      img {
        width: 240px;
        height: 200px;

        object-fit: cover;
      }
    }
    p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      color: #0f131e;
    }
  }
}
.mobile {
  display: none;
}
@media (max-width: 1200px) {
  .article {
    h3 {
      height: 58px;
    }
    &__body {
      picture {

        img {
          // min-height: 224px;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .articles {
    &__inner {
      gap: 36px;
      flex-direction: column;
    }
  }
  .article {
    h3 {
      height: auto;
    }
    &__body {
      picture {
        img {
          // height: 100%;
          // min-height: auto;
        }
      }
    }
  }
}
@media screen and (max-width: 576px) {
  .article{
    &__body{
      flex-direction: column;
      picture{
        img{
          width: 100%;
          max-height: 220px;
          height: 220px;
        }
      }
    }
  }
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
  .articles {
    margin-top: 40px;
  }
}

.block {
  background: #f8f8f8;
  border: 1px solid #f5f5f5;
  border-radius: 2px;
  padding: 16px 24px;
  display: flex;
  transition: 0.2s ease-in-out;
  flex-wrap: wrap;
  &__title {
    font-size: 18px;
    line-height: 130%;
    color: rgba(60, 60, 60, 0.8);
    margin-bottom: 4px;
    flex: 100%;
  }
  &__value {
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #0f131e;
    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 120%;
      letter-spacing: -0.03em;
      color: #0f131e;
      padding-left: 9px;
    }
  }
  &__link {
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #509c5b;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    cursor: pointer;
    flex: 70%;
    justify-content: flex-end;
    svg {
      margin-left: 3px;
      transition: 0.2s ease-in-out;
    }
    &:hover {
      svg {
        transform: translateX(5px);
        transition: 0.2s ease-in-out;
      }
    }
  }
  &:hover {
    -webkit-box-shadow: 0px 0px 18px -12px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 18px -12px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 18px -12px rgba(0, 0, 0, 0.75);
    transition: 0.2s ease-in-out;
  }
}

@media screen and (max-width: 662px) {
  .block {
    padding: 10px;
    justify-content: space-between;
    &__title{
      flex: 70%;
    }
    &__value{
      flex: 30%;
      text-align: right;
    }
    &__link{
      flex: 100%;
      justify-content: flex-start;
    }
  }
}

@import "../../../../styling/variables.scss";

.main {
  &__header {
    margin-top: 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > button{
      display: none;
    }
  }
  &__timer {
    padding: 12px 19px;
    background: #c6eccc;
    border-bottom: 2px solid $green;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 11px;
    div {
      display: flex;
      align-items: center;
    }
    &_label {
      margin-left: 10px;
      font-weight: 600;
      font-size: 16px;
      line-height: 120%;
      letter-spacing: -0.03em;
      color: #0f131e;
    }
    &_time {
      font-weight: 500;
      font-size: 16px;
      line-height: 120%;
      letter-spacing: -0.03em;
      color: #0f131e;
    }
  }
  &__info {
    margin-top: 11px;
    display: flex;
    justify-content: space-between;
    &_image {
      width: 406px;
      img {
        width: 100%;
      }
    }
  }
  &__web {
    &_info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 11px;
      &_text {
        font-size: 13px;
        line-height: 140%;
        color: #0f131e;
      }
      &_row:first-child {
        .main__web_info_text:first-child {
          margin-right: 8px;
          padding-right: 8px;
          border-right: 1px solid #b3b3b3;
        }
      }
      &_row:last-child {
        .main__web_info_text:first-child {
          margin-right: 8px;
        }
      }
    }
  }
  &__block {
    padding: 30px 28px 24px 24px;
    background: #f8f8f8;
    border-left: 2px solid $lightGreen;
    display: flex;
    justify-content: space-between;
    &_info {
      width: calc(50% - 20px);
    }
    &_right {
      width: calc(100% - 426px);
    }
    &_row {
      display: flex;
      margin-bottom: 8px;
      width: 100%;
      &_label {
        width: 130px;
        min-width: 130px;
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        color: rgba(60, 60, 60, 0.8);
        padding-top: 2px;
      }
      &_value {
        font-weight: 600;
        font-size: 18px;
        line-height: 140%;
        color: #0f131e;
        width: 100%;
        &_status {
          > div {
            display: inline;
          }
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    &:last-child {
      .main__block_row_value {
        text-align: right;
      }
    }
  }
}

.cultivate {
  margin-top: 22px;
  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    color: #0f131e;
    font-family: "Montserrat", sans-serif;
  }
  &__table {
    margin-top: 16px;
    height: 266px;
    overflow: auto;
    padding-right: 5px;
    &::-webkit-scrollbar {
      width: 2px;
      background: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
      width: 4px;
      background: #b3b3b3;
      border-radius: 2px;
    }
  }
  &__row {
    padding: 10px 20px;
    display: flex;
    background: #f5f5f5;
    &:nth-child(even) {
      background: #ffffff;
    }
    div {
      font-weight: 400;
      font-size: 14px;
      line-height: 130%;
      color: #0f131e;
      &:last-child {
        margin-left: 20px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .main {
    &__block {
      padding: 30px 15px 24px 15px;
      flex-direction: column;
      &_info {
        width: 100%;
      }
      &_row {
        &_value {
          font-size: 14px;
        }
      }
    }
  }
}
@media screen and (max-width: 840px) {
  .cultivate {
    height: calc(50vh - 84px);
    &__table {
      height: 35vh;
    }
  }
  .main {
    padding-bottom: 84px;
  }
}
@media screen and (max-width: 768px) {
  .main {
    &__info {
      flex-direction: column;
      &_image {
        width: 100%;
      }
    }
    &__block {
      &_right {
        margin-top: 12px;
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 475px) {
  .main {
    padding-inline: 11px 14px;
    &__block {
      gap: 24px;
      padding-block: 16px;
      &_row {
        div{
          font-size: 16px;
        }
      }
    }
    & > button{
      display: none;
    }
    &__header{
      & > button{
        display: block;
      }
    }
  }
  .cultivate{
    &__title{
      font-size: 18px;
    }
    &__row{
      div{
        &:first-child{
          display: flex;
          flex-direction: column;
          span{
            color: #3C3C3CCC;
            font-size: 12px;
          }
        }
      }
    }

  }
}

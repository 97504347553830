.stats {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 20px;
  padding: 4px 22px 4px 4px;
  display: flex;
  justify-content: space-between;
  width: auto;
  min-width: 150px;
  &__wrapper {
    margin-left: 5px;
    display: flex;
    align-items: center;
    span {
      margin-right: 6px;
    }
  }
}

@media screen and (max-width: 475px) {
  .stats {
    min-width: 130px !important;
    width: 130px !important;
    justify-content: flex-start;
    gap: 4px;
    div{
      font-size: 12px;
    }
    img {
      width: 20px;
      height: 20px;
    }
  }
}

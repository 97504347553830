.link {
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  color: #fffff8;
  transition: all 200ms ease;
  padding-inline: 16px;
  margin-bottom: 8px;
  padding-block: 2px;
  & > span {
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
  }
  &:hover {
    background: #c5d48380;
  }
}
.disabled {
  pointer-events: none;
  color: #0000005c;
}
.active {
  background: #c5d483;
  &:hover {
    background: #c5d483;
  }
}

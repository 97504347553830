.table {
  padding-block: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: #0f131e;
  &__entries {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &_settings {
      display: flex;
      gap: 16px;
      align-items: center;
      font-weight: 500;
      select {
        padding: 12px 16px;
        font-size: 14px;
        font-weight: 400;
        border: 1px solid #d7d3c1;
      }
    }
    &_info {
      font-size: 14px;
      color: #b3b3b3;
    }
  }
  &__table {
    &_head {
      display: flex;
      gap: 4px;
      padding-block: 8px;
      padding-inline: 16px;
      color: rgba(60, 60, 60, 0.8);
      font-size: 14px;
      font-weight: 500;
      & > div {
        flex: 1;
        &:first-child {
          flex: 0.9;
        }
        &:last-child {
          text-align: right;
        }
      }
    }
    &_body {
      .line {
        border-bottom: 1px solid #f5f5f5;
        &__data {
          display: flex;
          gap: 4px;
          padding-block: 16px;
          font-size: 14px;
          padding-inline: 16px;
          transition: border 0.2s ease;
          & > div {
            flex: 1;
            font-family: "Montserrat";
            &:first-child {
              flex: 0.9;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            &:last-child {
              text-align: right;
            }
          }
        }
        &:nth-child(odd) {
          background: #f5f5f5;
        }
        &:hover {
          cursor: pointer;
          transition: border 0.2s ease;
          & {
            border-bottom: 1px solid #6ba32baa;
          }
        }
        &__chart {
          display: none;
          padding-inline: 10px;
          margin-block: 8px;
          &_date {
            display: flex;
            gap: 12px;
          }
          &_bars {
            display: flex;
            gap: 15px;
            margin-block: 16px 10px;
            & > div {
              display: flex;
              align-items: center;
              gap: 2px;
              font-size: 14px;
              div {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: #fa8a07;
              }
              &:nth-child(2) {
                div {
                  background-color: #079ea8;
                }
              }
              &:last-child {
                div {
                  background-color: #067be7;
                }
              }
            }
          }
        }
      }
    }
    &__pagination {
      display: flex;
      gap: 8px;
      align-items: center;
      & > * {
        cursor: pointer;
        width: 20px;
        font-size: 14px;
      }
    }
  }
  &__copy {
    cursor: pointer;
    border: none;
    outline: none;
    width: 25px;
    height: 25px;
    position: relative;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    &_container {
      text-align: center;
    }
    &_state {
      text-align: center;
      pointer-events: none;
      font-size: 9px;
      opacity: 0;
      z-index: -1;
      transition: 0.2s ease-in-out;
      &_active {
        opacity: 1;
        z-index: 1;
        color: #509c5b;
        transition: 0.2s ease-in-out;
      }
    }
    img {
      width: 18px;
      height: 18px;
    }
  }
  &__inner {
    &_wrapper {
      border-radius: 8px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__id {
    &_copy {
      display: flex;
      align-items: center;
      &_text {
        color: #509c5b;
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .table {
    &__table {
      &_head {
        & > div {
          &:first-child {
            flex: 0.7;
          }
        }
      }
      &_body {
        .line {
          &__data {
            & > div {
              &:first-child {
                flex: 0.7;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 662px) {
  .table {
    &__table {
      &_head {
        display: none;
      }
      &_body {
        .line {
          &__data {
            gap: 0;
            flex-wrap: wrap;
            & > div {
              &:first-child {
                order: 1;
                flex: 20%;
              }
              &:nth-child(2) {
                order: 2;
                flex: 30%;
              }
              &:nth-child(3) {
                order: 4;
                flex: 100%;
              }
              &:nth-child(4) {
                display: none;
              }
              &:nth-child(5) {
                display: none;
              }
              &:last-child {
                order: 3;
                flex: 50%;
                text-align: left;
                margin: 25px 0 15px 0;
              }
            }
          }
          &__clients {
            order: 8;
            flex: 30%;
            position: relative;
            margin-top: 20px;
            &::before {
              content: "Clients";
              position: absolute;
              top: -18px;
              color: rgba(60, 60, 60, 0.8);
              font-size: 14px;
            }
          }
          &__sale {
            order: 7;
            flex: 30%;
            position: relative;
            margin-top: 20px;
            &::before {
              content: "Sales";
              position: absolute;
              top: -18px;
              color: rgba(60, 60, 60, 0.8);
              font-size: 14px;
            }
          }
          &__purchases {
            order: 9;
            flex: 30%;
            position: relative;
            margin-top: 20px;
            &::before {
              content: "Purchases";
              position: absolute;
              top: -18px;
              color: rgba(60, 60, 60, 0.8);
              font-size: 14px;
            }
          }
        }
      }
    }
    &__pagination {
      justify-content: center;
    }
  }
}

@media screen and (max-width: 425px) {
  .table {
    &__entries {
      flex-direction: column-reverse;
      align-items: flex-end;
      gap: 8px;
    }
    &__table {
      &_body {
        .line {
          &__chart {
            &_date {
              flex-wrap: wrap;
              & > div {
                width: 100%;
                label {
                  justify-content: space-between;
                }
              }
            }
          }
        }
      }
    }
  }
}

.press {
  &__title {
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: 42px;
    line-height: 110%;
    letter-spacing: -0.03em;
    color: #0f131e;
    margin-top: 10px;
  }
  &__inner {
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  &__group {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    h3 {
      font-family: "Montserrat";
      font-size: 24px;
      font-weight: 700;
      line-height: 31.2px;
      color: #509c5a;
      flex: 1;
    }
    ul {
      // flex: 2;
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
    &_item {
      display: flex;
      flex-direction: column;
      gap: 4px;
      h5 {
        font-family: "Montserrat";
        font-size: 18px;
        font-weight: 700;
        line-height: 23.4px;
        margin-bottom: 8px;
      }
      a {
        font-family: "Roboto";
        font-size: 16px;
        font-weight: 400;
        line-height: 22.4px;
        color: #3c3c3ccc;
        word-break: break-all;
      }
      span {
        display: block;
        font-family: "Roboto";
        font-size: 12px;
        font-weight: 400;
        line-height: 16.8px;
        color: #509c5a;
      }
    }
  }
}
.pressContainer {
  max-width: 1140px;
}
@media screen and (max-width: 840px) {
  .press {
    &__inner {
      margin-bottom: 100px;
    }
  }
}
@media screen and (max-width: 768px) {
  .press {
    &__group {
      flex-direction: column;
      ul {
        padding-left: 20px;
      }
    }
  }
}
@media screen and (max-width: 576px) {
  .press {
    &__title {
      font-size: 24px;
    }
    &__inner {
      gap: 32px;
    }
    &__group {
      gap: 16px;
      h3 {
        font-size: 18px;
        line-height: 23.4px;
      }
      ul {
        gap: 16px;
      }
      &_item {
        h5 {
          margin-bottom: 4px;
        }
      }
    }
  }
}

.pagination{
  > ul {
    display: flex;
    align-items: center;
  }
    &__button{
    outline: none;
    border: none;
    cursor: pointer;
    background: transparent;
    display: flex;
    align-items: center;
    width: 20px;
    justify-content: center;
    font-size: 13px;
    line-height: 20px;
    color: #B3B3B3;
    transition: .2s ease-in-out;
    svg{
      min-width: 16px;
    }
    &__active > a{
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      color: #0F131E;
      transition: .2s ease-in-out;
    }
    &__text {
      text-decoration: none;
      display: flex;
      align-items: center;
      color: #B3B3B3;
    }
  }
}
.wrapper {
  display: flex;
  height: 100svh;
}

.container {
  width: 100%;
  position: relative;
  height: 100vh;
  flex: 7;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 4px;
    background: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    width: 4px;
    background: #b3b3b3;
    border-radius: 2px;
  }
}

@media screen and (max-width: 840px) {
  .container {
    height: 100vh;
    padding-bottom: 60px;
  }
  .footer {
    display: none;
  }
}

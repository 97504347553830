/** @format */

.main {
  padding: 0 23px 84px 20px;
  height: calc(100vh - 200px);
  overflow-y: scroll;
  h2 {
    margin-left: 12px;
  }
  &__empty {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    h2 {
      font-size: 24px;
      text-align: center;
    }
  }

  &__container {
    margin-top: 24px;
    display: flex;
    gap: 16px;
    height: 100%;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 840px){
  .main{
    height: auto;
  }
}

@import '../../styling/variables.scss';

.back{
  padding: 12px 32px;
  border: 1px solid #B3B3B3;
  border-radius: 4px;
  background: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 110%;
  color: #0F131E;
  cursor: pointer;
  transition: .2s ease-in-out;
  &:hover{
    background: $lightGreen;
    border-color: $lightGreen;
    transition: .2s ease-in-out;
  }
}
@import '../../styling/variables.scss';

.input {
  position: relative;
  display: block;
  margin: 0;
  width: 100%;
  margin: 0;

  textarea {
    width: 100%;
    margin-top: 8px;
    padding: 12px 16px;
    font-size: 16px;
    outline-color: transparent;
    outline-width: 0px;
    color: #0f131e;
    border: none;
    background-color: #fff;
    border-bottom: 2px solid #d7d3c1;
    transition: 0.2s ease-in-out;
    resize: none;
  }

  &__title {
    padding-left: 16px;
    font-size: 16px;
    line-height: 130%;
    color: #0f131e;
  }
  &__input {
    width: 100%;
    margin-top: 8px;
    padding: 0 16px;
    font-size: 16px;
    line-height: 130%;
    outline-color: transparent;
    outline-width: 0px;
    color: #0f131e;
    border: none;
    border-bottom: 2px solid $green;
    background: #f8f8f8;
    transition: 0.2s ease-in-out;
    &::-webkit-input-placeholder {
      color: #b3b3b3;
    }
    &::-moz-placeholder {
      color: #b3b3b3;
    }
    &:-ms-input-placeholder {
      color: #b3b3b3;
    }
    &:-moz-placeholder {
      color: #b3b3b3;
    }
    &_error {
      color: #ca4b15;
      border-color: #ca4b15;
      transition: 0.2s ease-in-out;
      &::-webkit-input-placeholder {
        color: #ca4b15;
      }
      &::-moz-placeholder {
        color: #ca4b15;
      }
      &:-ms-input-placeholder {
        color: #ca4b15;
      }
      &:-moz-placeholder {
        color: #ca4b15;
      }
    }
  }
  &__toggle {
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 38px;
    right: 13px;
  }
  &__error {
    margin-top: 2px;
    padding-left: 16px;
    display: flex;
    align-items: center;
    svg {
      min-width: 12px;
      width: 12px;
    }
    div {
      font-weight: 500;
      font-size: 11px;
      line-height: 130%;
      color: #ca4b15;
      margin: 2px 0 0 4px;
    }
  }
}

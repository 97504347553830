/** @format */

@import '../../../../styling/variables.scss';

.reasons {
  // margin-top: 67px;
  color: $blackGreen;
  background-color: #f8f8f8;
  padding-bottom: 67px;
  padding-top: 99px;

  &__head {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
  }
  &__pretitle {
    color: $gray;
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 600;
  }
  &__title {
    width: 100%;
    margin: 0 auto;
    max-width: 944px;
    text-align: center;
  }
  &__grid {
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(
      auto-fit,
      minmax(350px, 1fr)
    );
    row-gap: 32px;
    column-gap: 30px;
    margin-top: 45px;

    .tile {
      display: flex;
      gap: 14px;
      flex-direction: column;
      align-items: center;
      img {
        width: 75px;
        min-width: 75px;
        height: 70px;
      }
      h4 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 140%;
        text-align: center;
        color: #0f131e;
      }
      p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        text-align: center;
        color: #0f131e;
        justify-self: flex-end;
      }
    }
  }
}

@media (max-width: 991px) {
  .reasons {
    &__grid {
      grid-template-columns: repeat(
        auto-fit,
        minmax(280px, 1fr)
      );
    }
  }
}

@media screen and (max-width: 786px) {
  .reasons {
    display: none;
  }
}

@media screen and (max-width: 576px) {
  .reasons {
    margin-top: 60px;
    margin-bottom: 50px;
    &__grid {
      margin-top: 40px;
      grid-template-columns: repeat(
        auto-fit,
        minmax(120px, 1fr)
      );
      .tile {
        gap: 8px;
        h4 {
          font-size: 14px;
        }
        img {
          width: 40px;
          min-width: 40px;
          height: 40px;
        }
      }
    }
  }
}

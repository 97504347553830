.subheader{
  display: flex;
  align-items: center;
  > div {
    &:nth-child(2){
      margin: 0 16px;
    }
  }
}
@media screen and (max-width:660px){
  .subheader{
    display: none;
  }
}
.logout {
  cursor: pointer;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  color: #000;
  transition: all 200ms ease;
  span {
    display: none;
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
  }
}

@media screen and (max-width: 840px) {
  .logout {
    padding-inline: 20px;
    margin-bottom: 8px;
    padding-block: 8px;
    color: #fffff8;
    span {
      display: block;
    }
  }
}

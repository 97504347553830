@import '../../styling/variables.scss';

.title{
  margin: 0;
  position: relative;
  padding-left: 10px;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #0F131E;
  font-family: 'Montserrat', sans-serif;
  &::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background: $green;
  }
}
.inactive{
  color: #B3B3B3;
  &::before{
    content: none;
  }
}
@import '../../styling/variables.scss';

.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plants {
  display: flex;
  gap: 24px;
  justify-content: space-between;
  margin-top: 50px;
  width: 100%;
  & > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
    h2 {
      font-family: "Roboto";
      font-size: 40px;
      font-weight: 500;
      line-height: 38.4px;
      // text-align: center;
      color: #509c5a;
    }
    & > div {
      display: flex;
      gap: 10px;
      // flex-direction: column;
      // align-items: center;
      ul {
        flex: 1;
        display: flex;
        flex-direction: column;
        list-style-type: none;
        margin: 0;
        padding: 0;
        // width: 100%;
        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 8px 16px;
          font-family: "Roboto";
          font-size: 14px;
          font-weight: 500;
          line-height: 15.6px;
          text-align: left;
          color: #0f131e;
          &:nth-child(even) {
            background: #f8f8f8;
          }
        }
      }
    }
  }
  &__left {
    flex: 0.75;
  }
  &__right {
    flex: 1.25;
    img {
      opacity: 0.5;
    }
    &_table {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
    &_row {
      display: flex;
      gap: 10px;
      padding: 8px 16px;
      font-size: 14px;
      & > p{
        flex: 1;
        font-weight: 500;
        &:not(:first-child){
          text-align: center;
        }
      }
      &:nth-child(even) {
        background: #f8f8f8;
      }
    }
  }
}
.noplants {
  width: 269px;
  height: 309px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 50px;
  &__plant {
    width: 200px;
  }

  &__text {
    text-align: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
  }
  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 32px;
    height: 39px;
    width: 244px;
    min-height: 39px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 110%;
    transition: 0.2s ease-in-out;
    background: $green;
    border: 1px solid $green;
    text-align: center;
    color: #ffffff;
    &:hover {
      transition: 0.2s ease-in-out;
      background: #ffffff;
      color: $green;
    }
  }
}

@media screen and (max-width: 1280px) {
  .plants {
    margin-top: 32px;
    flex-direction: column;
    gap: 24px;
  }
}
@media screen and (max-width: 768px) {
  
  .plants {
    & > div {
      h2 {
        font-size: 32px;
      }
      & > div {
        ul {
          li {
            font-size: 16px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 575px) {
 
  .plants {
    & > div {
      h2 {
        text-align: center;
      }
      & > div {
        flex-direction: column;
        align-items: center;
        ul {
          width: 100%;
          li {
            font-size: 16px;
          }
        }
      }
    }
    &__right{
      &_table{
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 315px) {
  .noplants {
    width: 100%;
    &__button {
      width: 100%;
    }
  }
}

/** @format */

@import "../../../../styling/variables.scss";

.insured {
  margin-top: 67px;
  // padding-block: 2rem;
  // background: #f8f8f8;
  &__inner {
    display: flex;
    gap: 20px;
    justify-content: space-between;
  }
  h2 {
    line-height: 110%;
    letter-spacing: -0.03em;
    color: #0f131e;
    margin-bottom: 1rem;
    text-align: left;
  }
  &__text {
    background-color: $green;
    margin-bottom: 55px;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    padding: 4px 14px;
    border-radius: 5px;
    width: max-content;
    text-align: left;
    // margin-inline: auto;
  }
  &__left {
    flex: 0.75;
    display: flex;
    flex-direction: column;
    gap: 24px;
    &_token {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      position: relative;
      div {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        position: absolute;
        right: 15%;
        a {
          justify-content: center;
        }
      }
      picture{
        display: flex;
        justify-content: center;
      }
      img {
        width: 75%;
        // margin-left: 30px;
        // margin-inline: auto;
      }
    }
    &_price {
      font-size: 18px !important;
      border: 1px solid #509c5b;
      border-radius: 10px;
      padding: 0.5rem;
      width: max-content;
    }
  }
  &__button{
    display: flex;
    justify-content: center;
    // max-width: 400px;
    a{
      font-size: 18px;
      padding-block: 10px;
      height: auto;
    }
  }
  &__lawpartners {
    display: flex;
    align-items: center;
    justify-content: space-between;
    div {
      flex: 1;
      width: 100%;
      display: flex;
      picture {
        display: flex;
        align-items: center;
        & > img {
          width: 200px;
        }
      }
    }
    h3 {
      font-family: "Montserrat";
      line-height: 110%;
      letter-spacing: -0.03em;
      color: #0f131e;
      font-size: 0.75rem;
      font-weight: 500;
      // width: 45%;
    }
  }
  &__right {
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex: 0.75;
    h6{
      font-size: 24px;
      margin-bottom: 10px;
    }
    p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      width: 85%;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      color: #0f131e;
    }
    & > div {
      display: flex;
      flex-direction: column;
      gap: 10px;

      div {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
    &_list > p {
      color: $darkGray;
      font-size: 18px;
    }
  }
}

.one {
  h2 {
    font-size: 36px;
  }
  p {
    width: 100%;
  }
}
.two {
  h2 {
    font-size: 36px;
  }
  .insured__right_list {
    // margin-bottom: 20px;
    p {
      width: 100%;
      margin: 0.4rem 0;
    }
  }
}
.mobile {
  display: none;
}
@media (max-width: 1200px) {
  .insured {
    &__inner {
      gap: 40px;
    }
    &__right {
      flex: 1;
      p {
        width: 100%;
      }
      &_token {
        margin-top: 59px;
      }
    }
    &__left {
      &_token {
        div {
          right: 0;
        }
        img {
          margin-top: 20px;
          width: 85%;
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .insured {
    &__inner {
      flex-direction: column;
    }
    &__left {
      &_token {
        div {
          right: 15%;
        }
        img {
          margin-top: 0;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .insured {
    &__inner {
      flex-direction: column;
    }
    &__left {
      display: flex;
      justify-content: center;
      &_token {
        img {
          margin-left: 0;
        }
      }
    }
  }
}
@media screen and (max-width: 576px) {
  .insured {
    margin-top: 40px;
    &__text {
      width: 100%;
    }
    &__left {
      &_token {
        div {
          display: none;
        }
        picture {
          width: 100%;
        }
        img {
          width: 100%;
        }
      }
      &_price {
        text-align: center;
      }
    }
    &__lawpartners {
      h3 {
        font-size: 18px;
      }
      div {
        picture {
          flex: 1;
          img {
            width: 100%;
          }
        }
      }
    }
  }
  .desktop {
    display: none;
  }
  .mobile {
    display: flex;
  }
}
@media screen and (max-width: 425px) {
  .insured {
    &__lawpartners {
      h3 {
        font-size: 16px;
      }
    }
  }
}

@import '../../styling/variables.scss';

.verify{
  padding: 146px 0 40px 0; 
  min-height: 512px;
  height: calc(100vh - 249px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__noty{
    margin-bottom: 30px;
  }
  &__info{
    max-width: 529px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  &__circle{
    min-width: 125px;
    width: 125px;
    height: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border-radius: 50%;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.25);
    position: relative;
    z-index: 2;
    img{
      position: relative;
      z-index: 2;
    }
  }
  &__image{
    position: absolute;
    top: -106px;
    left: 50%;
    transform: translateX(-50%);
    animation: show 1s ease-in-out;
  }
  &__title{
    margin: 12px 0 50px 0;
    font-weight: 600;
    font-size: 42px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #0F131E;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    z-index: 2;
    position: relative;
  }
}

@keyframes show {
  0%    { opacity: 0; }
  100%  { opacity: 1; }
}

@media screen and (max-width: 425px){
  .verify{
    justify-content: flex-start;
    &__title{
      font-size: 32px;
      margin-top: 40px;
    }
    &__image{
      width: 150px;
      top: -56px;
    }
    &__circle{
      min-width: 65px;
      width: 65px;
      height: 65px;
      padding: 6px;
    }
    a{
      width: 100%;
      justify-content: center;
    }
  }
}

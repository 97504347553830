/** @format */

.locations {
  padding-block: 1rem;
  padding-top: 67px;
  padding-bottom: 67px;


  background-color: #f8f8f8;
  h2 {
    text-align: center;
    margin-bottom: 2rem;
  }
  &__inner {
    display: flex;
    width: 100%;
    gap: 1rem;
  }
}

.location {
  flex: 1;
  display: flex;
  gap: 1rem;
  picture {
    flex: 1.5;
    img {
      width: 100%;
    }
  }
  &__body {
    flex: 1;
  }
  h3 {
    text-align: left;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 0.5rem;
    &:nth-child(2) {
      display: none;
    }
  }
  p {
    color: #a1a1a1;
    font-size: 16px;
    margin-bottom: 0.5rem;
  }
}

@media screen and (max-width: 991px) {
  .locations {
    &__inner {
      flex-direction: column;
    }
  }
  .article {
    h3 {
      height: auto;
    }
    &__body {
      img {
        width: 40%;
        min-height: auto;
      }
    }
  }
}

@media screen and (max-width: 780px) {
  .location {
    img {
      min-height: 160px;
    }
  }
  .location:nth-child(1) {
    .location__body {
      h3 {
        &:nth-child(2) {
          display: block;
        }
        &:nth-child(1) {
          display: none;
        }
      }
    }
  }

  .location__body {
    p {
      span {
        display: none;
      }
    }
  }
}
@media screen and (max-width: 576px) {
  .locations {
    margin-top: 0;
    padding-block: 40px;
    background: #f8f8f8;
  }
  .location{
    picture{
      img{
        object-fit: cover;
      }
    }
  }
}
@media screen and (max-width: 340px) {
  .location {
    img {
      // min-height: 185px;
    }
  }
}
@media screen {
  .email {
    display: none;
  }
}

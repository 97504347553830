/** @format */

.item {
  position: relative;
  padding: 16px;

  &__icon {
    display: flex;
    width: 288px;
    height: 274px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex-shrink: 0;
  }
  &__discount {
    display: flex;
    padding: 6px 10px;
    align-items: center;
    gap: 10px;
    position: absolute;
    top: 33px;
    background: var(--Main-Red, #ca4b15);
    color: var(--Main-White, #fff);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
    letter-spacing: -0.48px;
  }
  h3 {
    color: var(--Main-Black-green, #0f131e);

    /* H5 */
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 23.4px */
    align-self: stretch;
  }
  &__body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    div {
      margin-top: 10px;

      border-left: 4px solid
        var(--Main-Red, #ca4b15);
      display: flex;
      padding: 0px 10px;
      flex-direction: column;
      align-items: flex-start;
      flex: 1 0 0;
      p {
        color: var(
          --Grey-black-80,
          rgba(60, 60, 60, 0.8)
        );
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 16.8px */
      }
      h6 {
        color: var(
          --Grey-black-80,
          rgba(60, 60, 60, 0.8)
        );
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 24px */
        letter-spacing: -0.6px;
        & span:first-child {
          text-decoration-line: line-through;
          margin-right: 10px;
        }
        & span:nth-child(2) {
          color: var(--Main-Red, #ca4b15);
          font-family: Roboto;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 24px */
          letter-spacing: -0.6px;
        }
      }
    }
    a.disabled {
      display: flex;
      width: 120px;
      height: 38px;
      padding: 12px 0px;
      justify-content: center;
      text-decoration: none;
      align-items: center;
      gap: 10px;
      border-radius: 4px;
      border: 1.2px solid
        var(--Main-Green, #509c5a);
      background: none;
      color: var(--Main-Black-green, #0f131e);
      font-family: Roboto;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%; /* 14.3px */
    }
    a.active {
      text-decoration: none;

      background: none;
      cursor: pointer;

      border: none;
      display: flex;
      width: 120px;
      padding: 12px 0px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 4px;
      background: var(--Main-Green, #509c5a);
      color: var(--Main-White, #fff);
      font-family: Roboto;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%; /* 14.3px */
    }
  }
}

@import "../../../../styling/variables.scss";

.link {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  text-decoration: none;
  color: rgba(60, 60, 60, 0.8);
  &__text {
    margin-left: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    transition: 0.2s ease-in-out;
  }
  &:hover {
    .link__text {
      color: #ffffff;
    }
    svg path {
      transition: 0.2s ease-in-out;
      stroke: #ffffff;
    }
  }
  svg {
    min-width: 24px;
    width: 24px;
    height: 24px;
    transition: 0.2s ease-in-out;
  }
  &__active {
    .link__text {
      color: #ffffff;
      font-weight: 500;
      transition: 0.2s ease-in-out;
    }
  }
  &__activeMob {
    .link__text {
      color: #ffffff;
      font-weight: 500;
      transition: 0.2s ease-in-out;
    }
    svg path {
      transition: 0.2s ease-in-out;
      stroke: #0f131e;
    }
    border-bottom: 3px solid $green;
  }
  &__admin {
    &:hover {
      .link__text {
        color: #0f131e;
      }
      svg path {
        transition: 0.2s ease-in-out;
        stroke: #0f131e;
      }
    }
  }
}

.link__admin.link__active {
  .link__text {
    color: #0f131e;
  }
}

.link.link_disabled {
  cursor: default;
  &:hover {
    .link__text {
      color: rgba(60, 60, 60, 0.249);
    }
    svg path {
      transition: 0.2s ease-in-out;
      stroke: rgba(60, 60, 60, 0.249);
    }
  }
}

@media screen and (max-width: 840px) {
  .link {
    &:hover {
      svg {
        path {
          stroke: #0f131e;
        }
      }
    }
    &__activeMob {
      &:hover {
        svg {
          path {
            stroke: #0f131e;
          }
        }
      }
    }
  }
}

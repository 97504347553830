@import "../../styling/variables.scss";

.register {
  padding: 62px 0;
  min-height: 760px;
  position: relative;
  overflow: hidden;
  &__country {
    width: calc(50% - 6px);
    span {
      color: #0f131e;
    }
  }
  &__modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(60, 60, 60, 0.05);
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 8;
    &_inner {
      max-width: 451px;
      width: 100%;
      padding: 40px 31px;
      position: relative;
      background: #ffffff;
      border: 1px solid #f8f8f8;
      box-shadow: 0px 10px 20px -3px rgba(60, 60, 60, 0.6);
      border-radius: 2px;
    }
    &_close {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      background: none;
      outline: none;
      border: none;
    }
    &_title {
      font-weight: 700;
      font-size: 18px;
      line-height: 130%;
      text-align: center;
      color: #0f131e;
      margin-bottom: 24px;
    }
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__left {
    width: 50%;
  }
  &__right {
    width: 457px;
    height: 392px;
    background: $green;
    position: relative;
    margin-top: 75px;
    img {
      position: absolute;
      left: 50%;
      top: -67px;
      transform: translateX(-50%);
    }
  }
  &__title {
    margin: 0;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 42px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #0f131e;
  }
  &__info {
    margin-top: 8px;
    display: flex;
    align-items: center;
    div {
      font-size: 20px;
      line-height: 130%;
      letter-spacing: -0.02em;
      color: #0f131e;
      margin-right: 10px;
    }
    a {
      font-weight: 600;
      font-size: 20px;
      line-height: 130%;
      letter-spacing: -0.02em;
      text-decoration-line: underline;
      color: #509c5b;
    }
  }
  &__form {
    margin-top: 32px;
    &_full_wrapper {
      margin-bottom: 12px;
    }
    &_wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
      label {
        width: calc(50% - 6px);
      }
      &__phone {
        width: calc(50% - 6px);
        position: relative;
        > label {
          width: 100%;
          > input {
            padding-left: 80px;
          }
        }
      }
      &__country {
        position: absolute;
        top: 43px;
        left: 20px;
      }
      &__gender {
        margin-left: 17px;
        > div {
          display: flex;
          align-items: center;
          margin-top: 12px;
          > label:first-child {
            margin-right: 25px;
          }
          > label {
            padding-left: 25px;
          }
        }
      }
    }
    &_referal {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-bottom: 12px;
    }
    &_btnRef {
      opacity: 0;
      pointer-events: none;
      display: none;
      margin-left: 12px;
      cursor: pointer;
      white-space: nowrap;
      background: none;
      border: none;
      color: $green;
      text-decoration: underline;
      margin-bottom: 16px;
    }
    &_check {
      margin: 0 0 24px 0;
      span {
        a {
          color: #509c5b;
        }
      }
    }
    &_errors {
      margin-top: 24px;
    }
    &_error {
      font-size: 14px;
      color: red;
      text-transform: capitalize;
    }
    &_dob {
      width: calc(50% - 6px);
      &_label {
        padding-left: 16px;
        font-size: 16px;
        line-height: 130%;
        color: #0f131e;
      }
      label {
        margin-top: 8px;
        width: 100%;
        gap: 0px;
        input {
          width: 100%;
          max-width: 100%;
          background: #f8f8f8;
          border: none;
          border-bottom: 2px solid #509c5b;
          &::-webkit-input-placeholder {
            color: #b3b3b3;
          }
          &::-moz-placeholder {
            color: #b3b3b3;
          }
          &:-ms-input-placeholder {
            color: #b3b3b3;
          }
          &:-moz-placeholder {
            color: #b3b3b3;
          }
        }
      }
      &_error {
        input {
          &::-webkit-input-placeholder {
            color: #ca4b15 !important;
          }
          &::-moz-placeholder {
            color: #ca4b15 !important;
          }
          &:-ms-input-placeholder {
            color: #ca4b15 !important;
          }
          &:-moz-placeholder {
            color: #ca4b15 !important;
          }
        }
      }
    }
  }
  &__dob {
    display: flex;
    align-items: center;
    justify-content: space-between;
    input {
      width: 35%;
      height: 45px;
      border: none;
      outline: none;
      background: #f8f8f8;
      border-bottom: 2px solid #509c5a;
      color: #0f131e;
      padding: 0 16px;
      font-size: 16px;
      line-height: 130%;
      outline-width: 0px;
      outline-color: transparent;
      transition: 0.2s ease-in-out;
      &:first-child {
        width: 24%;
      }
      &::-webkit-input-placeholder {
        color: #b3b3b3;
      }
      &::-moz-placeholder {
        color: #b3b3b3;
      }
      &:-ms-input-placeholder {
        color: #b3b3b3;
      }
      &:-moz-placeholder {
        color: #b3b3b3;
      }
    }
    &_label {
      padding-left: 16px;
      font-size: 16px;
      line-height: 130%;
      color: #0f131e;
      margin-bottom: 8px;
    }
    &_error {
      input {
        border-color: #ca4b15;
        transition: 0.2s ease-in-out;
      }
    }
  }
  &__sale {
    &_title {
      color: #0f131e;
      font-size: 20px;
      letter-spacing: -0.02em;
      line-height: 130%;
      margin-right: 10px;
    }
  }
}
.dob_error {
  margin-top: 2px;
  padding-left: 16px;
  display: flex;
  align-items: center;
  svg {
    min-width: 12px;
    width: 12px;
  }
  div {
    font-weight: 500;
    font-size: 11px;
    line-height: 130%;
    color: #ca4b15;
    margin: 2px 0 0 4px;
  }
}
.check {
  padding: 53px 0;
  height: 500px;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__title {
    font-family: "Montserrat", sans-serif;
    margin: 4px 0 20px 0;
    font-weight: 600;
    font-size: 42px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #0f131e;
    text-align: center;
    max-width: 529px;
  }
  &__descr {
    font-size: 20px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #0f131e;
    text-align: center;
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 991px) {
  .register {
    height: auto;
    &__wrapper {
      flex-direction: column-reverse;
      align-items: center;
    }
    &__left {
      width: 100%;
      margin-top: 56px;
    }
    &__right {
      width: 327px;
      height: 322px;
      img {
        width: 90%;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .register {
    padding-top: 36px;

    &__right {
      width: 327px;
      height: 282px;
      img {
        width: 80%;
      }
    }
  }
}
@media screen and (max-width: 576px) {
  .register {
    &__form {
      &_dob {
        width: 100%;
      }
      &_wrapper {
        &_data {
          flex-direction: column;
          > div {
            &:last-child {
              margin-top: 12px;
              width: 100%;
            }
          }
        }
      }
    }
    &__country {
      width: 100%;
    }
    &__right {
      width: 248px;
      height: 213px;
      img {
        width: 80%;
        top: -47px;
      }
    }
    &__title {
      font-size: 32px;
    }
    &__info {
      div,
      a {
        font-size: 16px;
      }
    }
    &__form {
      a {
        width: 100%;
        justify-content: center;
      }
    }
  }
}

@media screen and (max-width: 475px) {
  .register {
    &__form {
      & > div {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        label {
          width: 100%;
        }
      }
      &_btnRef {
        margin-left: 0;
      }
      &_wrapper {
        &__phone {
          width: 100%;
        }
        &_data {
          > div {
            &:last-child {
              margin-top: 0px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .check {
    padding-inline: 10px;
    justify-content: flex-start;
    height: auto;
    &__title {
      font-size: 32px;
    }
    &__descr {
      font-size: 16px;
    }
    a {
      width: 100%;
      justify-content: center;
    }
  }
}

@media screen and (max-width: 375px) {
  .register {
    &__form {
      a {
        width: 100%;
        justify-content: center;
      }
    }
  }
}

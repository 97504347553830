@import '../../styling/variables.scss';

.register {
  &__modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(60, 60, 60, 0.05);
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 8;
    &_inner {
      max-width: 451px;
      width: 100%;
      padding: 40px 31px;
      height: 397px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      background: #ffffff;
      border: 1px solid #f8f8f8;
      box-shadow: 0px 10px 20px -3px rgba(60, 60, 60, 0.6);
      border-radius: 2px;

      &_close {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        background: none;
        outline: none;
        border: none;
      }
    }

    &_title {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 130%;
      display: flex;
      align-items: center;
      text-align: center;
    }
  }
}

.check {
  display: flex;
  gap: 30px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/** @format */

.investing {
  margin-top: 67px;
  padding-bottom: 2rem;
  h2 {
    text-align: center;
    margin-bottom: 24px;
  }
  p {
    background-color: #509c5b;
    margin-bottom: 15px;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    padding: 4px 14px;
    border-radius: 5px;
    width: max-content;
    text-align: center;
    margin-inline: auto;
  }
  &__desc {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 130%;
    text-align: center;
    color: #0f131e;
    width: 85%;
    margin: 0 auto;
  }
  &__inner {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
    column-gap: 20px;
    margin-top: 20px;
    row-gap: 20px;
  }
  &__tile {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    & > div {
      width: 150px;
      height: 140px;
      background-color: #e8eecd;
      border-bottom: 10px solid #509c5b;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    h5 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 130%;
      color: #000000;
      width: 60%;
      text-align: center;
    }
    p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      color: #000000;
    }
  }
  &__video {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: transparent;
      border: none;
      cursor: pointer;
      svg {
        width: 64px;
        height: 64px;
      }
    }
    picture {
      width: 100%;
      
      display: flex;
      img {
        width: 100%;
        max-height: 320px;
        object-fit: contain;
      }
    }
  }
}

.modal {
  max-width: 1104px;
  width: 100%;
  position: fixed;
  top: 75px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: flex-start;
  z-index: 3;
  video {
    width: 100%;
    border-radius: 6px;
    box-shadow: 0px 4px 20px -4px rgba(0, 0, 0, 0.5);
  }
  button {
    background: #509c5b;
    width: 34px;
    min-width: 34px;
    // margin-left: 16px;
    height: 34px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 15px;
    img {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1200px) {
  .modal {
    max-width: 90%;
  }
}

@media screen and (max-width: 830px) {
  .modal {
    max-width: 704px;
  }
  .investing {
    p {
      width: auto;
    }
  }
}

@media screen and (max-width: 768px) {
  .investing {
    padding-top: 0;
    h2 {
      margin-bottom: 0px;
      line-height: 31px;
    }

    &__video {
      margin-top: 5px;
    }
    &__tile {
      & > div {
        margin: 0 auto;
      }
    }

    p {
      display: none;
    }
  }
  .modal {
    max-width: 90%;
  }
}

@media screen and (max-width: 576px) {
  .investing {
    margin-top: 40px; /* remove if neccesary */
    padding-bottom: 40px;
    h2 {
      text-align: center;
    }
    &__tile {
      & > div {
        margin: 0 auto;
        width: 80px;
        height: 80px;
        img {
          width: 50%;
        }
      }
      h5 {
        font-size: 14px;
      }
    }
    &__desc {
      font-weight: 600;
      font-size: 16px;
      line-height: 120%;
      letter-spacing: -0.03em;
    }
    &__video {
      width: 90%;
      margin: 20px auto 0 auto;
      img {
        width: 100%;
      }
    }
    &__inner {
      margin-top: 0;
    }
  }
}

@media screen and (max-width: 370px) {
  .investing {
    &__inner {
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }
  }
}

.table {
  &__header {
    display: flex;
    align-items: center;
    padding-inline: 8px;
    padding-block: 16px;
    & > div {
      font-weight: 500;
      font-size: 14px;
      line-height: 110%;
      color: rgba(60, 60, 60, 0.8);
    }
  }
  &__order {
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 0.5;
    svg {
      margin-right: 6px;
      min-width: 12px;
    }
  }
  &__date {
    display: flex;
    align-items: center;
    flex:1;
  }
  &__name {
    display: flex;
    align-items: center;
    flex: 1;
  }
  &__token {
    display: flex;
    align-items: center;
    flex: 0.5;
  }
  &__status {
    display: flex;
    align-items: center;
    flex: 1;
  }
  &__amount {
    display: flex;
    align-items: center;
    flex: 1;
  }
}
.table__overflow {
  overflow: auto;
}

.check{
  height: calc(100vh - 249px);
  min-height: 560px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__title{
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 42px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #0F131E;
    text-align: center;
  }
  &__subtitle{
    margin: 20px 0 20px 0;
    font-size: 20px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #0F131E;
  }
  &__wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 529px;
    margin: 0 auto;
  }
}
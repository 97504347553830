.dashboard {
  padding: 0 23px 84px 20px;
  height: calc(100vh - 200px);
  overflow-y: scroll;
  &__dect {
    display: none;
    margin-top: 10px;
    p {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 8px;
      border-top: 1px solid #d7d3c1;
      padding-top: 10px;
    }
  }
  &__referral {
    display: none;
    padding-top: 10px;
    p {
      font-family: Roboto;
      font-size: 15px;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 8px;
      color: rgba(15, 19, 30, 1);
      border-top: 1px solid #d7d3c1;
      padding-top: 10px;
    }
  }
  &__warning {
    margin-bottom: 40px;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #509c5a;
    border-left: 2px solid #c5d483;
    color: #fafafa;
    p {
      font-family: "Roboto";
      font-size: 16px;
      font-weight: 500;
      line-height: 20.8px;
      color: #fafafa;
    }
  }
  &__startnow {
    margin-top: 16px;
    display: flex;
    & > div {
      flex: 1;
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      &:first-child {
        background: #d7d3c1;
      }
      &:nth-child(2) {
        background: #e0ded1;
      }
      &:last-child {
        background: #e9e7e0;
      }
      & > div {
        font-family: "Roboto";
        font-size: 42px;
        font-weight: 600;
        line-height: 50.4px;
        letter-spacing: -0.03em;
        color: #0f131e;
        display: flex;
        align-items: center;
        gap: 8px;
      }
      & > p {
        font-family: "Montserrat";
        font-size: 18px;
        font-weight: 600;
        line-height: 23.4px;
        color: #0f131e;
        width: 90%;
      }
    }
    &_title{
      margin-top: 50px;
    }
  }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(3px);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  &__inner {
    max-width: 500px;
    width: 500px;
    border-radius: 8px;
    position: relative;
    padding: 30px 30px 50px 30px;
    background: #ffffff;
    &_close {
      cursor: pointer;
      border: none;
      outline: none;
      background: none;
      position: absolute;
      top: 15px;
      right: 15px;
    }
    &_wrapper {
      position: relative;
      display: flex;
      align-items: center;
      margin-top: 16px;
      border-bottom: 2px solid #509c5a;
      background: #f8f8f8;
    }
    &_title {
      font-family: "Montserrat", sans-serif;
      color: #0f131e;
      text-align: center;
      font-size: 24px;
      font-weight: 700;
      line-height: 130%;
    }
    &_code {
      padding: 6px 10px;
      color: #0f131e;
      font-size: 16px;
      line-height: 130%;
      text-align: center;
      border-radius: 8px;
      width: 100%;
      word-break: break-all;
    }
    &_helper{
      font-size: 12px;
      text-align: left;
      margin-top: 32px;
      opacity: 0.8;
    }
  }
  &__copy {
    cursor: pointer;
    border: none;
    outline: none;
    min-width: 33px;
    height: 31px;
    background: transparent;
    position: relative;
    &_state {
      text-align: center;
      pointer-events: none;
      opacity: 0;
      z-index: -1;
      transition: 0.2s ease-in-out;
      &_active {
        opacity: 1;
        z-index: 1;
        color: #509c5b;
        transition: 0.2s ease-in-out;
      }
    }
    img {
      width: 21px;
      height: 21px;
    }
  }
  &__tip {
    padding: 20px 16px;
    border-left: 2px solid #fa8a07;
    background: #f8e9b6;
    display: flex;
    align-items: center;
    margin-top: 8px;
    svg {
      min-width: 21px;
      height: 21px;
    }
    div {
      margin-left: 12px;
      color: #fa8a07;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.2px;
    }
  }
  &__btn {
    border-radius: 4px;
    background: #509c5a;
    max-width: 386px;
    margin: 16px auto 0 auto;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 110%;
    padding: 12px 12px;
    text-align: center;
    border: 1px solid #509c5a;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover {
      color: #509c5a;
      background: #fff;
      transition: 0.2s ease-in-out;
    }
  }
}
.newsWebcam {
  display: flex;
  flex-direction: column;
}
.mobile {
  display: none;
}
@media screen and (max-width: 1065px) {
  .dashboard {
    &__subheader {
      div {
        div {
          font-size: 16px;
        }
      }
    }
  }
}
@media screen and (max-width: 1050px) {
  .dashboard {
    height: auto;
  }
}
@media screen and (max-width: 964px) {
  .dashboard {
    &__empty {
      flex-direction: column;
      // align-items: center;
    }
  }
}
@media screen and (max-width: 875px) {
  .dashboard {
    &__subheader {
      flex-direction: column;
    }
  }
}
@media screen and (max-width: 840px) {
  .dashboard {
    height: auto;
    padding-bottom: 24px;
    &__dect {
      display: block;
    }
    &__referral {
      display: block;
    }
  }
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .dashboard {
    &__startnow {
      & > div > div {
        font-size: 25px;
        & > img {
          height: 28px;
          width: 28px;
        }
      }
      & > div > p {
        font-size: 16px;
      }
      & > div {
        gap: 0px;
        padding: 15px;
      }
      flex-direction: column;
    }
  }
}
@media screen and (max-width: 610px) {
  .dashboard {
    &__warning {
      p {
        font-size: 14px;
        line-height: 1.25;
      }
    }
  }
}
@media screen and (max-width: 575px) {
  .dashboard {
    padding-inline: 10px;
    &__header {
      padding-inline: 10px;
      flex-direction: column;
      gap: 0.5rem;
    }
    &__subheader {
      padding-inline: 10px;
      gap: 8px;
      margin-top: 16px;
      & > div {
        &:last-child {
          display: none;
        }
      }
    }
    &__dect {
      padding-inline: 10px;
      a {
        width: 100%;
        justify-content: center;
      }
    }
    &__referral {
      padding-inline: 10px;
      a {
        width: 100%;
        justify-content: center;
      }
    }
    &__empty {
      align-items: center;
      &_right {
        width: 100%;
        h5 {
          text-align: center;
        }
        &_inner {
          flex-direction: column;
          text-align: center;
          gap: 1rem;
          width: 100%;
          div {
            p {
              padding-inline: 4px;
              span {
                text-align: left;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
  .newsWebcam {
    flex-direction: column-reverse;
  }
}
@media screen and (max-width: 540px) {
  .modal {
    &__inner {
      max-width: 90%;
      width: 100%;
    }
    &__tip {
      line-height: 120%;
      padding: 12px 9px;
      div {
        font-size: 14px;
      }
    }
    &__inner {
      &_code {
        width: calc(100% - 33px);
      }
    }
    &__copy {
      min-width: 33px;
    }
  }
}

@media screen and (max-width: 400px) {
  .modal {
    &__inner {
      padding: 20px 15px 30px 15px;
      &_code {
        word-break: break-all;
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .dashboard {
    &__empty {
      &_right {
        &_inner {
          div {
            p {
              span {
                // flex: 1;
              }
            }
          }
        }
      }
    }
  }
}

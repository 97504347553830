.partners {
  margin-top: 67px;
  h2 {
    text-align: center;
  }
  &__wrapper {
    border: 2px solid #509c5b;
    border-radius: 14px;
    padding: 1.5rem;
    margin: 0 auto;
    img {
      max-width: 200px;
    }
  }
  &__partners {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px auto 0 auto;
  }
  &__info {
    margin-top: 19px;
    &_title {
      color: rgba(60, 60, 60, 0.8);
      font-size: 13px;
      font-weight: 500;
      line-height: 120%;
    }
    &_subtitle {
      color: #0f131e;
      font-size: 13px;
      font-weight: 500;
      line-height: 120%;
      margin: 1px 0 4px 0;
    }
  }
  &__height {
    height: 65px;
  }
}
.carousel {
  margin-top: 38px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__inner {
    display: flex;
    transition: transform 0.5s ease;
    overflow: hidden;
    align-items: center;
    gap: 84px;
    width: 625px;
    z-index: 0;
  }
  &__logo {
    transition: opacity 0.5s ease;
    img {
      max-width: 240px;
    }
  }
  &__controls {
    button {
      padding: 10px;
      background: #d7d3c1;
      border: none;
      z-index: 10;
      cursor: pointer;
      svg {
        width: 16px;
        height: 10px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .partners {
    &__wrapper {
      max-width: 900px;
    }
  }
}

@media screen and (max-width: 991px) {
  .partners {
    &__partners {
      flex-wrap: wrap;
      justify-content: space-between;
      div {
        flex: 1;
        img {
          max-width: 80%;
        }
      }
    }
    &__info {
      width: 100% !important;
      div {
        width: 100%;
      }
    }
    &__height {
      height: 46px;
    }
  }
}

@media screen and (max-width: 768px) {
  .partners {
    &__partners {
      flex-direction: column;
      justify-content: center;
      gap: 2rem;
      div {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 208px;
        }
      }
    }
    &__height {
      height: 70px;
      &_ls {
        height: 50px;
      }
    }
  }
  .carousel {
    &__inner {
      gap: 54px;
      width: 100%;
    }
    &__logo {
      img {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .partners {
    margin-bottom: 50px;
    &__info {
      padding-left: 20px;
    }
  }
  .carousel {
    &__inner {
      gap: 16px;
      justify-content: center;
    }
    &__controls {
      display: none;
    }
  }
}

.cookie {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: rgba(60, 60, 60, 0.05);
  backdrop-filter: blur(5px);
  &__inner {
    max-width: 389px;
    width: 100%;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.25);
    padding: 30px;
  }
  &__title {
    color: #000;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 130%;
    font-family: "Montserrat", sans-serif;
  }
  &__descr {
    font-family: "Montserrat", sans-serif;
    color: #0f131e;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.48px;
    margin-top: 24px;
    a {
      color: #509c5a;
      margin-left: 6px;
      text-decoration: none;
    }
  }
  &__btns {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      width: calc(50% - 8px);
      height: 39px;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      border-radius: 4px;
      border: none;
      cursor: pointer;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      line-height: 110%;
      transition: 0.2s ease-in-out;
      &:first-child {
        background: #509c5a;
        border: 1px solid #509c5a;
        &:hover {
          background: #fff;
          color: #509c5a;
        }
      }
      &:last-child {
        border: 1px solid #509c5a;
        color: #509c5a;
        background: #fff;
        &:hover {
          background: #509c5a;
          color: #fff;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .cookie {
    &__inner {
      width: 90%;
      padding: 20px;
    }
    &__title {
      font-size: 21px;
    }
    &__descr {
      font-size: 14px;
    }
  }
}

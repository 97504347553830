.check {
  padding: 53px 0;
  height: calc(100vh - 249px);
  min-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__container {
    display: flex;
    &__img1 {
      margin-right: -50px;
    }
    &__img2 {
      margin-top: 70px;
      margin-left: -40px;


    }
  }
  &__email {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 130%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #509c5a;
  }
  &__title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 120%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #0f131e;
    max-width: 754px;
  }
  &__descr {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    color: #0f131e;
    max-width: 754px;
    margin-bottom: 20px;

  }
}

@media screen and (max-width: 425px) {
  .check {
    padding-inline: 10px;
    justify-content: flex-start;
    height: auto;
    &__title {
      font-size: 32px;
    }
    &__descr {
      font-size: 16px;
    }
    a {
      width: 100%;
      justify-content: center;
    }
  }
}

/** @format */

.marketplace {
  padding: 15px 20px;
  height: calc(100vh - 200px);
  overflow-y: scroll;
  h2 {
    margin-bottom: 12px;
  }

  &__container {
    height: 505px;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 4px;
    border: 1px solid #c5d483;

    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.2) 100%
      ),
      url('../../../../img/marketplace.jpeg')
        lightgray 50% / cover no-repeat;

    // background-size: cover;
    // background-position: center;
    // background-repeat: no-repeat;
  }
}

@media screen and (max-width: 768px) {
  .marketplace {
    h2 {
      margin-left: 0;
    }
  }
}

@media screen and (max-width: 500px) {
  .marketplace {

  &__container {
    height: 650px;
  }}
}

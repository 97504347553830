.counter {
  width: 100%;
  text-align: center;
  div {
    margin-bottom: 0.5rem;
    color: #509c5b;
    span {
      font-size: 2rem;
      font-family: "Montserrat";
      font-weight: bold;
    }
  }
  & > span {
    font-size: 1rem;
    font-family: "Roboto", sans-serif;
    color: black;
  }
}

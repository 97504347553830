.exhibitors {
  padding-top: 67px;
  padding-bottom: 67px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("../../../../img/exhibitors.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &__inner {
    min-height: 500px;
    display: grid;
    align-items: start;
    justify-content: end;
  }
  h2 {
    color: #fff;
    max-width: 650px;
    // text-align: center;
  }
  &__subtitle {
    background-color: #509c5b;
    margin-bottom: 15px;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    padding: 4px 14px;
    border-radius: 5px;
    width: fit-content;
    text-align: center;
    // margin-inline: auto;
  }
}

@media (max-width: 768px) {
  .exhibitors {
    margin-bottom: 40px;
    &__inner {
      min-height: 370px;
    }
  }
}

@media screen and (max-width: 576px) {
  .exhibitors {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 450px) {
  .exhibitors {
    padding-block: 20px;
    background-position: 40% center;
    &__inner {
      min-height: 350px;
    }
  }
}

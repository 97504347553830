@import "../../../../styling/variables.scss";

.main {
  padding: 0 23px 84px 20px;
  height: calc(100vh - 200px);
  overflow-y: scroll;
  h2 {
    margin-left: 20px;
    font-family: "Roboto";
      font-size: 40px;
      font-weight: 500;
      line-height: 38.4px;
      // text-align: center;
      color: #509c5a;
  }
  &__tabs {
    display: flex;
    justify-content: flex-end;
    &_tab {
      width: 44px;
      min-width: 44px;
      padding: 7px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      position: relative;
      outline: none;
      background: none;
      cursor: pointer;
      &::after {
        content: "";
        width: 100%;
        height: 2px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: #fff;
        transition: 0.2s ease-in-out;
      }
      &_active {
        &::after {
          background: $green;
          transition: 0.2s ease-in-out;
        }
      }
    }
  }
  &__noplants_container {
    width: 100%;
    // height: 100%;
    display: flex;
    align-items: center;
  }
  &__wrapper {
    display: flex;
    margin-top: 11px;
    flex-direction: column;
    height: auto;
    padding: 0 5px 40px 0;
    overflow: auto;
    position: relative;
    transition: 0.2s ease-in-out;
    &_blocks {
      gap: 30px;
      // flex-direction: row;
      // flex-wrap: wrap;
      // justify-content: space-between;
      display: grid;
      transition: 0.2s ease-in-out;
      grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    }
    &_blocksMob {
      width: 100%;
      // display: grid;
      // grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
      gap: 10px;
    }
    &::-webkit-scrollbar {
      width: 2px;
      background: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
      width: 4px;
      background: #b3b3b3;
      border-radius: 2px;
    }
  }
  &__statuses {
    margin: 25px 0 0 20px;
    display: flex;
    flex-wrap: wrap;
    max-width: 1500px;
    width: 100%;
  }
  &__status {
    &_row {
      display: flex;
      margin-bottom: 10px;

      width: 504px;
      &_text {
        font-weight: 400;
        font-size: 16px;
        line-height: 110%;
        color: #0f131e;
        margin-left: 20px;
      }
      div:first-child {
        width: 136px;
        min-width: 136px;
        div {
          display: inline;
        }
      }
    }
  }
}

@media screen and (max-width: 1150px) {
  .main {
    &__statuses {
      justify-content: space-between;
    }
    &__status {
      &_row {
        width: calc(50% - 10px);
        &_text {
          font-size: 14px;
        }
        div:first-child {
          width: 120px;
          min-width: 120px;
        }
        &:nth-child(even) {
          margin-left: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 840px) {
  .main {
    height: auto;
    &__tabs {
      display: none;
    }
    &__wrapper {
      // height: calc(100vh - 356px);
    }
  }
}

@media screen and (max-width: 640px) {
  .main {
    h2 {
      margin-left: 0;
    }
    &__statuses {
      flex-direction: column;
      margin-left: 10px;
    }
    &__status {
      &_row {
        width: 100%;
        margin-bottom: 2px;
        align-items: flex-start;
        &_text {
          font-size: 12px;
          color: rgba(60, 60, 60, 0.8);
        }
      }
    }
  }
}
@media screen and (max-width: 475px) {
  .main {
    padding-inline: 10px;
  }
}

@import "../../styling/variables.scss";

.button {
  padding: 0 32px;
  display: inline-flex;
  align-items: center;
  height: 39px;
  min-height: 39px;
  text-align: center;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 110%;
  transition: 0.2s ease-in-out;
  &:hover {
    transition: 0.2s ease-in-out;
  }
  &__disabled {
    pointer-events: none;
    opacity: 0.5;
    transition: 0.2s ease-in-out;
  }
}
.default {
  border: 1px solid #b3b3b3;
  background: #ffffff;
  font-weight: 500;
  font-size: 14px;
  line-height: 110%;
  color: #0f131e;
  &:hover {
    background: #b3b3b3;
    color: #ffffff;
  }
}

.green {
  background: $green;
  border: 1px solid $green;
  color: #ffffff;
  &:hover {
    background: #ffffff;
    color: $green;
  }
}
.black {
  background: $blackGreen;
  border: 1px solid #fff;
  color: #ffffff;
  &:hover {
    background: #ffffff;
    color: $blackGreen;
  }
}

.lightGreen {
  background: #c6eccc;
  color: $green;
  &:hover {
    background: $lightGreen;
    color: #fff;
  }
}

.buy {
  width: 244px;
  background: $green;
  border: 1px solid $green;
  color: #ffffff;
  text-align: center;
  justify-content: center;
  &:hover {
    background: #ffffff;
    color: $green;
  }
}

.checkout {
  margin: 10px auto 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 244px;
  height: 39px;
  min-height: 39px;
  background: #509c5b;
  border-radius: 4px;
  border: 1px solid #509c5b;
  font-weight: 500;
  font-size: 14px;
  line-height: 110%;
  color: #ffffff;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  &:hover {
    background: #ffffff;
    color: #509c5b;
    transition: 0.2s ease-in-out;
  }
}

.darkGreen {
  background: #509c5b;
  border: 1px solid #509c5b;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 110%;
  color: #ffffff;
  width: 199px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px auto 0 auto;
  transition: 0.2s ease-in-out;
  &:hover {
    background: #ffffff;
    color: #509c5b;
    transition: 0.2s ease-in-out;
  }
}

.red {
  background: transparent;
  border: 1px solid #ca4b15;
  color: #ca4b15;
  &:hover {
    background: #ca4b15;
    color: #ffc9b1;
  }
}

.home {
  background: transparent;
  color: #509c5a;
  border: 1px solid #509c5a;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%;
  gap: 10px;
  padding-inline: 16px;
  width: 100%;
  justify-content: center;
}

.dect {
  background: #d7d3c1;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #0F131E;
  padding-inline: 10px;
}
.referral {
  background: rgba(197, 212, 131, 1);
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  color: #0F131E;
  padding-inline: 10px;
  width: 100%;
  justify-content: center;
}

// .black{
//   background: #000;
// }

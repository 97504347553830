.block {
  background: #f8f8f8;
  border: 1px solid #f5f5f5;
  border-radius: 2px;
  padding: 16px 24px;
  width: 373px;
  &__wrapper {
    display: flex;
    align-items: flex-end;
  }
  &__label {
    font-size: 18px;
    line-height: 130%;
    color: rgba(60, 60, 60, 0.8);
    margin-bottom: 4px;
  }
  &__value {
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #0f131e;
  }
  &__dimension {
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #0f131e;
    margin: 0 0 3px 9px;
  }
}

@media (max-width: 991px) {
  .block {
    flex: 30%;
  }
  .block + div {
    flex: 60%;
  }
}
@media screen and (max-width: 560px) {
  .block {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__label {
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 475px) {
  .block {
    flex-direction: column;
    align-items: flex-start;
    &__wrapper {
      margin-top: 10px;
    }
  }
}

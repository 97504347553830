.date {
  display: flex;
  gap: 10px;
  position: relative;
  justify-content: flex-start;
  &__text{
    flex: 3;
    p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      color: rgba(60, 60, 60, 0.8);
      width: 85%;
    }
    h5 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 130%;
      color: #509c5a;
      display: flex;
      align-items: flex-end;
    }
  }
  &__bottom {
    flex: 0.2;
    display: flex;
    align-items: center;
    &_dot {
      height: 8px;
      width: 8px;
      background-color: #509c5b;
    }
    &_line {
      height: 2px;
      width: 100%;
      background-color: #509c5a;
    }
  }
  &.reversed{
    flex-direction: column-reverse;
    top: -12px;
  }
}

@media screen and (max-width: 768px) {
  .date {
    flex-direction: row-reverse;
    h5 {
      font-size: 20px;
      align-items: flex-start;
    }
    p{
      padding-bottom: 34px;
    }
    &__bottom {
      flex-direction: column;
      &_line{
        width: 2px;
        height: 100%;
      }
    }
  }
}

.swiper-pagination-bullet{
    background: #B3B3B3 !important;
    opacity: 1 !important;
}
.swiper-pagination-bullet-active{
    background: #509c5b !important;
}

.swiper{
    padding-bottom: 18px;
}
.swiper-pagination{
    bottom: 0 !important;
    text-align: left;
}
.tab{
  font-family: 'Montserrat', sans-serif;
  padding: 2px 10px;
  border-left: 4px solid #fff;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #B3B3B3;
  cursor: pointer;
  transition: .2s ease-in-out;
  &__active{
    border-left: 4px solid #509C5B;
    color: #0F131E;
    transition: .2s ease-in-out;
  }
}
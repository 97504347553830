/** @format */

@import "../../../../styling/variables.scss";

.calculate {
  margin-top: 67px;
  padding-block: 2rem 98px;
  overflow: hidden;
  background: #f8f8f8;
  &__head {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 24px;
    p {
      background-color: $green;
      margin-bottom: 45px;
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      padding: 4px 14px;
      border-radius: 5px;
      width: max-content;
      text-align: left;
    }
  }
  &__title {
    font-size: 36px;
  }
  .calculator {
    background-image: url("../../../../img/calculator.webp");
    background-repeat: no-repeat;
    background-position: center;
    height: 553px;
    background-size: cover;
    &__yield {
      padding-left: 8px;
      white-space: nowrap;
      font-size: 14px;
      display: block;
    }
    &__inner {
      display: flex;
      padding-top: 76px;
      // overflow: hidden;
    }
    &__left {
      flex: 2;
    }
    &__trees {
      background: rgba(118, 161, 151, 0.4);
      padding: 30px 50px;
      padding-top: 50px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      div {
        width: 93px;
        height: 93px;
        background-color: transparent;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        margin-left: -20px;
        margin-top: -20px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
        & img {
          width: 100%;
          height: 100%;
        }
      }
    }
    &__law {
      background-color: #fff;
      margin-right: 66px;
      margin-top: 190px;
      border-left: 4px solid #c5d483;
      padding: 10px 10px 10px 44px;
      position: relative;
      img {
        position: absolute;
        left: 10px;
        top: 10px;
      }
      h5 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: -0.03em;
        color: #000000;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        width: 95%;
      }
    }
    &__body {
      flex: 1;
      height: 615px;
      position: relative;
      transform: translateY(-18%);
      max-width: 420px;
      padding: 10px 20px;
      color: #fff;
      position: relative;
      .blur {
        background: rgba(60, 60, 60, 0.4);
        backdrop-filter: blur(13.5px);
        border-radius: 14px;
        position: absolute;
        width: 100%;
        height: 615px;
        border: 1px solid #b3b3b3;
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;
      }
      h3 {
        font-size: 24px;
        text-align: center;
        font-family: "Montserrat";
      }
      &_amount {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        &__leaf {
          background-color: transparent;
          min-width: 90px;
          height: 140px;
          display: flex;
          border-radius: 50%;
          // transform: translateX(15px);
          z-index: 1;
          img {
            // width: 100%;
            height: 100%;
          }
        }
        &__trees {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #fff;
          color: $blackGreen;
          font-family: "Montserrat";
          font-size: 20px;
          font-weight: 500;
          padding: 6px 22px 6px 20px;
          text-align: right;
          width: 290px;
          border-radius: 4px;
          transform: translateX(0px);
          z-index: 0;
          span {
            color: $green;
            font-weight: 600;
            font-size: 14px;
          }
        }
      }
      &_slider {
        margin-top: 16px;
        &__value {
          display: flex;
          justify-content: space-between;
          font-size: 24px;
          div {
            font-family: "Montserrat";
            &:first-child,
            &:last-child {
              cursor: pointer;
            }
          }
        }
        &__container {
          width: 100%;
          margin-top: 16px;
          .slider {
            -webkit-appearance: none;
            width: 100%;
            height: 2px;
            background: #fff;
            outline: none;
          }

          .slider::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            border: 1px solid #fff;
            background: $green;
            cursor: pointer;
          }

          .slider::-moz-range-thumb {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            border: 1px solid #fff;
            background: $green;
            cursor: pointer;
          }
        }
      }
      &_scenario {
        margin-top: 16px;
        h3 {
          text-transform: uppercase;
        }
        &__tabs {
          padding-block: 12px;
          display: flex;
          border-bottom: 3px solid #fff;
          justify-content: center;
        }
        &__info {
          margin-top: 24px;
          display: flex;
          justify-content: center;
          gap: 1rem;

          &_line {
            width: 140px;
            p {
              text-align: center;
            }
            hr {
              background-color: #509c5b;
              height: 3px;
              border: none;
              position: relative;
              overflow: visible;
              &::after {
                content: "V";
                position: absolute;
                color: #509c5b;
                right: -2px;
                transform: rotate(-90deg);
                top: -8px;
              }
            }
          }
          div {
            p {
              font-size: 24px;
              font-weight: 500;

              &:not(:first-child) {
                padding-right: 40px;
                font-size: 24px;
                font-weight: 500;
                font-family: "Montserrat";
                text-align: center;
                margin-top: 1rem;
                min-width: 180px;
                text-align: right;
                span {
                  font-size: 18px;
                }
              }
              &:last-child {
                padding-right: 46px;
              }
            }
          }
        }
      }
      &_power {
        position: absolute;
        margin-top: 10px;
        background-color: #fff;
        height: 100px;
        border-radius: 10px;
        padding-block: 10px;
        display: flex;
        gap: 5px;
        align-items: center;
        color: $blackGreen;
        justify-content: center;
        width: calc(100% - 40px);
        bottom: 48px;
        left: 50%;
        transform: translateX(-50%);
        h3 {
          font-size: 16px;
          font-weight: 600;
          color: $green;
        }
        p {
          font-size: 32px;
          font-weight: 600;
          font-family: "Montserrat";
          span {
            font-size: 24px;
            font-family: "Montserrat";
          }
        }
        & > div {
          position: absolute;
          right: 0;
          top: -24px;
          font-family: "Montserrat";
          font-size: 14px;
          display: flex;
          align-items: center;
          gap: 2px;
          color: #fff;
          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
      &_note {
        text-align: center;
        margin-top: 45px;
      }
    }
  }
}
.mobile {
  display: none;
}

@media (max-width: 1200px) {
  .calculate {
    .calculator {
      &__law {
        margin-top: 140px;
        margin-right: 40px;
      }
    }
  }
}

@media (max-width: 991px) {
  .calculate {
    &__head {
      p {
        width: 100%;
        margin-bottom: 24px;
      }
    }
    .calculator {
      &__left {
        display: none;
      }
      &__inner {
        justify-content: center;
      }
    }
  }
}
@media screen and (max-width: 700px) {
  .calculate {
    &__title {
      margin-bottom: 24px;
    }
  }
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
}

@media screen and (max-width: 576px) {
  .calculate {
    margin-top: 40px;
    padding-top: 0;
    padding-bottom: 0;
    &__title {
      text-align: center;
      font-size: 24px;
      padding-top: 24px;
    }
    &__head {
      margin-bottom: 10px;
      p {
        text-align: center;
        margin-bottom: 0;
      }
    }
    .calculator {
      height: auto;
      padding: 0;
      margin-top: 10px;
      &__inner {
        padding-top: 0;
        height: 585px;
        position: relative;
      }
      &__body {
        width: 100%;
        transform: translateY(0);
        border-radius: 0;
        padding-inline: 10px;
        position: absolute;
        bottom: 0;
        height: 535px;
        h3 {
          font-size: 18px;
        }
        .blur {
          border: none;
          border-radius: 0;
          height: 535px;
        }
        &_amount {
          &__trees {
            font-size: 16px;
            width: 100%;
            padding-right: 40px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .calculate {
    .calculator {
      &__inner {
        position: static;
      }
      &__body {
        max-width: none;
        position: static;
        &_power {
          margin-top: 10px;
          padding-inline: 16px;
          bottom: 10px;
          a {
            width: 100%;
            justify-content: center;
          }
        }
        &_amount {
          &__leaf {
            img {
              width: 90px;
              // height: 90px;
            }
          }
        }
        &_scenario {
          &__info {
            gap: 20px;
            div {
              text-align: center;
              &:last-child {
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 410px) {
  .calculate {
    .calculator {
      height: auto;
      padding: 0;
      margin-top: 10px;
      &__inner {
        padding-top: 0;
      }
      &__body {
        width: 100%;
        transform: translateY(0);
        border-radius: 0;
        padding-inline: 10px;
        h3 {
          font-size: 18px;
        }
        .blur {
          border: none;
          border-radius: 0;
        }
        &_amount {
          &__trees {
            font-size: 16px;
            width: 100%;
            padding-right: 10px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 410px) {
  .calculate {
    .calculator {
      height: auto;
      padding: 0;
      margin-top: 10px;
      &__inner {
        padding-top: 0;
      }
      &__body {
        width: 100%;
        transform: translateY(0);
        border-radius: 0;
        padding-inline: 10px;
        h3 {
          font-size: 18px;
        }
        .blur {
          border: none;
          border-radius: 0;
        }
        &_amount {
          &__trees {
            font-size: 12px;
            width: 100%;
            padding-right: 10px;
            span {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 365px) {
  .calculator {
    &__body {
      &_total {
        font-size: 21px;
      }
    }
  }
}

.main {
  display: flex;
  flex-direction: column;
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__header {
    margin-top: 19px;
    margin-bottom: 32px;
  }

  &__body {
    display: flex;
    margin-bottom: 129px;
  }

}

.header {
  &__image {
    &_wrapper {
      height: 261px;
      position: relative;
      margin-bottom: 9px;
    }

    &_rectangle {
      position: absolute;
      width: 57%;
      height: 8%;
      right: 0;
      bottom: 0;
      background: #d7d3c1;
      z-index: 2;
    }
  }
}

.title {
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 42px;
  line-height: 110%;
  letter-spacing: -0.03em;
  color: #0f131e;
  &__wrapper {
  }
}

.paragraphs {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.paragraph {
  display: flex;
  gap: 5px;

  &__text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: rgba(60, 60, 60, 0.8);
  }
}

.link {
  color: #509c5b;
}

@media screen and (max-width: 1140px) {

  .header {
    &__image {
      width: 100%;
      height: 100%;
      &_wrapper {
        height: 100%;
      }

      &_rectangle {
        bottom: 4px;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .main {
    &__header {
      margin-top: 0;
      margin-bottom: 24px;
    }

    &__body {
      margin-bottom: 50px;
    }
  }

  .title {
    width: 355px;
    font-weight: 600;
    font-size: 32px;
    line-height: 120%;
  }

  .header {
    &__image {
      height: 121px;
      object-fit: cover;
    }
  }
}

.functions {
  margin-top: 67px;
  h2 {
    margin-bottom: 24px;
  }
  &__desc {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 130%;
    color: #0f131e;
    width: 50%;
  }
  &__checks {
    margin-top: 32px;
    max-width: 743px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(356px, 1fr));
    column-gap: 30px;
    row-gap: 16px;
    & > div {
      display: flex;
      align-items: center;
      gap: 12px;
      p {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: -0.03em;
        color: #0f131e;
      }
    }
  }
  &__inner {
    margin-top: 36px;
    height: 160px;
    background: #c5d483;
    display: flex;
    padding-right: 16px;
    &_left {
      flex: 70%;
      display: grid;
      grid-template-columns: repeat(auto-fit, 220px);
      padding-block: 20px 32px;
      padding-left: 36px;
      column-gap: 16px;
      & > div {
        h5 {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 42px;
          line-height: 120%;
          letter-spacing: -0.03em;
          color: #0f131e;
          margin: 0;
        }
        p {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 120%;
          letter-spacing: -0.03em;
          color: #0f131e;
        }
      }
    }
    &_right {
      flex: 30%;
      position: relative;
      top: -120px;
      div {
        display: flex;
        gap: 20px;
        p {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 140%;
          color: #ffffff;
        }
      }
    }
  }
}
@media (max-width: 1200px) {
  .functions {
    &__desc {
      width: 100%;
    }
    &__inner {
      height: auto;
      &_right {
        top: 14px;
      }
    }
  }
}

@media (max-width: 991px) {
  .functions {
    &__checks {
      grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
    }
    &__inner {
      &_left {
        grid-template-columns: repeat(auto-fit, 280px);
        row-gap: 16px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .functions {
    &__inner {
      flex-direction: column;
      padding-right: 0;
      &_left {
        padding: 20px 18px;
        column-gap: 28px;
        grid-template-columns: repeat(auto-fit, 230px);
      }
      &_right {
        position: static;
        padding: 8px 12px;
        img {
          width: 100%;
        }
      }
    }
  }
}
@media screen and (max-width: 576px) {
  .functions {
    &__desc {
      font-weight: 600;
      font-size: 16px;
      line-height: 120%;
      letter-spacing: -0.03em;
    }
    &__inner {
      &_left {
        & > div {
          h5 {
            font-size: 30px;
          }
          p {
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 543px) {
  .functions {
    &__inner {
      &_left {
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
      }
    }
  }
}

@media screen and (max-width: 385px) {
  .functions__checks {
    > div > p {
      max-width: 70%;
    }
  }
}

@media screen and (max-width: 350px) {
  .functions {
    max-width: 100%;
    overflow: hidden;
  }
}

@import "../../../../styling/variables.scss";

.settings {
  padding: 0 23px 84px 20px;
  min-height: 800px;
  h2 {
    margin-left: 10px;
  }
  input {
    border-bottom: 2px solid #b3b3b3;
  }
  &_dob {
    width: 50%;
    &_label {
      padding-left: 16px;
      font-size: 16px;
      line-height: 130%;
      color: #0f131e;
    }
    label {
      margin-top: 8px;
      width: 100%;
      gap: 0px;
      input {
        width: 100%;
        max-width: 100%;
        background: #f8f8f8;
        border: none;
        border-bottom: 2px solid #b3b3b3;
        &::-webkit-input-placeholder {
          color: #b3b3b3;
        }
        &::-moz-placeholder {
          color: #b3b3b3;
        }
        &:-ms-input-placeholder {
          color: #b3b3b3;
        }
        &:-moz-placeholder {
          color: #b3b3b3;
        }
      }
    }
    &_error {
      input {
        &::-webkit-input-placeholder {
          color: #ca4b15 !important;
        }
        &::-moz-placeholder {
          color: #ca4b15 !important;
        }
        &:-ms-input-placeholder {
          color: #ca4b15 !important;
        }
        &:-moz-placeholder {
          color: #ca4b15 !important;
        }
      }
    }
  }
  &__account {
    margin-bottom: 70px;
    &_info {
      margin-top: 32px;
      display: flex;
      gap: 28px;
      flex-wrap: wrap;

      & > div {
        &:nth-child(2) {
          a {
            width: 200px;
            justify-content: center;
          }
        }
      }
      &_photo {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
      &_pict {
        width: 208px;
        height: 208px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        &_uploaded {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
        }
      }
    }
    &_upload {
      position: absolute;
      bottom: -50px;
      left: 0;
      &_text {
        max-width: 199px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: absolute;
        bottom: -70px;
      }
      input {
        opacity: 0;
        position: absolute;
        z-index: -1;
      }
      &_btn {
        width: 199px;
        height: 39px;
        border: 1px solid #509c5b;
        border-radius: 4px;
        font-weight: 500;
        font-size: 14px;
        line-height: 110%;
        color: #0f131e;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        cursor: pointer;
        background: #fff;
        transition: 0.2s ease-in-out;
        &:hover {
          background: #509c5b;
          color: #ffffff;
          transition: 0.2s ease-in-out;
        }
      }
    }
  }
  &__inner {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    & > div {
      padding: 16px 20px;
      border: 1px solid #b3b3b3;
      border-radius: 2px;
    }
  }
  &__billing {
    flex: 100%;
    &_inner {
      margin-top: 20px;
    }
    &_row {
      margin-top: 24px;
      display: flex;
      gap: 20px;
      justify-content: flex-start;
      & > div,
      & > label {
        flex: 30%;
      }
    }
  }
  &__initials {
    flex: 60%;
    margin-bottom: 20px;
    &_inner {
      margin-top: 20px;
      margin-right: 200px;
    }
    &_row {
      margin-top: 24px;
      display: flex;
      gap: 20px;
      justify-content: flex-start;
      & > div,
      & > label {
        flex: 30%;
      }
    }
  }
  &__bank {
    flex: 100%;
    &_inner {
      margin-top: 20px;
      display: flex;
      gap: 20px;
      & > * {
        flex: 1;
      }
      &:last-child {
        align-items: center;
        & > label {
          &:last-child {
            display: flex;
            padding-left: 0;
            top: 14px;
            div {
              margin-left: 35px;
            }
          }
        }
      }
      &_fix {
        & > * {
          padding-left: 0;
        }
        div:last-child {
          margin-left: 35px;
        }
      }
      &_noty {
        margin-top: 14px;
        font-size: 16px;
      }
    }
  }
  &__security {
    flex: 1;
    min-height: 264px;
    &_inner {
      margin-top: 32px;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    &_row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 90%;
      > p {
        width: 50%;
      }
      a {
        gap: 4px;
      }
    }
    // &:last-child {
    //   padding-left: 84px;
    //   display: flex;
    //   flex-direction: column;
    //   gap: 24px;
    //   label {
    //     width: 374px;
    //   }
    // }
    // }
  }
  &__notifications {
    flex: 1;
    min-height: 264px;
    &_inner {
      margin-top: 32px;
      display: flex;
      flex-direction: column;
      gap: 14px;
    }
    &_row {
      display: flex;
      gap: 10px;
    }
  }
  &__dob {
    display: flex;
    align-items: center;
    justify-content: space-between;
    input {
      margin-top: 8px;
      text-align: center;
      height: 45px;
      width: calc(28% - 4px);
      outline: none;
      border: none;
      border-bottom: 2px solid #b3b3b3;
      padding: 0 16px;
      font-size: 16px;
      line-height: 130%;
      outline-color: transparent;
      outline-width: 0px;
      color: #0f131e;
      &:last-child {
        width: calc(44% - 4px);
      }
      &::-webkit-input-placeholder {
        color: #b3b3b3;
      }
      &::-moz-placeholder {
        color: #b3b3b3;
      }
      &:-ms-input-placeholder {
        color: #b3b3b3;
      }
      &:-moz-placeholder {
        color: #b3b3b3;
      }
    }
    &_error {
      input {
        color: #ca4b15;
        border-color: #ca4b15;
      }
    }
  }
}

.dob_error {
  margin-top: 2px;
  padding-left: 16px;
  display: flex;
  align-items: center;
  svg {
    min-width: 12px;
    width: 12px;
  }
  div {
    font-weight: 500;
    font-size: 11px;
    line-height: 130%;
    color: #ca4b15;
    margin: 2px 0 0 4px;
  }
}
.error {
  margin-top: 10px;
  color: #ca4b15;
}
.form {
  margin-top: 32px;
  &_wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    label {
      width: calc(50% - 6px);
    }
    &__phone {
      width: calc(50% - 6px);
      position: relative;
      > label {
        width: 100%;
        > input {
          padding-left: 80px;
        }
      }
    }
    &__country {
      position: absolute;
      top: 43px;
      left: 20px;
    }
  }
}

.save {
  padding-inline: 20px;
  display: flex;
  align-items: center;
  // justify-content: center;
  position: relative;
  // flex-direction: column;
  padding-inline: 24px;
  padding-block: 40px 32px;
  // transform: translateX(-119px);
  // position: fixed;
  // top: 30%;
  // left: 50%;
  h2 {
    color: #509c5b;
    font-size: 30px;
    text-align: center;
    margin: 0 0 30px 0;
  }
  button {
    display: flex;
    margin-left: auto;
    cursor: pointer;
    background: none;
    border: none;
  }
}

.modal__buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  > button:first-child {
    margin-left: 0px;
  }
  > button:last-child {
    margin-left: 10px;
  }
  > button {
    padding: 0 32px;
    display: inline-flex;
    align-items: center;
    height: 39px;
    min-height: 39px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 110%;
    transition: 0.2s ease-in-out;
    background: $green !important;
    border: 1px solid $green !important;
    color: #ffffff !important;
    &:hover {
      transition: 0.2s ease-in-out;
      background: #ffffff !important;
      color: $green !important;
    }
  }
}

.modal2fa {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  background: rgba(60, 60, 60, 0.05);
  backdrop-filter: blur(5px);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &__inner {
    max-width: 470px;
    width: 100%;
    padding: 40px 31px;
    border: 1px solid #f8f8f8;
    background: #ffffff;
    box-shadow: 0px 10px 20px -3px rgba(60, 60, 60, 0.6);
    border-radius: 2px;
    position: relative;
  }
  &__close {
    position: absolute;
    background: #ffffff;
    border: none;
    outline: none;
    cursor: pointer;
    top: 10px;
    right: 10px;
  }
  &__title {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    color: #0f131e;
    text-align: center;
    &_offset {
      margin-bottom: 24px;
      text-align-last: left;
    }
  }
  &__subtitle {
    margin-top: 24px;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #0f131e;
    text-align: center;
  }
  &__img {
    display: block;
    margin: 24px auto;
  }
  &__text {
    margin-top: 24px;
    text-align: center;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #0f131e;
  }
  &__start {
    margin: 24px auto 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #509c5b;
    border-radius: 4px;
    border: 1px solid #509c5b;
    width: 199px;
    height: 39px;
    font-weight: 500;
    font-size: 14px;
    line-height: 110%;
    color: #ffffff;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover {
      color: #509c5b;
      background: #ffffff;
      transition: 0.2s ease-in-out;
    }
  }
  &__loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(60, 60, 60, 0.05);
    backdrop-filter: blur(2px);
    &_dot {
      border-radius: 50%;
      width: 30px;
      height: 30px;
    }
    &_dot1 {
      background-color: hsl(114, 35%, 75%);
      animation: 1000ms ease-in-out 500ms infinite grow-shrink;
    }
    &_dot2 {
      background-color: hsl(114, 35%, 52%);
      animation: 1000ms ease-in-out infinite grow-shrink2;
      position: absolute;
    }
  }
  &__error {
    font-size: 14px;
    color: red;
    margin-top: 12px;
    padding-left: 16px;
  }
}

@keyframes grow-shrink {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.6);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes grow-shrink2 {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 1200px) {
  .settings__account_info_photo {
    width: 100px;
    height: 100px;
  }
  .settings {
    &__initials {
      &_inner {
        margin-right: 0px;
      }
    }
  }
}
.modal__success {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background: rgba(60, 60, 60, 0.05);
  backdrop-filter: blur(5px);
  width: 100%;
  height: 100vh;
  &_inner {
    max-width: 561px;
    padding: 30px;
    background: #ffffff;
    width: 100%;
    &_check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }
  h2 {
    color: #6ba32b;
    font-size: 30px;
    text-align: center;
    margin: 0 0 30px 0;
  }
  button {
    display: flex;
    margin-left: auto;
    cursor: pointer;
    background: none;
    border: none;
  }
}

@media screen and (max-width: 1200px) {
  .settings__account_info_photo {
    width: 132px;
    height: 132px;
  }
}

@media screen and (max-width: 1105px) {
  .settings {
    &__initials {
      &_inner {
        margin-right: 0px;
      }
    }
    &__account {
      margin-bottom: 40px;
      &_info {
        & > div {
          &:last-child {
            display: flex;
            flex-direction: column;
            gap: 24px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .settings {
    &__initials {
      margin-top: 20px;
    }
    &__account {
      margin-bottom: 40px;
      &_info {
        display: block;
      }
    }
  }
}

@media screen and (max-width: 840px) {
  .settings {
    &__account {
      &_info {
        & > div {
          &:last-child {
            padding-left: 0;
          }
        }
      }
    }

    &__security {
      flex: 1;
    }
  }
}

@media screen and (max-width: 560px) {
  .settings {
    &_dob {
      width: 100%;
    }
    padding-inline: 10px;
    &__initials {
      &_row {
        display: block;

        & > label {
          margin-bottom: 17px;
        }
        & > div {
          margin-bottom: 17px;
        }
      }
    }
    &__billing {
      &_inner {
        margin-top: 16px;
      }
      &_row {
        flex-direction: column;
        margin-top: 0;
        &:last-child {
          margin-top: 16px;
        }
      }
    }
    h2 {
      margin-left: 0;
    }
    &__account {
      &_info {
        gap: 24px;
        & > img {
          flex: 45%;
          object-fit: contain;
        }
        & > div {
          &:nth-child(2) {
            flex: 45%;
            a {
              width: 100%;
            }
          }
          &:last-child {
            flex: 100%;
            label {
              width: 100%;
            }
          }
        }
      }
    }
    &__inner {
      flex-direction: column;
      & > div {
        border: none;
        padding: 0;
      }
    }
    &__bank {
      &_inner {
        flex-direction: column;
      }
      & > div {
        &:nth-child(2) {
          & > div {
            display: none;
          }
        }
        &:last-child {
          & > label {
            &:last-child {
              display: flex;
              justify-content: flex-start;
              padding-left: 35px;
              top: 0;
              div {
                margin-left: 0;
              }
            }
          }
        }
      }
    }
    &__security {
      min-height: 0;
      margin-bottom: 18px;
      &_row {
        width: 100%;
      }
    }
    &__notifications {
      min-height: 0;
    }
  }
  .form {
    &_wrapper {
      &__phone {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 475px) {
  .save {
    background: transparent;
    border-top: none;
    justify-content: flex-end;
    padding-inline: 10px;
  }
}

@media screen and (max-width: 375px) {
  .save {
    a {
      width: 100%;
      justify-content: center;
    }
  }
}

.network {
    padding-inline: 20px;
    padding-block: 9px;
    padding-bottom: 50px;
    overflow: hidden;

    &__inner {
        img {
            width: 80%;
            pointer-events: none;
        }
    }

    &__text {
        font-size: 24px;
        font-family: "Montserrat";
        font-weight: 300;
        margin-bottom: 16px;
    }

    &__invite {
        background: #d7d3c1;
        padding: 16px;
        width: 50%;

        p {
            font-size: 18px;
            font-family: 'Montserrat';
            font-weight: 600;
            margin-bottom: 10px;
        }

        div {
            display: flex;
            align-items: center;
            gap: 16px;

            input {
                padding: 4px 8px;
                border: none;
                border-radius: 4px;
                background: #fff;
                font-size: 16px;
                flex: 1;
            }

            button {
                background: transparent;
                border: none;
                cursor: pointer;
                transition: all 0.3s ease;
                padding: 4px;
                border-radius: 4px;

                img {
                    width: 24px;
                    height: 24px;
                }

                &:hover {
                    background-color: #00000010;
                }
            }
        }

    }
}

.copyButton {
    position: relative;
}

.tooltip {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    margin-bottom: 5px;

    &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        border-width: 5px;
        border-style: solid;
        border-color: #333 transparent transparent transparent;
    }
}

@media screen and (max-width: 840px){
    .network{
        &__inner{
            img{
                width: 100%;
            }
        }
        &__invite{
            width: 100%;
        }
    }
}
.loader{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100vh;
  background: rgba(256,256,256, 0.5);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
  &__dot{
    border-radius: 50%;
    width: 60px;
    height: 60px;
  }
  &__dot1{
    background-color: hsl(114, 35%, 75%);
    animation: 1000ms ease-in-out 500ms infinite grow-shrink;
  }
  &__dot2{
    background-color: hsl(114, 35%, 52%);
    animation: 1000ms ease-in-out infinite grow-shrink2;
    position: absolute;
  }
}




@keyframes grow-shrink {
  0% { transform: scale(1); }
  50% { transform: scale(1.6); }
  100% { transform: scale(1); }
}

@keyframes grow-shrink2 {
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}
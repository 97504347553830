.status{
  padding: 2px 16px;
  display: inline-flex;
  background: #F6D4AD;
  border-radius: 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  &__wrapper{
    display: flex;
    align-items: center;
  }
}
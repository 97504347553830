.prognose {
  margin-top: 67px;
  h2 {
    text-align: center;
  }
  &__wrapper {
    display: flex;
    gap: 68px;
    padding-inline: 40px;
    margin-top: 56px;
  }
  &__graph {
    flex: 60%;
    position: relative;
    height: 297px;
    // :first-child {
    //   position: absolute;
    //   rotate: 270deg;
    //   left: -64px;
    //   top: 120px;
    //   font-family: "Montserrat";
    //   font-style: normal;
    //   font-weight: 600;
    //   font-size: 12px;
    //   line-height: 120%;
    //   letter-spacing: -0.03em;
    //   color: #509c5b;
    // }
    // :last-child {
    //   position: absolute;
    //   font-size: larger;
    //   font-weight: 400;
    //   font-size: 14px;
    //   margin-top: 12px;
    // }
  }
  &__info {
    flex: 40%;
    background: url("../../../../img/prognose.svg");
    background-repeat: no-repeat;
    background-position: 100%;
    background-size: 70%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    &_inner {
      display: flex;
      height: auto;
      & > div {
        &:first-child {
          flex: 25%;
          background: #509c5b;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &:last-child {
          flex: 75%;
          background: #c5d483;
          padding: 12px 24px;
          h4 {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 42px;
            line-height: 120%;
            letter-spacing: -0.03em;
            color: #0f131e;
          }
          p {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 120%;
            letter-spacing: -0.03em;
            color: #0f131e;
          }
        }
      }
    }
  }
  &__tip {
    color: #509c5a;
    font-size: 12px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.36px;
    transform: rotate(-90deg);
    position: absolute;
    left: -110px;
    bottom: 150px;
  }
}

.chart {
  width: 100%;
  height: 297px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  &__sourse {
    margin-top: 15px;
  }
  &__block {
    width: 24px;
    background: #509c5a;
    position: relative;
  }
  &__row {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    justify-content: flex-end;
    height: 100%;
    &_line1 {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 1px;
      background: #d9d9d9;
      height: 269px;
    }
  }
  &__year {
    font-size: 12px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.36px;
    font-family: "Montserrat", sans-serif;
    margin-top: 9px;
  }
  &__left {
    height: 280px;
    position: absolute;
    left: -30px;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    div {
      font-size: 12px;
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      line-height: 120%;
      letter-spacing: -0.36px;
      text-align: right;
    }
  }
  &__step1 {
    height: 13px;
  }
  &__step2 {
    height: 27px;
  }
  &__step3 {
    height: 79px;
  }
  &__step4 {
    height: 99px;
  }
  &__step5 {
    height: 126px;
  }
  &__step6 {
    height: 148px;
  }
  &__step7 {
    height: 174px;
  }
  &__step8 {
    height: 199px;
  }
  &__step9 {
    height: 228px;
  }
}

@media screen and (max-width: 1200px) {
  .prognose {
    &__info {
      &_inner {
        height: auto;
      }
    }
  }
}

@media (max-width: 991px) {
  .prognose {
    &__wrapper {
      flex-direction: column;
    }
    &__info {
      background: none;
    }
    &__graph {
      width: 100%;
      margin-bottom: 30px;
      p {
        &:last-child {
          bottom: -32px;
        }
      }
    }
  }
  .chart {
    &__left {
      bottom: 34px;
    }
  }
}

@media screen and (max-width: 576px) {
  .prognose {
    margin-top: 128px;
    margin-bottom: 28px;
    &__wrapper {
      margin-top: 16px;
      gap: 16px;
      padding-inline: 0;
    }
    &__graph {
      display: flex;
      flex-direction: column;
      height: 230px;
      p {
        position: static;
        rotate: 0deg;
        text-align: center;
      }
    }
    &__tip {
      display: none;
    }
    &__info {
      &_inner {
        div {
          &:last-child {
            display: flex;
            flex-direction: column;
            justify-content: center;
            h4 {
              font-size: 30px;
            }
            p {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  .chart {
    width: calc(100% - 80px);
    margin: 0 auto;
    &__left {
      left: 0;
    }
  }
}

@media screen and (max-width: 435px) {
  .chart {
    height: 230px;
    &__left {
      height: 230px;
    }
    &__row {
      &_line1 {
        height: 200px;
      }
    }
    &__step1 {
      height: 20px;
    }
    &__step2 {
      height: 60px;
    }
    &__step3 {
      height: 80px;
    }
    &__step4 {
      height: 100px;
    }
    &__step5 {
      height: 120px;
    }
    &__step6 {
      height: 140px;
    }
    &__step7 {
      height: 160px;
    }
    &__step8 {
      height: 180px;
    }
    &__step9 {
      height: 200px;
    }
  }
}

@media screen and (max-width: 390px) {
  .chart {
    width: calc(100% - 48px);
    margin-right: 15px;
    &__year {
      font-size: 10px;
    }
    &__block {
      width: 18px;
    }
    &__left {
      div {
        font-size: 9px;
      }
    }
  }
}

.modal {
  height: 100vh;
  width: 100%;
  background: rgba(60, 60, 60, 0.05);
  backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0f131e;
  z-index: 100;
  &__inner {
    width: 450px;
    background-color: #fff;
    border: 1px solid #f8f8f8;
    box-shadow: 0px 10px 20px -3px rgba(60, 60, 60, 0.6);
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;
    padding-inline: 24px;
    padding-block: 40px 32px;
    gap: 32px;
    transform: translateX(-119px);
    .modal__close {
      outline: none;
      border: none;
      background: none;
      cursor: pointer;
      top: 15px;
      right: 15px;
      position: absolute;
      cursor: pointer;
    }
    h3 {
      font-size: 24px;
      font-family: "Montserrat";
      font-weight: 700;
      text-align: center;
    }
  }
  &__close {
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
    top: 15px;
    right: 15px;
    position: absolute;
    cursor: pointer;
  }
  p {
    font-weight: 400;
    text-align: center;
  }
  a {
    width: 200px;
    justify-content: center;
  }
  span {
    font-size: 14px;
    color: #509c5b;
    cursor: pointer;
  }
  &__success {
    width: 450px;
    background-color: #fff;
    border: 1px solid #f8f8f8;
    box-shadow: 0px 10px 20px -3px rgba(60, 60, 60, 0.6);
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;
    padding-inline: 24px;
    padding-block: 40px 32px;
    transform: translateX(-119px);
    h2 {
      color: #509c5b;
      font-size: 30px;
      text-align: center;
      margin: 0 0 30px 0;
    }
  }
  &__error {
    width: 450px;
    background-color: #fff;
    border: 1px solid #f8f8f8;
    box-shadow: 0px 10px 20px -3px rgba(60, 60, 60, 0.6);
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;
    padding-inline: 24px;
    padding-block: 40px 32px;
    transform: translateX(-119px);
    &_errors {
      margin: 8px 0 30px 0;
      div {
        font-size: 14px;
        color: #ca4b15;
      }
    }
    h2 {
      color: #ca4b15;
      font-size: 30px;
      text-align: center;
      margin: 0;
    }
    svg {
      width: 100px;
      height: 100px;
    }
    .modal__close {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}

@media screen and (max-width: 840px) {
  .modal {
    justify-content: center;
    z-index: 100;
    &__inner {
      max-width: 90%;
      width: 100%;
      transform: translateX(0);
    }
  }
}
